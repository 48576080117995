Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NavigationMenu";
exports.labelBodyText = "NavigationMenu Body";

exports.btnExampleTitle = "CLICK ME";

exports.openDrawerText = "Log Out";
exports.userName = "Jordan Ramsay";
exports.userDesignation = "Miami Herica";
exports.logout = "Logout";

exports.jsonApiContentType = "application/json";
exports.getMenuItemsEndPoint = "navmenu/navigation_menu";
exports.getApiMethodType = "GET";

exports.navigationButtons={
  dashboard:"Dashboard",
  reservations:"Reservations",
  calendar:"Calendar",
  inbox:"Inbox",
  transactions:"Transactions",
  settings:"Settings",
  logout:"Logout",
  avatar:"Avatar",
  find_hotels:"Find Hotels",
  doggy_daycare:"Doggy Daycare",
  about_us:"About Us",
  why_register:"Why Register",
  help:"Help",
  faqs:"FAQs",
  my_reservations:"My Reservations",
  pet_owner_signin:"Sign In",
  pet_owner_register:"Register",
  business_owner_signin:"Business Login",
  bussiness_owner_register:"Business Account"

}

exports.logout={
  stay:"Stay Logged In",
  exit:"Sign Out Now",
  heading:"Logout?",
  subHeading:"Would you like to sign out of account now?"
}

exports.getActivityEndPoint="bx_block_custom_form/getting_data"
exports.getAccountDetailsEndPoint="account_block/account_details"
exports.sessionErrorMessage="Your session expired. Login in again to proceed"

exports.profileSetup="Please complete the profile setup"

// Customizable Area End