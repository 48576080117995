import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  FormControl,
  TextField,
  Paper,
  Menu,
  MenuItem,
  Avatar,
  Checkbox,
  ClickAwayListener,
  Divider,
  Modal
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { Theme, createStyles, styled, withStyles } from "@material-ui/core/styles";
import { boxShadows, colors, theme } from "../../../components/src/theme";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility"; import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ControlPointIcon from "@material-ui/icons/ControlPoint";
import CustomsettingModal from "../../../components/src/CustomSettingsmodal";
import Footer from "../../../components/src/Footer.web";
import  PetOwnerNavigationMenu  from "../../navigationmenu/src/PetOwnerNavigationMenu.web";
import { editPencilIcon } from "./assets";
import { downArrow } from "../../contactus/src/assets";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import { addPlus } from "../../dashboard/src/assets";
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";
import { SelectButton } from "../../../components/src/CustomizedComponents.web";
import { cancel } from "../../shoppingcart/src/assets";



export const SettingsTextFieldInput = withStyles({
  root: {
    "& .MuiInputBase-root": {
      fontFamily: "Inter",
      color: theme.palette.common.black,
      backgroundColor: "#ffffff",
      fontSize: 16,
      fontWeight: theme.typography.fontWeightRegular,
      height: 44
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.primaryBlue,
      border: "1px solid #CBD5E1",
      borderRadius: 8
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.primaryBlue,
      border: "1px solid #CBD5E1",
      borderRadius: 8
    },
    "& .MuiOutlinedInput-root:hover": {
      borderColor: colors.primaryBlue,
      border: "1px solid #CBD5E1",
      borderRadius: 8
    },
    "& .MuiSvgIcon-root": {
      display: 'none'
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 0
    },
    "& .MuiFormHelperText-root": {
      marginTop: 5,
      fontSize: 14
    }
  }
})(TextField);
export const SettingsDropDownField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      background: `linear-gradient(0deg, #E0F4FC, #E0F4FC),linear-gradient(0deg, #CCEDFA, #CCEDFA)`,
      color: theme.palette.common.black,
      fontSize: 16,
      fontWeight: theme.typography.fontWeightRegular,
      textAlign: 'center',
      height: 44,
      cursor: 'pointer'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.primaryBlue,
      border: "1px solid #CCEDFA",
      borderRadius: "8px 0px 0px 8px"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: colors.primaryBlue,
      border: "1px solid #CCEDFA",
      borderRadius: "8px 0px 0px 8px"
    },
  }
})(TextField);
export class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderMenu = () => {
    return (<>
      <Menu
        anchorEl={this.state.anchorElMenu}
        id="long-menu"
        open={Boolean(this.state.anchorElMenu)}
        keepMounted

        onClose={this.handleClose}

        PaperProps={{
          style: {
            width: "20ch",
            maxHeight: 200,
            borderRadius: "8px",
            marginTop: "5%"

          },
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}

        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
      >
        <div>
          <Button
            onClick={() => this.OnEditPet("View")}
            data-test-id="handleClickModelOpen2"
            style={{ borderRadius: "8px", backgroundColor: this.state.mousehover === 3 ? "#B2E4F8" : "white", width: "95%", margin: "5px", textTransform: "none", justifyContent: "flex-start" }}
            onMouseLeave={() => this.setState({ mousehover: 0 })} onMouseEnter={() => this.setState({ mousehover: 3 })}
          >
            <VisibilityIcon style={{ marginRight: "7px" }} />
            <span>View</span>
          </Button>
          <Button
            onClick={() => this.OnEditPet("Edit")}
            data-test-id="editData"
            style={{ textTransform: "none", backgroundColor: this.state.mousehover === 1 ? "#B2E4F8" : "white", justifyContent: "flex-start", borderRadius: "8px", width: "95%", margin: "5px" }}
            onMouseLeave={() => this.setState({ mousehover: 0 })}
            onMouseEnter={() => this.setState({ mousehover: 1 })}
          >
            <EditIcon style={{ marginRight: "7px" }} />
            <Typography>Edit</Typography>
          </Button>

          <Button
            onClick={() => this.setState({ deletepetmodal: true })}
            data-test-id="handleClickModelOpen"
            style={{ borderRadius: "8px", textTransform: "none", backgroundColor: this.state.mousehover === 2 ? "#B2E4F8" : "white", width: "95%", margin: "5px", justifyContent: "flex-start" }}
            onMouseLeave={() => this.setState({ mousehover: 0 })}
            onMouseEnter={() => this.setState({ mousehover: 2 })}
          >
            <DeleteOutlineIcon style={{ marginRight: "7px" }} />
            <span>Delete</span>
          </Button>


        </div>
      </Menu>
    </>)
  }

  RenderSavedPet = () => {
    const dogmap = this.state.savedPet.filter(pet => pet.attributes.pet_type === 'dog')
    const catmap = this.state.savedPet.filter(pet => pet.attributes.pet_type === 'cat')
    return (
      <>
        <div >
          <Typography style={webStyle.petheading as React.CSSProperties}>Dog's Profile</Typography>
          <div style={{

            // maxHeight: "250px",
            padding: "3% 1%",

          }}>
            {

              Array.isArray(this.state.savedPet) &&
              dogmap.map((pet) => {
                const dateOfBirthDatadog = pet.attributes.date_of_birth
                  .toString()
                  .split("-");
                const birthYeardog = parseInt(dateOfBirthDatadog[0]);
                const birthMonthdog = parseInt(dateOfBirthDatadog[1]);

                const nowdog = new Date();
                const currentYeardog = nowdog.getFullYear();
                const currentMonthdog = nowdog.getMonth() + 1;

                let yearsDiffdog = currentYeardog - birthYeardog;
                let monthsDiffdog = currentMonthdog - birthMonthdog;

                if (monthsDiffdog < 0) {
                  yearsDiffdog--;
                  monthsDiffdog = 12 - birthMonthdog + currentMonthdog;
                }
                return (
                  <div key={pet.id}>
                    <Paper
                      style={webStyle.paperstyle as React.CSSProperties}
                    >
                      <Grid item style={webStyle.Grid3 as React.CSSProperties}>
                        <div style={{ marginRight: "17px" }}>
                          <Avatar
                            style={{ height: "64px", width: "64px" }}
                            src={pet.attributes.pet_photo}
                            alt="pet Photo"
                          />
                        </div>
                        <div>
                          <Typography
                            style={webStyle.Grid5}
                          >
                            {pet.attributes.name}
                          </Typography>
                          <Typography
                            style={webStyle.Grid6}>

                            Pet Sex: <span>{pet.attributes.gender}</span> | Pet Age:
                            <span>
                              {" "} {`${yearsDiffdog} years and ${monthsDiffdog} months`}
                            </span>
                          </Typography>
                        </div>
                      </Grid>

                      <Grid
                        style={{ textAlign: "right" }}
                        item
                      >
                        <IconButton
                          aria-haspopup="true"
                          aria-controls="long-menu"
                          aria-label="more"
                          onClick={(e) => this.handleClick(e, pet.id)}
                          data-test-id="menuopen"
                        >
                          <MoreVertIcon />
                        </IconButton>
                        {this.renderMenu()}
                      </Grid>
                    </Paper>
                  </div>
                );
              })}
          </div>
          <Button
            data-test-id="navigationbtn"
            onClick={() => this.OnAddPetDog()}
            style={webStyle.addpetbtn as React.CSSProperties}
          > <ControlPointIcon style={{
            marginRight: 6,
            fontSize: 18,
          }} />
            Add dog profile
          </Button>
        </div>


        <div
          style={{
            marginTop: "5%"
          }}>
          <Typography
            style={webStyle.petheading as React.CSSProperties}>
            Cat's Profile
          </Typography>
          <div
            style={{

              // maxHeight: "250px",
              padding: "2% 1.5%",

            }}
          >
            {

              Array.isArray(this.state.savedPet) &&
              catmap.map((pet) => {
                const dateOfBirthDatacat = pet.attributes.date_of_birth
                  .toString()
                  .split("-");
                const birthYearcat = parseInt(dateOfBirthDatacat[0]);
                const birthMonthcat = parseInt(dateOfBirthDatacat[1]);

                const nowcat = new Date();
                const currentYearcat = nowcat.getFullYear();
                const currentMonthcat = nowcat.getMonth() + 1;

                let yearsDiffcat = currentYearcat - birthYearcat;
                let monthsDiffcat = currentMonthcat - birthMonthcat;

                if (monthsDiffcat < 0) {
                  yearsDiffcat--;
                  monthsDiffcat = 12 - birthMonthcat + currentMonthcat;
                }
                return (
                  <div key={pet.id}>
                    <Paper
                      style={webStyle.paperstyle as React.CSSProperties}
                    >
                      <Grid item style={webStyle.Grid3 as React.CSSProperties}>
                        <div style={{ marginRight: "17px" }}>
                          <Avatar
                            alt="pet Photo"
                            style={{ height: "64px", width: "64px" }}
                            src={pet.attributes.pet_photo}
                          />
                        </div>
                        <div>
                          <Typography style={webStyle.Grid5}>
                            {pet.attributes.name}
                          </Typography>
                          <Typography style={webStyle.Grid6}>
                            Pet Sex: <span>{pet.attributes.gender}</span> | Pet Age:
                            <span>
                              {" "}{`${yearsDiffcat} years and ${monthsDiffcat} months`}
                            </span>
                          </Typography>
                        </div>
                      </Grid>

                      <Grid item style={{ textAlign: "right" }}>
                        <IconButton
                          data-test-id="menuopen"
                          aria-haspopup="true"
                          aria-controls="long-menu"
                          aria-label="more"
                          onClick={(e) => this.handleClick(e, pet.id)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        {this.renderMenu()}
                      </Grid>
                    </Paper>
                  </div>
                );
              })}
          </div>
          <Button style={webStyle.addpetbtn as React.CSSProperties}
            onClick={() => this.OnAddPetCat()}
          > <ControlPointIcon style={{
            marginRight: 6,
            fontSize: 18,
          }} />Add cat profile</Button>
        </div>
      </>
    );
  };
  RenderPassword = () => {

    const {
      confirmPassword,
      showPassword,
      showYourPassword,
      password,
      oldpassword,
      showoldPassword
    } = this.state;

    const passwordValidationPo = this.validatePasswordPo();
    return (
      <>
        <Grid style={{ marginTop: "20px" }}
        >
          <Typography
            style={webStyle.labelsEmailPassword as React.CSSProperties}
          >
            Old Password
          </Typography>




          <PassField
            style={webStyle.inputEmailPassword as React.CSSProperties}
            type={showPassword ? "text" : "password"}
            placeholder="Enter old password"
            variant="outlined"
            value={oldpassword}
            onChange={this.handleChangeoldPassword}
            data-test-id="password_change"
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  onClick={this.handleTogglePassword}
                  data-test-id="togglePassword"
                >
                  {showPassword ? (
                    <VisibilityIcon
                      style={{ color: "#94A3B8", cursor: "pointer" }}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={{ color: "#94A3B8", cursor: "pointer" }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Typography style={{ color: "red" }} >{this.state.errorpassword}</Typography>
          <Typography
            style={webStyle.labelsEmailPassword as React.CSSProperties}
          >
            New Password
          </Typography>

          <PassField
            style={{ ...webStyle.inputEmailPassword } as React.CSSProperties}
            type={showoldPassword ? "text" : "password"} variant="outlined"
            onChange={this.handleChangePassword} placeholder="Enter new password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end"
                  onClick={this.handleToggleoldPassword} data-test-id="togglePassword"
                >
                  {showoldPassword ? (
                    <VisibilityIcon style={webStyle.visiblestyle} />
                  ) : (
                    <VisibilityOffIcon style={webStyle.visiblestyle} />
                  )}
                </InputAdornment>
              ),
            }}
            data-test-id="password_change"
            value={password}
          />
          {this.state.sameoldpass ? <Typography style={{ color: "red" }}>Old and new password can not be same</Typography> : null}

          <Typography style={webStyle.labelsEmailPassword as React.CSSProperties}>
            Confirm Password
          </Typography>

          <PassField
            InputProps={{
              endAdornment: (
                <InputAdornment
                  data-test-id="handle_pas_tgl"
                  position="end"
                  onClick={this.handleYourTogglePassword}
                >
                  {showYourPassword ? (
                    <VisibilityIcon
                      style={webStyle.visiblestyle}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={webStyle.visiblestyle}
                    />
                  )}
                </InputAdornment>
              ),
            }}
            placeholder="Confirm new password"
            style={webStyle.inputEmailPassword as React.CSSProperties}
            variant="outlined"
            data-test-id="confirm_password"
            onChange={this.handleConfirmPassword}
            type={showYourPassword ? "text" : "password"}
            value={confirmPassword}
            name="confirmPassword"

          />
          {this.state.samepass ? null : <Typography style={{ color: "red" }}>New passwords should be same</Typography>}

          <div>

            <Typography style={{ fontSize: "18px", fontWeight: 400, fontFamily: "inter", marginTop: "30px" }}>Password must contain</Typography>
            {[
              {
                message: "At least one capital letter",
                isValid: passwordValidationPo.isCapitalLetter,
              },
              {
                message: "At least one lowercase letter",
                isValid: passwordValidationPo.isLowerCaseLetter,
              },
              {
                message: "At least one number",
                isValid: passwordValidationPo.isNumber,
              },
              {
                message: "Minimum character length is 8 characters",
                isValid: passwordValidationPo.isMinLength,
              },
              {
                isValid: passwordValidationPo.isSpecialChracter,
                message: "At least one special character"
              }

            ].map((validItem, idx) => (
              <Grid
                style={{
                  alignItems: "center",
                  display: "flex",
                  marginTop: "7px",
                }}
                key={idx}
              >
                {validItem.isValid ? (
                  <CheckCircleIcon
                    style={{
                      marginRight: "5px",
                      color: "#34D399",
                    }}
                  />
                ) : (
                  <RemoveCircleIcon
                    data-test-id='removeIcon'
                    style={{
                      color: "#DC2626",
                      marginRight: "5px"
                    }}
                  />
                )}
                <span style={{ fontFamily: "inter" }}>{validItem.message}</span>
              </Grid>
            ))}
          </div>

          <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end", height: 50 }}>
            <Button disabled={!passwordValidationPo.isValid || !this.state.samepass} style={{ ...webStyle.linkstyle, textTransform: "none", color: !passwordValidationPo.isValid || !this.state.samepass ? "grey" : "#EA0c78", marginRight: "0px" }} onClick={this.openpassmodal}>Save</Button>
          </Box>


        </Grid>


      </>
    )
  }
  renderCountryDopDown = (inputType: string) => {
    const { classes }: any = this.props;
    return (
      <>
        <ClickAwayListener onClickAway={() => this.closeDropDowns(inputType)}>
          <Box className={inputType == "salutation" || inputType == "emergencySalutation" || inputType == "legalGuidancesSalutation" ? classes.salutationOuterContainer : classes.outerContainer}>
            <Box className={classes.innerBox}>
              {inputType == 'country' && this.renderCountryDropDown()}
              {inputType == 'salutation' && this.renderSalutation()}
              {inputType == 'emergencySalutation' && this.renderSalutation()}
              {inputType == 'legalGuidancesSalutation' && this.renderSalutation()}
              {inputType == "phoneNumberDropDown" && this.renderPhoneNumberDropDown()}
              {inputType == "emergencyPhoneNumberDropDown" && this.renderPhoneNumberDropDown()}
              {inputType == "legalGuardianPhoneNumberDropDown" && this.renderPhoneNumberDropDown()}
            </Box>
          </Box>
        </ClickAwayListener>
      </>
    )

  }
  renderSalutation = () => {
    const { classes }: any = this.props;
    return (
      <>
        {["Mr.", "Miss", "Mrs.", "Mx."].map((value: string, index: number) => {
          return (
            <>
              <MenuItem

                className={classes.menuItemText}
                data-test-id="select_Salutation"
                key={index}
                value={value}
                onClick={() => this.selectSalutation(value)}
              >
                {value}
              </MenuItem>
              <Divider />
            </>
          )
        })
        }
      </>
    )
  }
  renderCountryDropDown = () => {
    const { classes }: any = this.props;
    return (
      <>
        {configJSON.countries.map((country: any, index: any) => (
          <>
            <MenuItem className={classes.menuItemText}
              data-test-id="countryDropDown"
              key={index}
              value={country.label}
              onClick={() => this.selectCountry(country.label)}
            >
              {country.label}
            </MenuItem>
            <Divider />
          </>
        ))}

      </>
    )
  }
  renderPhoneNumberDropDown = () => {
    const { classes }: any = this.props;
    return (
      <>
        {configJSON.countries.map((country: any, index: number) => (
          <MenuItem className={classes.menuItemText}
            data-test-id="phoneNumberDropDownSelection"
            key={index}
            value={`${country.label} ${country.phone}`}
            onClick={() => this.selectPhoneNumberCountry(country.label, country.phone)}

          >
            {`${country.label} ${country.phone}`}
          </MenuItem>
        ))}
      </>
    )
  }

  renderAccordition = (title: string, status: boolean, key: string) => {
    const { classes }: any = this.props
    return (
      <>
        <Grid item xs={12} className={classes.acordition}>
          <Grid container justifyContent="space-between" alignItems="center" className={key == "legalGuardian" && !this.state.showLeagalGaurdianDetails && classes.Legalacordition}>
            <Grid item>
              <Typography variant="h6" style={webStyle.lableText}>{title}</Typography>
            </Grid>
            <Grid item
              style={webStyle.accorditionArrow}

              onClick={() => this.showAccorditionDetails(key)} data-test-id={key}>
              {status ? <ExpandLessIcon /> : <KeyboardArrowDown />}
              {/* <img src={status ? arrowUp : arrowDown}
                data-test-id={key}
                 /> */}
            </Grid>

          </Grid>

        </Grid>
      </>
    )
  }
  renderAddress = () => {
    const { classes }: any = this.props;
    return (
      <>
        {this.renderAccordition('Address', this.state.showAddress, 'address')}
        {this.state.showAddress && <>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom style={webStyle.lableText}>Address Line 1*</Typography>
            <SettingsTextFieldInput
              onChange={this.handleAddressChange}
              data-test-id="address1"
              fullWidth
              placeholder="E.g. 125 High Street"
              value={this.state.addressForm.addressLine1}
              name="addressLine1"
              variant="outlined"
              error={Boolean(this.state.addressFormErrors.addressLine1)}
              helperText={this.state.addressFormErrors.addressLine1}
            />

          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom style={webStyle.lableText}>Address Line 2 {" "}



            </Typography>

            <SettingsTextFieldInput
              onChange={this.handleAddressChange}
              data-test-id="address2"
              fullWidth
              placeholder="E.g.Kensington"
              value={this.state.addressForm.addressLine2}
              name="addressLine2"
              variant="outlined"
              type="text"
              error={Boolean(this.state.addressFormErrors.addressLine2)}
              helperText={this.state.addressFormErrors.addressLine2}

            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom style={webStyle.lableText}>Town / City*</Typography>
            <SettingsTextFieldInput
              type="text"
              onChange={this.handleAddressChange}
              data-test-id="town"
              value={this.state.addressForm.town}
              name="town"
              fullWidth
              placeholder="E.g. London"
              variant="outlined"
              error={Boolean(this.state.addressFormErrors.town)}
              helperText={this.state.addressFormErrors.town}

            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom style={webStyle.lableText}>County{" "}

            </Typography>
            <SettingsTextFieldInput
              fullWidth
              variant="outlined"
              onChange={this.handleAddressChange}
              data-test-id="county"
              value={this.state.addressForm.county}
              name="county"
              placeholder="E.g. Greater London "
              error={Boolean(this.state.addressFormErrors.county)}
              helperText={this.state.addressFormErrors.county}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom style={webStyle.lableText}>Postcode*</Typography>
            <SettingsTextFieldInput
              variant="outlined"
              fullWidth
              type="text"
              onChange={this.handleAddressChange}
              data-test-id="postcode"
              value={this.state.addressForm.postcode}
              name="postcode"
              placeholder="SW1A 1AA"
              error={Boolean(this.state.addressFormErrors.postcode)}
              helperText={this.state.addressFormErrors.postcode}

            />
          </Grid>
          <Grid item xs={12} style={{ position: 'relative' }}>
            <Typography variant="body1" style={webStyle.lableText} gutterBottom>
              Country / Region{" "}
            </Typography>
            <SettingsDropDownField
              variant="outlined"
              fullWidth
              value={this.state.addressForm.country}
              className={classes.countryDropDownInput}
              name="country"
              InputProps={{
                readOnly: true,
                endAdornment: <img src={downArrow} />
              }}
              onClick={() => this.openDropDowns('country')}
              data-test-id="country_popup"

            />
            {this.state.openCountryDropdownStatus &&
              this.renderCountryDopDown('country')}
          </Grid>
        </>
        }
      </>

    )
  }
  renderEmergencyContactDetails = () => {
    const { classes }: any = this.props;
    return (
      <>
        {this.renderAccordition("Emergency Contact Details", this.state.showEmergencyDetails, 'emergencyDetails')}
        {this.state.showEmergencyDetails &&
          <Grid item xs={12}>
            <Grid item container direction="row" spacing={2}>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom style={webStyle.lableText}>First Name*</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row">
                      <Grid item xs={3} className={classes.dropDownPosition}>
                        <SettingsDropDownField
                          variant="outlined"
                          fullWidth
                          value={this.state.emergencyDetails.salutation}
                          name="salutation"
                          InputProps={{
                            readOnly: true,
                            endAdornment: <img src={downArrow} />
                          }}
                          onClick={() => this.openDropDowns('emergencySalutation')}
                          data-test-id="emgergencySalutation"
                        />
                        {this.state.emergencysalutationDropDownStatus &&
                          this.renderCountryDopDown("emergencySalutation")}

                      </Grid>
                      <Grid item xs={9}>
                        <SettingsTextFieldInput
                          variant="outlined"
                          fullWidth
                          className={classes.dropDownInput}
                          onChange={this.handleEmergencyChange}
                          value={this.state.emergencyDetails.firstName}
                          data-test-id="firstNameInput"
                          placeholder="First Name"
                          name="firstName"
                          error={Boolean(this.state.emergencyDetailsErrors.firstName)}
                          helperText={this.state.emergencyDetailsErrors.firstName}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom style={webStyle.lableText}>Surname*</Typography>
                <SettingsTextFieldInput
                  variant="outlined"
                  fullWidth
                  type="text"
                  onChange={this.handleEmergencyChange}
                  data-test-id="surnameInput"
                  value={this.state.emergencyDetails.surname}
                  name="surname"
                  placeholder="Last Name"
                  error={Boolean(this.state.emergencyDetailsErrors.surname)}
                  helperText={this.state.emergencyDetailsErrors.surname}
                />


              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom style={webStyle.lableText}>Email</Typography>
                <SettingsTextFieldInput
                  variant="outlined"
                  fullWidth
                  type="email"
                  name="email"
                  value={this.state.emergencyDetails.email}
                  onChange={this.handleEmergencyChange}
                  data-test-id="emrgencyEmail"
                  placeholder="kevinbarrett@iamacat.com"
                  error={Boolean(this.state.emergencyDetailsErrors.email)}
                  helperText={this.state.emergencyDetailsErrors.email}

                />

              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom style={webStyle.lableText}>Phone Number*</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row">
                      <Grid item xs={6} sm={5} className={classes.dropDownPosition}>
                        <SettingsDropDownField
                          variant="outlined"
                          fullWidth
                          value={this.state.emergencyDetails.countrycode}
                          name="phNoCountry"
                          InputProps={{
                            readOnly: true,
                            endAdornment: <img src={downArrow} />
                          }}
                          onClick={() => this.openDropDowns('emergencyPhoneNumberDropDown')}
                          data-test-id="emergencyPhoneNumberDropDown"
                        />
                        {this.state.emergencyPhoneNumberDropDownStatus &&
                          this.renderCountryDopDown('emergencyPhoneNumberDropDown')}

                      </Grid>
                      <Grid item xs={6} sm={7}>
                        <SettingsTextFieldInput
                          variant="outlined"
                          fullWidth
                          className={classes.dropDownInput}
                          onChange={this.handleEmergencyChange}
                          value={this.state.emergencyDetails.phoneNumber}
                          data-test-id="phoneNumberInput"
                          placeholder="Phone Number"
                          name="phoneNumber"
                          error={Boolean(this.state.emergencyDetailsErrors.phoneNumber)}
                          helperText={this.state.emergencyDetailsErrors.phoneNumber}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        }
      </>
    )
  }
  renderLegalGaurdianDetails = () => {
    const { classes }: any = this.props
    return (
      <>
        {this.renderAccordition("Legal Guardian", this.state.showLeagalGaurdianDetails, 'legalGuardian')}
        {this.state.showLeagalGaurdianDetails && <>
          <Grid item xs={12} >
            <Grid item container direction="row" spacing={2}>
              <Grid item xs={12} className={classes.checkbox} container alignItems="center" spacing={4}>
                <Checkbox
                  onChange={() => this.manageLegalGaurdianDetails()}
                  data-test-id='guardianCheckBox'
                  inputProps={{
                    'aria-label': 'checkbox with default color',
                  }}
                  checked={this.state.guardiancheckboxStatus}
                  color="secondary"
                />
                <Typography variant="subtitle2" style={webStyle.checkBoxText}>
                  Copy Emergency Contact Details
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom style={webStyle.lableText}>First Name*</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row">
                      <Grid item xs={3} className={classes.dropDownPosition}>
                        <SettingsDropDownField
                          variant="outlined"
                          fullWidth
                          value={this.state.legalGuardianDetails.salutation}
                          name="salutation"
                          InputProps={{
                            readOnly: true,
                            endAdornment: <img src={downArrow} />
                          }}
                          onClick={() => this.openDropDowns('legalGuidancesSalutation')}
                          data-test-id="legalGuidancesSalutation"
                        />
                        {this.state.legalGuardiansalutationDropDownStatus &&
                          this.renderCountryDopDown("legalGuidancesSalutation")}

                      </Grid>
                      <Grid item xs={9}>
                        <SettingsTextFieldInput
                          variant="outlined"
                          fullWidth
                          className={classes.dropDownInput}
                          name="firstName"
                          value={this.state.legalGuardianDetails.firstName}
                          data-test-id="gaurdianFirstName"
                          placeholder="First Name"
                          onChange={this.handleLegalGaurdianChange}
                          error={Boolean(this.state.legalGuardianDetailsErrors.firstName)}
                          helperText={this.state.legalGuardianDetailsErrors.firstName}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom style={webStyle.lableText}>Surname*</Typography>
                <SettingsTextFieldInput
                  variant="outlined"
                  fullWidth
                  type="text"
                  onChange={this.handleLegalGaurdianChange}
                  data-test-id="gaurdianSurname"
                  value={this.state.legalGuardianDetails.surname}
                  name="surname"
                  placeholder="Last Name"
                  error={Boolean(this.state.legalGuardianDetailsErrors.surname)}
                  helperText={this.state.legalGuardianDetailsErrors.surname}
                />

              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" gutterBottom style={webStyle.lableText}>Email</Typography>
                <SettingsTextFieldInput
                  variant="outlined"
                  fullWidth
                  type="email"
                  name="email"
                  value={this.state.legalGuardianDetails.email}
                  onChange={this.handleLegalGaurdianChange}
                  data-test-id="legalGardianEmail"
                  placeholder="kevinbarrett@iamacat.com"
                  error={Boolean(this.state.legalGuardianDetailsErrors.email)}
                  helperText={this.state.legalGuardianDetailsErrors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="body1" style={webStyle.lableText}>Phone Number*</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction="row">
                      <Grid item xs={6} sm={5} className={classes.dropDownPosition}>
                        <SettingsDropDownField
                          variant="outlined"
                          fullWidth
                          value={this.state.legalGuardianDetails.countrycode}
                          name="countrycode"
                          InputProps={{
                            readOnly: true,
                            endAdornment: <img src={downArrow} />
                          }}
                          onClick={() => this.openDropDowns('legalGuardianPhoneNumberDropDown')}
                          data-test-id="legalGuardianPhoneNumberDropDown"
                        />
                        {this.state.legalguidancePhoneNumberDropDownStatus &&
                          this.renderCountryDopDown("legalGuardianPhoneNumberDropDown")}

                      </Grid>
                      <Grid item xs={6} sm={7}>
                        <SettingsTextFieldInput
                          variant="outlined"
                          fullWidth
                          className={classes.dropDownInput}
                          onChange={this.handleLegalGaurdianChange}
                          value={this.state.legalGuardianDetails.phoneNumber}
                          data-test-id="gaurdianPhoneNumber"
                          placeholder="Phone Number"
                          name="phoneNumber"
                          error={Boolean(this.state.legalGuardianDetailsErrors.phoneNumber)}
                          helperText={this.state.legalGuardianDetailsErrors.phoneNumber}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>}
      </>
    )
  }
  RenderInformation = () => {
    const { classes }: any = this.props;
    return (

      <Box style={{ width: "100%", height: "100%", backgroundColor: "#fff", display: "block" }}>
        <Typography variant="h6" style={webStyle.lableText}>Personal Information</Typography>
        <Grid item xs={12} className={classes.profileSection}>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3}>

            <Grid item xs={12} sm={4}>

              <Box style={{ position: "relative" }}>
                <Avatar src={this.state.imageUrl}
                  className={classes.profileImg}
                  onClick={() => this.setState((prevState) => ({ editpupup: !prevState.editpupup }))}
                  data-test-id="popupbtn"
                />
                <img src={editPencilIcon} className={classes.profileEditIcon}
                  onClick={() => this.setState((prevState) => ({ editpupup: !prevState.editpupup }))}
                />

                {this.state.editpupup &&
                  <>
                    <ClickAwayListener onClickAway={() => this.setState({ editpupup: false })}>
                      <Box className={classes.editPopup}>

                        <label htmlFor="icon-button-file">
                          <input
                            accept="image/*"
                            name="imageUplode"
                            type="file"
                            id="icon-button-file"
                            onChange={this.handleFileChange}
                            style={{ display: "none" }}
                            ref={this.fileInputRef}
                            data-test-id="handleFileChangeImage"
                          />
                          <MenuItem
                            className={classes.menuItemText}
                            data-test-id="select_Salutation"
                          >
                            <EditIcon style={{ marginRight: "9%", color: "black !important" }} />
                            {this.state.photoUploaded ? " Edit photo" : "Add photo"}
                          </MenuItem>
                        </label>
                        {this.state.photoUploaded && (
                          <MenuItem
                            className={classes.menuItemText}
                            onClick={this.handledeletephoto}
                            data-test-id="deletephoto"
                          >
                            <DeleteOutlineIcon style={{ marginRight: "10%" }} />
                            Delete photo
                          </MenuItem>
                        )}
                      </Box>
                    </ClickAwayListener>
                  </>}
              </Box>
            </Grid>

            <Grid item xs={12} sm={8}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Typography variant="subtitle2" style={webStyle.petLabelText}>
                        Pet Profiles
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center" spacing={1}
                        data-test-id="navigation"
                        onClick={this.navigateAddPets}
                        style={webStyle.accorditionArrow}
                      >
                        <img src={addPlus} style={{ marginRight: 6 }} width={"24px"} />
                        <Typography display="inline" variant="subtitle2"
                          style={webStyle.AddPetText} >
                          Add Pet
                        </Typography>
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" justifyContent="flex-start" spacing={2} alignItems="center">
                    {this.state.savedPet.map((pet: any, index: number) => {
                      return (<Grid item xs={6} sm={3} key={index}>
                        <>
                          <Avatar src={pet?.attributes?.pet_photo} style={webStyle.petPhoto}
                            onClick={() => this.OnEditPet("View", pet?.id)}
                            data-test-id='view_pet'
                          />
                          <Typography variant="subtitle2" className={classes.profileName}>{pet?.attributes?.name}</Typography>
                        </>
                      </Grid>)
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom style={webStyle.lableText}>Full Name*</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row">
                    <Grid item xs={4} sm={3} md={2} className={classes.dropDownPosition}>
                      <SettingsDropDownField
                        variant="outlined"
                        fullWidth
                        value={this.state.profileInfo.nameSalutation}
                        name="salutation"
                        InputProps={{
                          readOnly: true,
                          endAdornment: <img src={downArrow} />
                        }}
                        onClick={() => this.openDropDowns('salutation')}
                        data-test-id="profileSalutation"

                      />
                      {this.state.salutationDropDownStatus &&
                        this.renderCountryDopDown('salutation')}

                    </Grid>
                    <Grid item xs={8} sm={7} md={10}>
                      <SettingsTextFieldInput
                        variant="outlined"
                        name="firstName"
                        value={this.state.profileInfo.firstName}
                        fullWidth
                        className={classes.dropDownInput}
                        data-test-id="profile_fullName"
                        placeholder="First Name"
                        onChange={this.handleProfileInfoChange}
                        error={Boolean(this.state.profileInfoErrors.firstName)}
                        helperText={this.state.profileInfoErrors.firstName}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom style={webStyle.lableText}>Surname*</Typography>
              <SettingsTextFieldInput
                variant="outlined"
                fullWidth
                name="surname"
                data-test-id="profile_surname"
                value={this.state.profileInfo.surname}
                placeholder="Last Name"
                onChange={this.handleProfileInfoChange}
                error={Boolean(this.state.profileInfoErrors.surname)}
                helperText={this.state.profileInfoErrors.surname}
              />
            </Grid>
            <Grid item xs={12}>
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "baseline" }}>

                <Typography variant="body1" gutterBottom style={webStyle.lableText}>Email</Typography>
                <Button onClick={this.openMail} style={{ ...webStyle.lableText, color: "#EF0C7B", textDecorationLine: "underline", textTransform: "none" }}>Change Email</Button>

              </div>
              <SettingsTextFieldInput
                data-test-id='profile_email'
                variant="outlined"
                fullWidth
                type="email"
                name="email"
                value={this.state.profileInfo.email}

                error={Boolean(this.state.profileInfoErrors.email)}
                helperText={this.state.profileInfoErrors.email}
                placeholder="kevinbarrett@iamacat.com"

              />
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom style={webStyle.lableText}>Phone Number*</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row">
                    <Grid item xs={6} sm={4} className={classes.dropDownPosition}>
                      <SettingsDropDownField
                        variant="outlined"
                        fullWidth
                        value={this.state.profileInfo.countryCode}
                        name="phNoCountry"
                        InputProps={{
                          readOnly: true,
                          endAdornment: <img src={downArrow} />
                        }}
                        onClick={() => this.openDropDowns('phoneNumberDropDown')}
                        data-test-id="phonenumberDropDown"

                      />
                      {this.state.phoneNumberDropDownStatus &&
                        this.renderCountryDopDown('phoneNumberDropDown')}

                    </Grid>
                    <Grid item xs={6} sm={8}>
                      <SettingsTextFieldInput
                        variant="outlined"
                        fullWidth
                        className={classes.dropDownInput}
                        value={this.state.profileInfo.phoneNumber}
                        data-test-id="profile_phonenumber"
                        placeholder="Phone Number"
                        name="phoneNumber"
                        onChange={this.handleProfileInfoChange}
                        error={Boolean(this.state.profileInfoErrors.phoneNumber)}
                        helperText={this.state.profileInfoErrors.phoneNumber}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {this.renderAddress()}
            {this.state.savedPet && this.state.savedPet.length > 0 && <>
              {this.renderEmergencyContactDetails()}

              {this.renderLegalGaurdianDetails()}
            </>
            }
            <Grid item xs={12}>
              <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <Button data-test-id="renderbox" style={{ ...webStyle.profileInfoSaveLink, color: this.state.isdisabled ? "#CBD5E1" : "#EA0c78" } as React.CSSProperties}
                  onClick={this.saveProfilePO}
                  disabled={this.state.isdisabled}
                >Save</Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  }
  renderScreens = () => {
    return (
      <>
        {this.state.screencount === 1 ?
          this.RenderInformation()
          : null}
        {this.state.screencount === 3 ? <Box style={{ paddingLeft: "50px", paddingRight: "25px" }}>
          {this.RenderSavedPet()}
        </Box> : null}

        {this.state.screencount === 4 ? <Box style={{ width: "80%", height: "100%", backgroundColor: "#fff", marginLeft: "15%", display: "block" }}>


          <Typography style={{ fontSize: "25px", color: "#000", fontWeight: 700 }}>Reset Password</Typography>

          {this.RenderPassword()}



          <Typography style={{ fontSize: 24, fontWeight: 700, color: "#000" }}>Delete Account</Typography>
          <Typography style={{ fontSize: 14, fontWeight: 400, color: "#000", marginTop: "25px" }}>Deleting your account will remove all of your information from our database.<br />This cannot be undone.</Typography>
          <Box style={{ width: "100%", display: "flex", justifyContent: "flex-end", marginTop: "25px" }}>
            <Button style={{ ...webStyle.linkstyle, marginRight: "0px" } as React.CSSProperties} onClick={this.openModal}>Delete Account</Button>
          </Box>


        </Box> : null}



      </>
    )
  }



  renderMailChange = () => {
    return (
      <>
        <Modal
          open={this.state.openMail}

          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            style={{
              position: "absolute",
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 625,
              backgroundColor: "#fff",
              boxShadow: "24px",
              padding: 21,
              borderRadius: 8,
            }}
          >
            <Box style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", height: 30 }}>
              <Button onClick={this.closeMail}>
                <img src={cancel} alt="" />
              </Button>
            </Box>

            <Box style={{ display: "flex", justifyContent: "center", marginBottom: "40px" }}>

              <Typography variant="h5">Change Email Address</Typography>
            </Box>

            <Box style={{ padding: "0px 50px" }}>
              <Typography>New Email Address*</Typography>

              <CssTextField
                style={{

                  fontSize: "14px",
                  fontWeight: 400,
                  borderRadius: "5px",
                  marginTop: "10px",
                  height: "40px",
                  width: "100%",
                } as React.CSSProperties}
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleMailChange}
                data-test-id="email-change"
                variant="outlined"

                placeholder="kevinbarrett@iamacat.com"
              />
              {this.state.emailError && <Typography style={{ color: "red", marginTop: "15px" }}>{this.state.emailError}</Typography>}
            </Box>

            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-evenly",
                alignItems: "center",
                height: 75,
                marginTop: "40px"
              }}
            >
              <Button
                data-test-id="btnCloseModal"
                variant="contained"
                onClick={this.closeMail}
                style={{
                  width: 237, height: 56, color: "red",
                  display: "flex", justifyContent: "center",
                  alignItems: "center", backgroundColor: "#fff", border: "2px solid #EA0C78",
                  borderRadius: 8, fontSize: 16, fontWeight: 700, textTransform: "none"
                }}
              >
                Cancel
              </Button>
              <Button
                data-test-id="btnCloseModal"
                variant="contained"
                onClick={() => this.handleChangeMail(this.state.email, "PO")}
                disabled={Boolean(this.state.emailError)}
                style={{
                  width: 237, height: 56, color: "#fff",
                  display: "flex", justifyContent: "center",
                  alignItems: "center", backgroundColor: this.state.emailError ? "grey" : "#EA0C78",
                  borderRadius: 8, fontSize: 16, fontWeight: 700, textTransform: "none"
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Modal>

      </>
    )
  }


  // Customizable Area End

  render() {
    const { classes }: any = this.props;
    return (
      // Customizable Area Start
      <>
        {this.renderMailChange()}
        {this.state.flashSlackPo && (
          <CustomizedSnackbars
            message={' Profile Updated Sucessfully'}
            toasterType="success"
            openToaster={this.state.flashSlackPo} closeStatus={this.closeStatus} duration={20000} />
        )}
        {this.state.passslack && (
          <CustomizedSnackbars
            message={' Password Updated Sucessfully'}
            toasterType="success"
            openToaster={this.state.passslack} closeStatus={this.closeStatus} duration={20000} />

        )}

        <CustomizedSnackbars
          duration={5000} openToaster={this.state.toasterStatus}
          message={this.state.toasterMessage}
          toasterType={this.state.toasterType} closeStatus={this.closeStatus} />
        <PetOwnerNavigationMenu  navType="fullNavbar" profilePhoto={this.state.navbarProfilePhoto}  navigation={this.props.navigation} id="" />
        <Container maxWidth={false} style={webStyle.settingsBglayout}>
          <Grid item xs={12} className={classes.outerlayout}>
            <Grid container direction="row" justifyContent="flex-start" className={classes.innerContainer} >
              <Grid item xs={12} sm={4} style={webStyle.mainbox}>
                <Box sx={webStyle.sidenav}>
                  <Box sx={webStyle.btngroup}>
                    <SelectButton style={{
                      ...webStyle.btns,
                      textTransform: "none",
                      backgroundColor: this.state.screencount === 1 ? "#fff" : "#CCEDFB",
                      fontWeight: this.state.screencount === 1 ? 700 : 400
                    }}
                      data-test-id="screencount1"
                      onClick={() => this.setState({ screencount: 1 })}>Personal Information</SelectButton>
                    <SelectButton
                      style={{
                        ...webStyle.btns,
                        textTransform: "none",
                        backgroundColor: this.state.screencount === 3 ? "#fff" : "#CCEDFB",
                        fontWeight: this.state.screencount === 3 ? 700 : 400
                      }}
                      data-test-id="screencount3"
                      onClick={() => this.setState({ screencount: 3 })}>Pet Profiles</SelectButton>
                    <SelectButton
                      style={{
                        ...webStyle.btns,
                        textTransform: "none",
                        backgroundColor: this.state.screencount === 4 ? "#fff" : "#CCEDFB",
                        fontWeight: this.state.screencount === 4 ? 700 : 400
                      }}
                      data-test-id="screencount4"
                      onClick={() => this.setState({ screencount: 4 })}>Settings</SelectButton>
                    <Button style={webStyle.logoutbtn as React.CSSProperties} onClick={this.openlogoutmodal} data-test-id="logoutCallId">
                      Log out
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Box style={webStyle.multiscreens} className={classes.contentLayout}>
                  {this.renderScreens()}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={webStyle.footerLayout}>
            <Footer footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData} />
          </Grid>
        </Container>

        <CustomsettingModal
          isVisible={this.state.isVisible}
          hideModal={this.hideModal}
          callApi={this.callApiForDelete}
          typography1="Delete Account"
          typography2="Are you sure you want to delete this account?"
          buttontext1="Yes"
          buttontext2="No"
        />

        <CustomsettingModal
          isVisible={this.state.islogoutVisible}
          hideModal={this.hideModal}
          callApi={this.handleLogout}
          typography1="Logout"
          typography2="Would you like to sign out of account now?"
          buttontext2="Stay Logged In"
          buttontext1="Sign Out Now"
        />

        <CustomsettingModal
          isVisible={this.state.ispassvisible}
          hideModal={this.hideModal}
          callApi={() => this.ResetPasswordForPO("PO")}
          typography1="Reset Password"
          typography2="Are you sure want to reset password?"
          buttontext2="No"
          buttontext1="Yes"
        />

        <CustomsettingModal
          isVisible={this.state.deletepetmodal}
          hideModal={this.hideModal}
          callApi={this.handleRemovePet}
          typography1="Delete Pet Profile"
          typography2="Are you sure you want to delete this pet profile?"
          buttontext2="No"
          buttontext1="Yes"
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start



const Infodiv: any = styled(Box)({
  '& [class^="MuiInputBase-root-"]::before': {
    borderBottom: "none !important",
    position: "inherit"
  },
  '& [class^="MuiNativeSelect-outlined-"]': {
    borderRadius: "8px",
  },

  "& .Component-root-616": {
    borderRadius: "8px",
    color: "#000"
  },
  "& .Component-root-555": {
    color: "#000"
  },
  "& .MuiNativeSelect-icon-561": {
    color: "#000",
    marginRight: 20
  }
})



const PassField: any = styled(TextField)({
  '& div': {
    borderRadius: "8px",
    height: "44px"
  },
})





const webStyle = {
  settingsBglayout: {
    backgroundColor: "#CCEDFB",
  },
  footerLayout: {
    marginTop: 100
  },

  editbutton1: {
    width: "100%",
    height: "50%",
    display: "flex",
    justifyContent: 'flex-start',
    paddingLeft: "5%",
    borderRadius: "8px",
    color: "black",
    textTranform: 'none'
  },

  editbutton2: {
    height: "50%",
    width: "100%",
    paddingLeft: "16%",
    display: "flex",
    color: "black",
    borderRadius: "8px",
    textTranform: 'none'
  },

  visiblestyle: {
    color: "#94A3B8",
    cursor: "pointer"
  },
  paperstyle: {

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: "10px",
    flexWrap: "nowrap",
    marginTop: "2%",
    position: "relative",
    boxShadow:
      "rgb(223 223 223 / 10%) 0px 8px 32px 0px, rgba(67, 134, 161, 0.3) 1px 1px 8px 0px",
    width: "100%",
    borderBottom: "2px solid #8BD7F5"

  },
  petheading: {
    fontSize: 24,
    fontWeight: 400,
    color: "#000",
    textTransform: "none"
  },
  addpetbtn: {
    fontSize: 14,
    fontWeight: 700,
    color: "#EA0C78",
    textDecorationLine: "underline",
    display: "flex",
    textTransform: 'none'
  },
  mainbox: {
    minHeight: "600px",
    display: "flex",
    padding: 10,
    justifyContent: "space-between",
    marginTop: "110px",
  },
  sidenav: {
    width: "325px",
    backgroundColor: "#B2E4F8",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 10,
  },
  btngroup: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    gap: "10px"
  },
  btns: {
    width: "100%",
    height: "63px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: "CCEDFB",
    color: "#000",
    border: "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: 400,
    '& .MuiButton-label': {
      marginLeft: 20
    }
  },
  logoutbtn: {
    width: "100%",
    height: "63px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#4386A1",
    border: "none",
    borderRadius: "8px",
    fontSize: "16px",
    color: "#fff",
    fontWeight: 700,
    textTransform: "none"
  },
  multiscreens: {
    display: "flex",
    padding: "10px 70px",
    marginTop: 110
  },
  inputbox: {
    border: "1px solid #CBD5E1",
    fontSize: "16px",
    width: "100%",
    height: "38px",
    borderRadius: "8px",
    marginTop: "8px",
    marginBottom: "3px",
    paddingLeft: "20px"
  },
  lableText: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightBold
  },
  linkstyle: {
    fontSize: "16px",
    fontWeight: 700,
    textDecorationLine: "underline",
    marginRight: 50,
    textTransform: 'none',
    color: "#ED2258"
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 670,
    height: 312,
    bgcolor: "#fff",
    boxShadow: 24,
    p: 4,
    borderRadius: 8
  },
  inputEmailPassword: {
    width: "100%",
    height: "50px",
    // marginBottom: "20px",
    borderRadius: "8px",
    fontSize: "14px",
    marginTop: "5px",
    fontWeight: 400,
  },
  labelsEmailPassword: {
    color: "#000",
    fontWeight: 500,
    font: "inter",
    textTransform: "none",
    fontFamily: "inter",
    marginTop: "20px",
    fontSize: "16px"
  },
  Grid3: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  Grid4: {
    backgroundColor: " rgba(203, 213, 225, 1)",
    borderRadius: "50%",
    padding: "4px",
    height: "60px",
    width: "60px",
    objectFit: "cover",

  },
  Grid5: {
    fontWeight: 700,
    fontSize: "16px",

    color: "rgba(51, 65, 85, 1)",
  },
  Grid6: {
    fontWeight: 400,
    fontSize: "14px",
    color: "rgba(100, 116, 139, 1)",
  },
  petLabelText: {
    color: colors.darkBlack,
    fontWeight: theme.typography.fontWeightBold
  },
  checkBoxText: {
    color: colors.darkBlack,
    fontWeight: theme.typography.fontWeightRegular
  },
  AddPetText: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'underline'
  },

  petPhoto: {
    width: 64,
    height: 64,
    cursor: 'pointer'


  },
  profileInfoSaveLink: {
    fontSize: "16px",
    fontWeight: theme.typography.fontWeightBold,
    textDecorationLine: "underline",
    textTransform: 'none',
    color: theme.palette.secondary.main
  },
  errorMessageLayout: {
    fontWeight: theme.typography.fontWeightRegular,
    marginTop: 10

  },
  accorditionArrow: {
    cursor: 'pointer'
  },
  phoneInput: {
    width: "40%",
    border: "1px solid #CBD5E1",
    fontSize: "16px",
    borderLeft: "none",
    paddingLeft: "15px",
    borderBottomRightRadius: "8px",
    borderTopRightRadius: "8px",
  },
  nameInput: {
    border: "1px solid #CBD5E1",
    width: "95%",
    borderLeft: "none",
    fontSize: "16px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    paddingLeft: "5px",
  },
  customSelectDropDown: {
    borderRadius: "8px",
    borderBottomRightRadius: "0px",
    borderTopRightRadius: "0px",
    width: "25%",
    backgroundColor: "#E0F4FC",
    color: "#000"
  },
  outerContainer: {
    position: "absolute" as const,
    zIndex: 9,
    width: "100%",
    maxHeight: "fit-content",
    borderRadius: 8,
    backgroundColor: "#ffffff",
    boxShadow: boxShadows.whiteShadow,
    overflowY: 'scroll'
  },
  innerBox: {
    padding: "0.8rem"
  }
};

export const SettingsStyles = ((theme: Theme) =>
  createStyles({
    salutationOuterContainer: {
      position: "absolute" as const,
      zIndex: 9,
      width: "100%",
      maxHeight: "fit-content",
      borderRadius: 8,
      backgroundColor: "#ffffff",
      boxShadow: boxShadows.whiteShadow,
    },
    outerContainer: {
      position: "absolute" as const,
      zIndex: 9,
      width: "100%",
      maxHeight: "fit-content",
      height: '320px',
      borderRadius: 8,
      backgroundColor: "#ffffff",
      boxShadow: boxShadows.whiteShadow,
      overflowY: 'scroll'
    },
    innerBox: {
      padding: "0.5rem"
    },
    divider: {
      background: "#F1F5F9"
    },
    menuItemText: {
      color: theme.palette.common.black,
      fontSize: 14,
      padding: '0.5rem',
      fontWeight: theme.typography.fontWeightRegular,
      '&:hover': {
        background: colors.primaryBlue,
        borderRadius: 8
      }
    },
    dropDownInput: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.primaryBlue,

        border: "1px solid #CBD5E1",
        borderRadius: " 0 8px 8px 0"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.primaryBlue,
        border: "1px solid #CBD5E1",
        borderRadius: "0 8px 8px 0"
      },
    },
    countryDropDownInput: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.primaryBlue,

        border: "1px solid #CBD5E1",
        borderRadius: 8
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: colors.primaryBlue,

        border: "1px solid #CBD5E1",
        borderRadius: 8
      },
    },
    dropDownPosition: {
      position: "relative"
    },
    showCount: {
      position: "absolute",
      transform: "translate(60%,50%)",
      top: 0,
      fontSize: 20,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.white
    },
    petPhoto: {
      width: 64,
      height: 64,
      cursor: 'pointer'
    },
    petPhotoOpacity: {
      width: 64,
      height: 64,
      opacity: 0.5,
      cursor: 'pointer'
    },
    profileName: {
      color: theme.palette.common.black,
      fontWeight: theme.typography.fontWeightRegular,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    },
    outerlayout: {
      padding: "0 50px",
      [theme.breakpoints.down('xs')]: {
        padding: "0px"
      }
    },
    innerContainer: {
      backgroundColor: theme.palette.common.white,
      borderRadius: "0px 0px 15px 15px",
      padding: 20,
      [theme.breakpoints.down('xs')]: {
        padding: 10,
      }

    },
    selectedMenutext: {
      color: theme.palette.common.black,
      fontSize: 14,
      padding: '0.5rem',
      fontWeight: theme.typography.fontWeightRegular,
      background: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.main,
        borderRadius: 8
      }
    },
    profileImg: {
      width: "156px",
      height: "156px",
      // marginTop: 20,
      objectFit: "cover",
      border: "solid 4px #B2E4F8",
    },
    profileEditIcon: {
      position: "absolute",
      right: "32%",
      bottom: 0,
      cursor: 'pointer'
    },
    editPopup: {
      width: "184px",
      backgroundColor: theme.palette.common.white,
      position: "absolute",
      zIndex: 99,
      borderRadius: "8px",
      left: '49%',
      boxShadow: boxShadows.blueShadow,
      border: "1px solid #E2E8F0",
      padding: '0.2rem',
      top: "73%"
    },
    popupEditText: {
      fontSize: 16,
      textTransform: 'none',
      color: theme.palette.common.black
    },
    profileSection: {
      margin: " 20px 0px 20px 0px"
    },
    acordition: {
      margin: " 15px 0px 10px 0px"

    },
    Legalacordition: {
      marginBottom: 300
    },
    checkbox: {
      marginTop: 20,
      marginBottom: 20
    },
    accordianmain: {
      backgroundColor: "transparent",
      border: "none",
      boxShadow: "none"

    },
    contentLayout: {
      [theme.breakpoints.down('xs')]: {

        padding: '5px !important'
      }
    }

  }))
export default withStyles(SettingsStyles)(Settings2)

const CssTextField = withStyles({
  root: {


    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: "8px",
        borderColor: '#CBD5E1',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#54A7C9',
      },

      '&.Mui-focused fieldset': {
        borderWidth: "2px",
        borderRadius: "8px",
        borderColor: '#54A7C9',
      },
    },
  },
})(TextField);
// Customizable Area End
