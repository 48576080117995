// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab";
import { handleNavigation } from "../../../components/src/CommonFunctions";
import { AdditionalServicesforPets } from "./types";
import { setStorageData } from "framework/src/Utilities";

interface HotelDetails {
  hotel_information: {
    pet_type: "daycare_services";
    budget_per_day: number;
  };
  day_care_services: {
    price_for_one_day(price_for_one_day: string, arg1: string): unknown;
    data: {
      attributes: {
        price_for_one_day: string;
      };
    }[];
  }; pet_type: string; 
}
export interface CancelResponce{
  message:string;
  errors:Errors[]
}
export interface Errors{
  message:string
}
interface LinkObject {
  url: string;
  title: string;
}

interface RoomData{
  roomId: number;
  price: number;
  name: string;
  pets: string[];
}
interface APIPayloadType {
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
  contentType?: string;
  method: string;
}

export interface Item {
  id: string;
  type: string;
  attributes: {
    id: string;
    price: number;
    state: string;
    full_address: string;
    city: string;
    reservation_date: string;
    zip_code: number,
    created_at: string;
    updated_at: string;
    booking_status: string;
    slot_start_time: string;
    slot_end_time: string;
    image: string;
    service_name: string;
  };
}


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  propId: string;
}

interface SProps {
  itemsList: Item[];
  searchedItem: Item[];
  searchText: string;
  startDate: string;
  endDate: string;
  isSortOpen: boolean;
  order: string;
  password: string;
  isEditOpen: boolean;
  anchorElDodservice: any;
  checkedServicesDog: any[],
  checkedServicesCat: any[],
  checkedServices: any[],
  roomTypeDog: string,
  roomopen: boolean,
  roomopenfordog: boolean;
  iscancelVisible: boolean;
  roomTypeCat: string;
  anchorElCatservice: any;
  PetList: any;
  petPopUp: boolean;
  reservation_id: string;
  reservationData: any;
  totalcalculationdays: number;
  finaltotalcalculation: number;
  ReservationLoader: boolean;
  hotelId: string;
  tailoredCancellation: any;
  serviceKey: string;
  standardCancellation: any;
  socialLinksData: { icons: string, navigationUrl: string }[];
  poBOLinksData: LinkObject[];
  toasterMessage: string,
  toasterType: Color,
  toasterStatus: boolean
  dogRoomData: RoomData[];
  catRoomData: RoomData[];
  displayPrice: string;
  numberOfWeeks: number;
}

interface SSProps {
  ssId: string;
}

export default class ReservationsController extends BlockComponent<
  Props,
  SProps,
  SSProps
> {
  uniqueSessionRequesterId: string = "";
  authToken: string = "";
  getProductItemListApiCallId: string = "";
  createAccountApiCallId: string = "";
  userAllPetsListApiCallId: string = "";
  ViewOrderApiCallId: string = "";
  CancelReservationApiCall: string = "";
  getStandardPolicyApiCallId: string = "";
  getPolicyApiCallId: string = ""
  roomsDataCallId: string = "";


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];

    this.state = {
      itemsList: [],
      searchedItem: [],
      searchText: '',
      startDate: '',
      endDate: "",
      isSortOpen: false,
      order: "descending",
      password: "",
      isEditOpen: false,
      anchorElDodservice: null,
      checkedServicesDog: [],
      checkedServicesCat: [],
      checkedServices: [],
      roomTypeDog: "Single Suite",
      roomopen: false,
      roomopenfordog: false,
      iscancelVisible: false,
      roomTypeCat: "Single Suite",
      anchorElCatservice: null,
      PetList: [],
      petPopUp: false,
      reservation_id: "",
      reservationData: 
      {
        id: "",
        type: "",
        attributes: {
          status: "",
          hotel_id: 0,
          account_id: 0,
          hotel_additional_service_id: null,
          additional_service_ids: null,
          check_in_date: "",
          price: "",
          number_of_dogs: 0,
          number_of_cats: 0,
          reject_message: null,
          room_type: null,
          room_type_for_dog: "",
          room_type_for_cat: "",
          additional_services_for_dog: [],
          additional_services_for_cat: [],
          dog_room_id: null,
          cat_room_id: null,
          pet_count_for_cat: 0,
          pet_count_for_dog: 0,
          pre_payment_amount: "",
          room_for_dogs: [],
          room_for_cats: [],
          select_pets: [],
          day_care_service_type: "",
          days: [],
          day_care_price: "",
          weekly_price: "",
          additional_notes: "",
          pet_owner_name: "",
          pets: {
            data: []
          },
          day_care_service_selected_pets: {
            data: []
          },
          date: "",
          hotel_information: {
            id: "",
            name: "",
            category: null,
            check_in: null,
            check_out: null,
            account_id: 0,
            created_at: "",
            updated_at: "",
            address: null,
            room_type: null,
            phone_number: "",
            service_type: "",
            latitude: 0,
            longitude: 0,
            description: "",
            property_name: null,
            license_number: "",
            country_region: null,
            street_address: null,
            flat_suite: null,
            city_town: null,
            postcode: null,
            email: "",
            daycare_price: null,
            additional_services: [],
            title: "",
            budget_per_day: 0,
            rating: null,
            pet_type: "",
            active: false,
            check_in_start: null,
            check_in_end: null,
            check_out_start: null,
            check_out_end: null,
            minimum_stay: null,
            maximum_stay: null,
            advance_notice: null,
            blocked_days: [],
            radius: null,
            location: null,
            establishment_type: null,
            price: null,
            daycare_price_for_dog: 0,
            daycare_price_for_cat: 0,
            hotel_type: [],
            phone_number_country_code: "",
            representative_country_code: null,
            legal_business_name: "",
            vat_number: "",
            hotel_address_line_1: "",
            hotel_address_line_2: "",
            hotel_city: "",
            hotel_country: "",
            hotel_postcode: "",
            hotel_country_region: "",
            first_name: null,
            surname: null,
            representative_phone_number: null,
            address_line_1: null,
            address_line_2: null,
            country: null,
            is_active: false,
            cover_image_id: 0,
            status: "",
            reason_for_reject: null,
            representative_contact_title: null,
            is_featured: false
          },
          images: [],
          pet_information: null,
          for_cat: {
            additional_services_for_cat: [],
            room_type_for_cat: []
          },
          for_dog: {
            room_type_for_dog: []
          },
          hotel_reviews_count: 0,
          chat_id: [],
          transactions: null,
          check_out_date: ""
        }
      },
      totalcalculationdays: 0,
      finaltotalcalculation: 0,
      ReservationLoader: true,
      hotelId: "",
      tailoredCancellation: "",
      serviceKey: "",
      standardCancellation: [],
      socialLinksData: [],
      poBOLinksData: [],
      toasterMessage: "",
      toasterType: 'success',
      toasterStatus: false,
      dogRoomData: [],
      catRoomData: [],
      displayPrice: "",
      numberOfWeeks: 1
  };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }



  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      this.handleNavigationPayload(message);
      return;
    }

    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      this.handleSessionResponse(message);
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleAPIResponse(message);
    }
  }

  handleNavigationPayload(message: Message) {
    const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
    if (sessionData) {
      setStorageData("reservation_id",sessionData?.selecteddataId)
      this.setState({ reservation_id: sessionData?.selecteddataId }, () => {
        this.ViewOrder();
      });
    }
  }

  handleSessionResponse(message: Message) {
    let requesterId = message.getData(getName(MessageEnum.SessionRequestedBy));
    if (requesterId === this.uniqueSessionRequesterId) {
      const sessionToken = message.getData(getName(MessageEnum.SessionResponseToken));
      this.authToken = sessionToken;
    }
  }

  handleAPIResponse(message: Message) {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.userAllPetsListApiCallId) {
      this.userPetListApiResponce(responseJson);
    }

    if (apiRequestCallId === this.ViewOrderApiCallId) {
      this.handleViewOrderApiResponse(responseJson);
    }

    if (apiRequestCallId === this.getPolicyApiCallId) {
      this.getCancelApiResponce(responseJson);
    }

    if (apiRequestCallId === this.getStandardPolicyApiCallId) {
      this.setState({ standardCancellation: responseJson.data });
    }

    if (apiRequestCallId === this.CancelReservationApiCall) {
      this.handleCancelReservationApiResponse(responseJson);
    }
    if(apiRequestCallId === this.roomsDataCallId) {
      this.handleRoomApiResponse(responseJson);
    }
  }

  handleViewOrderApiResponse(responseJson: any) {
    this.setState({
      reservationData: responseJson.data,
      hotelId: responseJson.data.attributes.hotel_information.id
    }, () => {
      this.getRoomsData();
      this.openViewModal();
    });
  };

  handleCancelReservationApiResponse(responseJson: CancelResponce) {
    if (responseJson && !responseJson.errors) {

      this.setState({ iscancelVisible: false, toasterStatus: true, toasterMessage: responseJson.message, toasterType: 'success' });
      setTimeout(() => {
        handleNavigation('ReservationHistory', {}, this.send, this.props)
      }, 1500)
    }
    else if (responseJson&&responseJson.errors){
      this.setState({ iscancelVisible: false, toasterStatus: true, toasterMessage: responseJson.errors[0].message, toasterType: 'error' });
      setTimeout(() => {
        handleNavigation('ReservationHistory', {}, this.send, this.props)
      }, 1500)
    }
  }






  async componentDidMount() {
    super.componentDidMount();
    this.getToken();

    this.getPets()
    removeStorageData("order_creation")
    const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
    this.setState({ socialLinksData: mySocialLinksData });
    const allLinksData = await getStorageData("BOandPOLinks",true)
    const reservationId = await getStorageData("reservation_id")
    this.setState({poBOLinksData: allLinksData,reservation_id:reservationId})
    this.ViewOrder()
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };


  btnHistoryExample = () => {
    this.props.navigation.navigate("ReservationHistory");
  };

  fetchServiceList = () => {
    const itemHeaders = {
      "Content-Type": configJSON.itemListApiContentType,
      "token": this.authToken
    };

    const getServiceListMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductItemListApiCallId = getServiceListMsg.messageId;

    getServiceListMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetItemList
    );

    getServiceListMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(itemHeaders)
    );
    getServiceListMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.itemListApiMethodType
    );

    runEngine.sendMessage(getServiceListMsg.id, getServiceListMsg);
    return true;
  }





  handleViewDetails = (item: Item) => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'ReservationsItem'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      data: item,
      type: "pre-booked"
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }



  dogserviceOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ anchorElDodservice: event.currentTarget });
  }

  catserviceOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ anchorElCatservice: event.currentTarget });
  }

  handleServiceCheckBoxDog = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;

    if (checked) {
      this.setState((prevState) => ({
        checkedServicesDog: [...prevState.checkedServicesDog, value],
        checkedServices: [...prevState.checkedServices, value],
      }));

    } else {
      this.setState((prevState) => ({
        checkedServicesDog: prevState.checkedServicesDog.filter((item) => item !== value),
        checkedServices: prevState.checkedServices.filter((item) => item !== value),
      }));

    }
  }

  handleServiceCheckBoxCat = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;

    if (checked) {
      this.setState((prevState) => ({
        checkedServicesCat: [...prevState.checkedServicesCat, value],
        checkedServices: [...prevState.checkedServices, value],
      }));

    } else {
      this.setState((prevState) => ({
        checkedServicesCat: prevState.checkedServicesCat.filter((item) => item !== value),
        checkedServices: prevState.checkedServices.filter((item) => item !== value),
      }));

    }
  }


  getPets = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userAllPetsListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_catalogue/pets/pet_details"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  userPetListApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors) {
      this.setState({ PetList: responseJson?.data });
    }

  }

  openPet = () => {
    this.setState({ petPopUp: true })
  }
  closePet = () => {
    this.setState({ petPopUp: false })
  }

  cancelclose = () => {
    this.setState({ iscancelVisible: false })
  }



  ViewOrder = () => {


    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken")
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.ViewOrderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/order_creations/${this.state.reservation_id}`
      // `bx_block_shopping_cart/order_creations/155`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleCancelReservation = () => {



    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken")
    };


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.CancelReservationApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/order_creations/${this.state.reservation_id}/cancel`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

  }




  openViewModal = () => {
    this.getCancellation()

    const checkInDateStr = this.state.reservationData.attributes.check_in_date;
    const checkOutDateStr = this.state.reservationData.attributes.check_out_date;

    const checkInDate: any = new Date(checkInDateStr);
    const checkOutDate: any = new Date(checkOutDateStr);

    const timeDifference = checkOutDate - checkInDate

    const daysDifference: any = timeDifference / (1000 * 60 * 60 * 24);

    this.setState({ totalcalculationdays: daysDifference }, 
      () => this.checkDays()
    )

  }


  OnShoppingCart = async () => {
    setStorageData("hotelID", this.state.reservationData.attributes.hotel_id);
    setStorageData("fromReservation", true);

    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'ShoppingCartOrders');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);


    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { orderID: this.state.reservation_id, fromReservation: true, reservationPrice: this.state.reservationData.attributes.price.split("£")[1] });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  }



  createAccount(): boolean {
    const header = {
      "Content-Type": configJSON.itemListApiContentType
    };

    const httpBody = {
      "data": {
        "attributes":
        {
          "email": "testpartner15@yopmail.com",
          "password": this.state.password
        },
        "type": "email_account"
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.secureReservationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }





  toggleFilter = () => {
    this.setState({ isSortOpen: !this.state.isSortOpen })
  }
  toggleSort = (item: string) => {
    this.setState({ order: item }, () => {
      this.sortById(this.state.itemsList, this.state.order)
    })
  }






  handleSearch = (text: string) => {
    let arrayData: Item[] = this.state.itemsList;
    this.setState({ searchText: text })
    if (text.length > 0) {
      const newData = arrayData.filter(function (item: Item) {
        return (item.attributes.service_name.toLowerCase().includes(text.toLowerCase()) ||
          moment(item.attributes.reservation_date).format("DD-MMM-YYYY").toLowerCase().includes(text.toLowerCase())
        );
      });
      this.setState({ searchedItem: newData, searchText: text }, () => {
        this.sortById(this.state.searchedItem, this.state.order)
      })
    } else {
      this.setState({ searchedItem: arrayData, searchText: text }, () => {
        this.sortById(this.state.searchedItem, this.state.order)
      })
    }
  }







  handleStartDate = (date: string) => {
    this.setState({ startDate: date })
  }
  handleEndDate = (date: string) => {
    this.setState({ endDate: date })
  }





  handleSortByDate = () => {
    let arrayData: Item[] = this.state.itemsList;
    const start = new Date(this.state.startDate);
    const endd = new Date(this.state.endDate);
    const newData = arrayData.filter((item: Item) => {
      return moment(item.attributes.reservation_date).isBetween(start, endd);
    })
    this.setState({ searchedItem: newData }, () => {
      this.sortById(this.state.searchedItem, this.state.order)
    });
  }






  sortById = (arrayData: Item[], order: string) => {
    arrayData.sort((item1, item2) => {
      if (order === "descending") {
        return Number(item1.id) < Number(item2.id) ? 1 : -1
      } else {
        return Number(item1.id) < Number(item2.id) ? -1 : 1
      }
    })
    this.setState({ searchedItem: arrayData })
  }



  getstandardCancellation = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.getStandardPolicyApiCallId = requestMessage.messageId;



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/standard_cancellations`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };






  getCancellation = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.getPolicyApiCallId = requestMessage.messageId;

    let urlParams = this.state.hotelId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/cancellation_policies?hotel_id=${urlParams}`
      // `bx_block_cancellation/cancellation_policies?hotel_id=926`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };








  getCancelApiResponce = (responseJson: any) => {
    this.setState({ ReservationLoader: false })

    if (responseJson && !responseJson?.errors) {
      if (responseJson?.data?.attributes?.cancellation_type === "standard") {
        this.setState({ serviceKey: responseJson.data.attributes.cancellation_type }, () => { this.getstandardCancellation() })
      } else {
        this.setState({ tailoredCancellation: responseJson?.data?.attributes })
      }
    }
  }

  closeToaster = (statusValues: boolean) => {
    this.setState({ toasterStatus: statusValues })
  };
  
  apiCall = async (apiData: APIPayloadType) => {
    const { method, endPoint, body, type } = apiData;
    
    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getRoomsData = async() => {
    this.roomsDataCallId =await this.apiCall({
      contentType: configJSON.itemListApiContentType,
      method: configJSON.itemListApiMethodType,
      endPoint: `${configJSON.hotelRoomEndpoint.replace("{hotelId}",this.state.hotelId)}`
    });
  };

  handleRoomApiResponse = (responseJson: {id: number, room_type: string}[]) => {
    const dogRooms: { roomId: number; pets: string[]; price: number; name: string; }[] = [];
    const catRooms: { roomId: number; pets: string[]; price: number; name: string; }[] = [];
    this.state.reservationData.attributes.room_for_dogs.forEach((roomData: {id: number,pets: string[], price: number})=>{
      const roomId: number = roomData.id;
      const pets: string[] = roomData.pets; 
      const roomPrice: number = roomData.price;
      const name: string = this.getName(roomData.id, responseJson);
      dogRooms.push({
        roomId,
        pets,
        price: roomPrice,
        name
      });
      this.setState({dogRoomData: dogRooms})
    });

    this.state.reservationData.attributes.room_for_cats.forEach((roomData: {id: number,pets: string[], price: number})=>{
      const roomId: number = roomData.id;
      const pets: string[] = roomData.pets; 
      const roomPrice: number = roomData.price;
      const name: string = this.getName(roomData.id, responseJson);
      catRooms.push({
        roomId,
        pets,
        price: roomPrice,
        name
      });
      this.setState({catRoomData: catRooms});
    });
  };
  
  getName = (roomId: number, roomsData: {id:number, room_type: string}[]) => {
    const nameData = roomsData.find((roomDetails: {id:number, room_type: string})=>roomDetails.id === roomId);
     if(nameData){
      return nameData.room_type;
     } else {
      return "";
     }
  };

  getPetCount = (dogCount: number, catCount: number) => {
    return dogCount+catCount;
  };

  getPrice =(price: number, dogCount: number, catCount: number, totalDays: number) => {
    return (price*(dogCount + catCount) * totalDays).toFixed(2);
  };

  getRoomPrice = (price: number, days: number) => {
    return (price*days).toFixed(2);
  };

  getDisaplyPrice = (hotel: HotelDetails) => {
  

    if(hotel?.hotel_information?.pet_type === "daycare_services"){
      return hotel?.day_care_services?.price_for_one_day || 0;
    }
    return hotel.hotel_information.budget_per_day;
  };

  getNumberOfWeeks = () => {
    if(this.state.reservationData.attributes.day_care_service_type === "Repeat_Weekly"){
      if(this.state.numberOfWeeks > 1){
        return `X ${this.state.numberOfWeeks} Weeks`
      } 
      else {
        return "X 1 Week"
      }
    }
    return "";
  };

  checkDays = () => {
    const dayList = [0, 0, 0, 0, 0, 0, 0];
    const startDay = new Date(this.state.reservationData.attributes.check_in_date).getDay();
    const totalDay: number = Math.floor(this.state.totalcalculationdays);
    let date = 0, getday = startDay;
    while (date <= totalDay) {
      dayList[getday] += 1;
      getday = (getday + 1) % 7;
      date += 1;
    }
    const daySet: Set<number> = new Set();
    dayList.forEach((dayFreq: number, dayIndex: number) => {
      dayIndex = dayIndex > 0 ? dayIndex - 1 : 6;
      if (this.state.reservationData.attributes.days.includes(String(dayIndex))) {
        daySet.add(dayFreq);
      }
    });
    this.setState({ 
      numberOfWeeks: Array.from(daySet)[0],
      displayPrice: this.state.reservationData.attributes.day_care_service_type === "One_Time"?this.state.reservationData.attributes.day_care_price : this.state.reservationData.attributes.weekly_price,
    });
  };

  getPetsNames = (petsList: string[]) => {
    let petNameList = "";
    petsList.forEach((name: string, catNameIndex: number) => {
        if (catNameIndex === petsList.length - 1 && catNameIndex !== 0) {
          petNameList = petNameList + " and " + name;
        } else if (catNameIndex === 0) {
          petNameList = petNameList + name;
        } else {
          petNameList = petNameList + ", " + name;
        }
      })
    return `( ${petNameList} )`;
  };

  getServiceTotalDaily = (price: number, pets: number , totalDay: number) => {
    return Number(price* pets * totalDay).toFixed(2);
  };

  getServiceOneTime = (price: number, pets: number) => {
    return (price * pets).toFixed(2);
  };

  getServiceTotalDailyAll = (price: number, totalDay: number) => {
    return (price* totalDay).toFixed(2);
  };

  getDayCarePrice = () => {
    return (Number(this.state.displayPrice) * this.state.reservationData.attributes.pets.data.length * this.state.numberOfWeeks);
  };


getTotalPriceRes = (price: string, stripeAmount: number) =>{
  return "£"+(Number(price?.split("£")[1]) + stripeAmount).toFixed(2);
};

  getWeeks = (checkInDate: { getDay: () => number; }, totalDays: number, selectedDays: string[]) => {
    const startDay = checkInDate.getDay();
    let date = 0, getday = startDay;
    const mydayList = [0, 0, 0, 0, 0, 0, 0];
    const totalDay: number = Math.floor(totalDays);
    while (date < totalDay) {
      mydayList[getday] += 1;
      getday = (getday + 1) % 7;
      date += 1;
    }
    const daySet: Set<number> = new Set();
    mydayList.forEach((dayFreq: number, dayIndex: number) => {
        dayIndex = dayIndex > 0 ? dayIndex -1 : 6;
        if (selectedDays.includes(String(dayIndex))) {
          daySet.add(dayFreq);
        }
    });
    return Array.from(daySet)[0];
  };

  getDays = () => {
    const rowdata = this.state.reservationData
    const checkInDateStr = rowdata.attributes.check_in_date;
    const checkOutDateStr = rowdata.attributes.check_out_date;
    const checkInDate: any = new Date(`${checkInDateStr}T00:00:00`);
    const checkOutDate: any = new Date(`${checkOutDateStr}T00:00:00`);
    const timeDifference = checkOutDate - checkInDate;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    if (rowdata.attributes.hotel_information.pet_type === "daycare_services") {
      if (rowdata.attributes.day_care_service_type === "One_Time") {
        return rowdata.attributes.days.length;
      } else {
        return rowdata.attributes.days.length * this.getWeeks(checkInDate, daysDifference, rowdata.attributes.days);
      }
    }
    this.setState({})
    return daysDifference;
  };

}



// Customizable Area End
