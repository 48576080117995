import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");

// Customizable Area Start
import { checkApiErrorResponce, handleNavigation } from "../../../components/src/CommonFunctions";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Color } from '@material-ui/lab/Alert';
import { DateObject } from "react-multi-date-picker";
import { ChangeEvent } from "react";

interface AdditionalServicesforPets {
  isAllPets: string,
  pets: string[],
  service: { id: number, name: string, price: number }
  service_provided_type: string
}
export interface RoomData 
  {
      id: number,
      price: number,
      max_capacity: number,
      additional_charges: number,
      account_id: number,
      hotel_id: number,
      room_type: string,
      pet_count: number,
      add_pet: number,
      created_at: string,
      updated_at: string,
      description: string,
      room_for: string,
      num_rooms: number,
      check_in_start: string,
      check_in_end: string,
      check_out_start: string,
      check_out_end: string,
      min_stay: number,
      max_stay: number,
      advance_notice: string,
      notice_before: string,
      pet_room_prices: number[],
      num_dogs: number,
      num_cats: number
  }
  interface LinkObject {
    url: string;
    title: string;
  }
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

interface ServiceResponse {
  isAllPets: string,
  pets: string[],
  service_provided_type: string,
  service: {
    id: number,
    name: string,
    price: number
  }
}

interface APIPayloadType {
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
  contentType?: string;
  method: string;
}

export interface DayCareServiceCosts {
  day_care_services: {
    data: Array<DayCareService>
  }
}

interface OrderCreation {
  data: {
    id: string;
    type: string;
    attributes: {
      hotel_information: {
        pet_type: string;
        id: number;
      };
      status: string;
      hotel_id: string;
      account_id: number;
      hotel_additional_service_id: number | null;
      additional_service_ids: number | null;
      check_in_date: string;
      check_out_date: string;
      price: string;
      stripe_charges: number;
      total_charges:string
      number_of_dogs: number;
      number_of_cats: number;
      reject_message: string | null;
      room_type: string | null;
      room_type_for_dog: string;
      room_type_for_cat: string;
      additional_services_for_dog: ServiceResponse[];
      own_services_for_dog: ServiceResponse[];
      additional_services_for_cat: {id: number, price: number, pets: string[]}[];
      dog_room_id: string | null;
      cat_room_id: string | null;
      pet_count_for_cat: number;
      pet_count_for_dog: number;
      pre_payment_amount: string;
      room_for_dogs: {id: number, price: number, pets: string[], type: string}[];
      room_for_cats: {id: number, price: number, pets: string[], type: string}[];
      select_pets: object[];
      day_care_service_type: string;
      days: string[];
      day_care_price: string;
      weekly_price: string;
      additional_notes: string;
      pet_owner_name: string;
      pets: {
        data: {id: string, attributes: {name: string}}[];
      };
      day_care_service_selected_pets: {
        data: object[];
      };
      date: string;
      for_cat: {
        additional_services_for_cat: ServiceData[];
      }
    };
  };
}

interface ServiceData {
  additional_service_id: number;
  hotel_id: number;
  id: number;
  name: string;
  price: number;
  service_provided_type: string;
}
export interface DayCareService {
  attributes: {
    spaces: string | null;
    weekly_price_for_two_days: string | null;
    weekly_price_for_three_days: string | null;
    weekly_price_for_four_days: string | null;
    price_for_one_day: string | null;
    price_for_five_days: string | null;
    price_for_two_days: string | null;
    weekly_price_for_five_days: string | null;
    weekly_price_for_seveen_days: string | null;
    weekly_price_for_six_days: string | null;
    price_for_six_days: string | null;
    price_for_seveen_days: string | null;
    price_for_three_days: string | null;
    price_for_four_days: string | null;
    weekly_price_for_one_day: string | null;
  },
  id: number;
  type: string;
}
export interface SelectedHotel {
  roomId: number;
  name: string;
  bookings: number;
  price: number;
}

export interface RoomAdded {
  id: number;
  price: number;
  pets: (string | number)[];
}

export interface PetDetailsData{
  id: string,
  attributes: {
    name: string,
    pet_photo: string,
    pet_type: string
  }
}

// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  openToastHandler?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  order_id: any;
  catalogue_id: number;
  quantity: number;
  taxable: boolean;
  taxable_value: number;
  token: string;
  orderList: any;
  orderItems: any;
  isVisible: boolean;
  isRefreshing: boolean;

  selectedFor: string;
  dailogOpen: boolean;
  petDetailsPopUpDog: boolean[];
  petDetailsPopUpCat: boolean[];
  additionalServe: string;
  additionalServicesPet: any[]
  checkedServices: any[];
  petType: string;
  servicesDog: any
  servicesCat: any
  selectedType: string
  checkedServicesDog: any[];
  checkedServicesCat: any[];
  openadditionalServices: boolean
  catAdditionalServices: boolean
  popupType: string
  checkInDate: any,
  checkOutDate: any,
  checkInDate1: Date,
  checkOutDate1: Date,
  selectRoomType: string,
  dogOpenRoomStatus: boolean,
  catOpenRoomStatus: boolean,
  // petDetails
  selectRoom: string
  selectRoomForDog: string
  selectRoomForCat: string,
  dogHoteldata: any,
  hotelTypes: any[],
  orderConfirmatationStatus: boolean,
  userPetList: any[],
  toasterMessage: string;
  toasterType: Color;
  toasterStatus: boolean;
  editInfoStatus: any;
  hotel_id: string,
  hotelDetails: any,
  hotelPetType: string,
  dogmap: PetDetailsData[],
  catmap: PetDetailsData[],
  serviceDogList: PetDetailsData[],
  serviceCatList: PetDetailsData[],
  checkedServicesDogPrice: any,
  checkedServicesCatPrice: any,
  selectRoomForDogPrice: number,
  selectRoomForCatPrice: number,
  totalDay: any,
  priceTotal: any,
  addRoomDog: any,
  addRoomCat: any,
  checkeddog: boolean,
  checkedcat: boolean,
  catRooms: string,
  dogRooms: string,
  selectRange: any[],
  roomsCat: any[];
  roomsDog: any[];
  selectRoomPet: string;
  roomDetailId: null | string;
  petId: any;
  dogId: string[][];
  catId: string[][];
  dogName: string[][];
  catName: string[][]; 
  EditPetId: any;
  roomTypeForDog: any[];
  checkedServicesSelected: any[],
  fromReservation: boolean;
  ShoppingcartLoader: boolean;
  roomTypeForCat: any[],
  additional_service_id: number | null;
  hotel_addtionl_service_id: number | null;
  petError: boolean;
  tailoredCancellation: any;
  serviceKey: string;
  standardCancellation: any;
  catRoomId: string;
  dogRoomId: string;
  dogRoomPetCount: number;
  catRoomPetCount: number;
  checkOutValidation:boolean;
  tokenStatus:boolean;
  orderPrice:string;
  connectAccoutnID:string
  paymentStatus:boolean;
  cancellationDetails:any
  updateOrderError:string;
  updateOrderErrorMsg:boolean;
  isDailyService: boolean;
  openModal: boolean;
  oneTimePrice: (string | null)[];
  weeklyPrice: (string | null)[];
  hotelType: string;
  buttonText: string;
  selectedDays: number[],
  selectedDays1: number[],
  selectedPriceType: string,
  selectedPriceType1: string,
  selectedPetIds: number[],
  additionalDetails: string,
  additionalDetails1: string,
  displayPrice: string,
  displayPrice1: string,
  isDayCareService: boolean;
  dogSelectedHotel: Array<SelectedHotel>;
  catSelectedHotel: Array<SelectedHotel>;
  flashLogin: boolean;
  transactionFee:string;
  reservationPrice: number;
  socialLinksData: {icons: string, navigationUrl: string}[];
  poBOLinksData: LinkObject[];
  orderDetails: OrderCreation;
  roomDetails: RoomData[];
  hotelId: string;
  priceList: DayCareService;
  petName: string[];
  selectFeildText: string;
  selectFeildText1: string;
  numberOfWeeks: number;
  modalError: string;
  isModalError: boolean;
  checkoutDayCare: boolean;
  daycareError: boolean;
  ownServices: {
    hotel_additional_service_id: number,
    isDailyService: boolean,
    name: string,
    perDayPrice: number,
    price: number,
    isAllPets: boolean,
    additional_service_id: number,
    pets: number
  }[];
  policyQuestions: {
    additional_condition: string,
    additional_question_one: string,
    additional_question_three: string,
    additional_question_two: string
  };
  availabilityErrorCheck: boolean,
  availabilityErrorMessage: string,
  editPrice:string;
  termsAndConditionsUrl: string;
  petServiceList: boolean[];
  ownPetServiceList: boolean[];
  servicePetIds: {
    serviceId: number,
    charge_type: string,
    pets: string[],
    service_provided_type: string,
    isAllPets: string
  }[];
  ownServicePetIds: {
    serviceId: number,
    charge_type: string,
    pets: string[],
    service_provided_type: string,
    isAllPets: string
  }[];
  additionalQuestion1: string;
  additionalQuestion2: string;
  additionalQuestion3: string;
  dogSelectionError: boolean;
  petSelectionErr: boolean;
  additionalServicePrice: number;
  dayCareDays: number;
  dayCareDays1: number;
  dateErrorText: string;
  dayCareDogCount: number;
  checkInDateError: string;
  checkOutDateError: string;
  openSnck: boolean;
  openPetPopup: boolean;
  dayCareFormSubmited: boolean;
  availableSpace: {[key: string]: number}[];
  stripeCharges:number
  roomError: string,
  isRoomError: boolean,
  editErrorMsg: string;
  editError: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShoppingCartOrdersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrdersApiCallId: any;
  showOrderApiCallId: any;
  createOrderItemApiCallId: any;
  deleteOrderItemApiCallId: any;
  userAllPetsListApiCallId: string = "";
  apiCallIdSeeHotelDetails: string = "";
  viewroomID: string = "";
  removepetcallID: string = "";
  orderupdateId: string = "";
  getStandardPolicyApiCallId: string = "";
  getPolicyApiCallId: string = "";
  userDayCareApiCallId: string = "";
  orderDetailCallId: string = "";
  getStandardCancellationCallId: string="";
  apiCallIdgetTermsAndConditionsUrl:string="";
  getAvailableSpaceCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      order_id: "",
      catalogue_id: 0,
      quantity: 0,
      taxable: false,
      taxable_value: 0,
      token: "",
      orderList: [],
      orderItems: [],
      isVisible: false,
      isRefreshing: false,
      selectedFor: "",
      dailogOpen: false,
      petDetailsPopUpDog: [],
      petDetailsPopUpCat: [],
      additionalServe: "",
      additionalServicesPet: [],
      checkedServices: [],
      petType: "",
      ownServices: [],
      servicesDog: [
        { name: "Grooming for Dogs", price: "�9" },
        { name: "Spa Services", price: "�9" },
        { name: "Webcam Access", price: "�9" },
        { name: "Pickup and Drop-off Services", price: "�9" },
        { name: "Training Programs", price: "�9" },
        { name: "Pet Spa Days", price: "�9" },
        { name: "Birthday Parties and Celebrations", price: "�9" },
      ], servicesCat: [
        { name: "Grooming for Cats", price: "�9" },
        { name: "Spa Services", price: "�9" },
        { name: "Webcam Access", price: "�9" },
        { name: "Pickup and Drop-off Services", price: "�9" },
        { name: "Training Programs", price: "�9" },
        { name: "Pet Spa Days", price: "�9" },
        { name: "Birthday Parties and Celebrations", price: "�9" },
      ],
      selectedType: "",
      checkedServicesDog: [],
      checkedServicesCat: [],
      openadditionalServices: false,
      catAdditionalServices: false,
      popupType: "",
      checkInDate: new DateObject(),
      checkOutDate: new DateObject(),
      checkInDate1: new Date(),
      checkOutDate1: new Date(),
      dogOpenRoomStatus: false,
      catOpenRoomStatus: false,
      selectRoomType: "",
      selectRoom: "",
      selectRoomForDog: "",
      selectRoomForCat: "",
      dogHoteldata: [{ description: "The room price of one dog per day", price: "�20" },
      { description: "The room price of one dog per day", price: "�10" },],
      hotelTypes: [],
      orderConfirmatationStatus: false,
      userPetList: [],
      toasterMessage: "",
      toasterType: "error",
      toasterStatus: false,
      editInfoStatus: null,
      hotel_id: "",
      hotelDetails: "",
      hotelPetType: "",
      dogmap: [],
      catmap: [],

      checkedServicesDogPrice: [],
      checkedServicesCatPrice: [],
      selectRoomForDogPrice: 0,
      selectRoomForCatPrice: 0,
      totalDay: "",
      priceTotal: "",
      addRoomDog: "",
      addRoomCat: "",
      checkeddog: false,
      checkedcat: false,
      catRooms: "",
      dogRooms: "",
      selectRange: [],
      roomsCat: [],
      roomsDog: [],
      selectRoomPet: "",
      roomDetailId: null,
      petId: [],
      dogId: [],
      catId: [],
      dogName: [],
      catName: [], 
      EditPetId: "",
      roomTypeForDog: [],
      checkedServicesSelected: [],
      fromReservation: false,
      ShoppingcartLoader: true,
      roomTypeForCat: [],
      additional_service_id: null,
      hotel_addtionl_service_id: null,
      petError: false,
      tailoredCancellation: "",
      serviceKey: "",
      standardCancellation: [],
      catRoomId: "",
      dogRoomId: "",
      dogRoomPetCount: 0,
      catRoomPetCount: 0,
      checkOutValidation:  false,
      tokenStatus:false,
  orderPrice:"",
  connectAccoutnID:"",
  paymentStatus:false,
  cancellationDetails:{},
      updateOrderError:"",
      updateOrderErrorMsg:false,
      isDailyService: true,
      openModal: false,
      oneTimePrice: [
        "� 000",
        "� 000",
        "� 000",
        "� 000",
        "� 000",
        "� 000",
        "� 000"
      ],
      weeklyPrice: [
        "� 000",
        "� 000",
        "� 000",
        "� 000",
        "� 000",
        "� 000",
        "� 000"
      ],
      hotelType: "",
      buttonText: "",
      selectedDays: [],
      selectedDays1: [],
      selectedPriceType: "",
      selectedPriceType1: "",
      selectedPetIds: [],
      additionalDetails: "",
      additionalDetails1: "",
      displayPrice: "",
      displayPrice1: "",
      isDayCareService: false,
      dogSelectedHotel: [],
      catSelectedHotel: [],
      flashLogin: false,
      transactionFee:"",
      reservationPrice: 0,
      socialLinksData: [],
      poBOLinksData: [],
      orderDetails: {
        data: {
          id: "",
          type: "",
          attributes: {
            for_cat: {
              additional_services_for_cat: []
            },
            price: "",
            number_of_dogs: 0,
            number_of_cats: 0,
            select_pets: [],
            day_care_service_type: "",
            days: [],
            day_care_price: "",
            weekly_price: "",
            additional_notes: "",
            pet_owner_name: "",
            reject_message: null,
            room_type: null,
            room_type_for_dog: "",
            stripe_charges: 0,
            total_charges:"",
            status: "",
            dog_room_id: null,
            cat_room_id: null,
            pet_count_for_cat: 0,
            pet_count_for_dog: 0,
            pre_payment_amount: "",
            room_for_dogs: [],
            hotel_id: "",
            account_id: 0,
            hotel_additional_service_id: null,
            additional_service_ids: null,
            check_in_date: "",
            check_out_date: "",
            room_type_for_cat: "",
            additional_services_for_dog: [],
            own_services_for_dog: [],
            additional_services_for_cat: [],
            room_for_cats: [],
            pets: {
              data: []
            },
            day_care_service_selected_pets: {
              data: []
            },
            date: "",
            hotel_information: {
              pet_type: "",
              id: 0
            }
          }
        }
      },
      roomDetails: [],
      hotelId: "",
      priceList: {
        attributes: {
          spaces: null,
          weekly_price_for_two_days: null,
          weekly_price_for_three_days: null,
          weekly_price_for_four_days: null,
          price_for_one_day: null,
          price_for_five_days: null,
          price_for_two_days: null,
          weekly_price_for_five_days: null,
          weekly_price_for_seveen_days: null,
          weekly_price_for_six_days: null,
          price_for_six_days: null,
          price_for_seveen_days: null,
          price_for_three_days: null,
          price_for_four_days: null,
          weekly_price_for_one_day: null
        },
        id: 0,
        type: "",
      },
      petName: [],
      selectFeildText: "",
      selectFeildText1: "",
      numberOfWeeks: 1,
      modalError: "",
      isModalError: false,
      checkoutDayCare: false,
      policyQuestions: {
        additional_condition: "",
        additional_question_one: "",
        additional_question_three: "",
        additional_question_two: ""
      },
      petServiceList: [],
      ownPetServiceList: [],
      availabilityErrorCheck: false,
      availabilityErrorMessage: "",
      editPrice:"",
      termsAndConditionsUrl:"",
      servicePetIds: [],
      ownServicePetIds: [],
      additionalQuestion1: "",
      additionalQuestion2: "",
      additionalQuestion3: "",
      serviceDogList: [],
      serviceCatList: [],
      dogSelectionError: false,
      petSelectionErr: false,
      additionalServicePrice: 0,
      dayCareDays: 0,
      dayCareDays1: 0,
      dateErrorText: "",
      dayCareDogCount: 0,
      checkInDateError: "",
      checkOutDateError: "",
      openSnck: false,
      openPetPopup: false,
      dayCareFormSubmited: false,
      availableSpace: [],
      stripeCharges:0,
      roomError: "",
      isRoomError: false,
      editErrorMsg: "",
      editError: false,
      daycareError: false
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    const isReservation = await getStorageData("fromReservation", true);
    if(isReservation){
      this.setState({ fromReservation: true });
      this.getOrderDetails();
      this.showOrder()
    }
    const orderId = await getStorageData("orderId", true);
    if(orderId){
      this.setState({order_id: orderId});
    }
    this.getStandardCancellation();
    await removeStorageData('pageComingFrom')
    this.getlocalresponse()
    this.seeHotelDetails()
    await this.getUserPets()
    const orderData = (await getStorageData("order_creation", true));
    const priceList = (await getStorageData("price_list", true));
    const dayCareDays = orderData?.days?.length * orderData?.numberOfWeeks
    if(orderData && orderData.hotelType === "daycare_services"){
      this.getDayCareServiceCost();
      this.setButtonText(orderData.days, orderData.pricetype);
      this.setState({
        selectedDays: orderData.days, 
        selectedPriceType: orderData.pricetype,
        petId: orderData.petIds,
        displayPrice: orderData.dayCareServicePrice, 
        additionalDetails: orderData.additionalDetails,
        isDayCareService: true,
        priceList,
        petName: orderData.petName,
        selectFeildText: orderData.selectFeildText,
        dayCareDays
      })
    }
    const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
    this.setState({socialLinksData: mySocialLinksData });
    const allLinksData = await getStorageData("BOandPOLinks",true)
    this.setState({poBOLinksData: allLinksData})
    const petIds = await getStorageData("petId", true);
    const catIds = await getStorageData("catId", true);
    const catName = await getStorageData("catName", true);
    const dogIds = await getStorageData("dogId", true);
    const dogName = await getStorageData("dogName", true);
    
    if (petIds && petIds.length > 0) {
      this.setState({
        petId: petIds,
        catId: catIds,
        catName,
        dogName,
        dogId: dogIds
      })
    }

     
     
    // this.showOrder()


    // Customizable Area End
  }

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {

      const sessionData = message.getData(getName(MessageEnum.SessionResponseData));

      if (sessionData) {

        this.setState({ order_id: sessionData?.orderID, fromReservation: sessionData?.fromReservation, reservationPrice: sessionData?.reservationPrice }, () => {
          if (this.state.fromReservation === true) {
            this.setState({ShoppingcartLoader: true})
            this.getOrderDetails();
            this.showOrder()
          } else {
            this.getlocalresponse()
          }

        })
      }
      return;

    } 




    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getOrdersApiCallId) {
      this.getOrderApiResponce(responseJson)
    } else if (apiRequestCallId === this.showOrderApiCallId) {


      this.showOrderApiResponce(responseJson)
    } else if (apiRequestCallId === this.createOrderItemApiCallId) {
      this.createOrderApiResponce(responseJson)
    } else if (apiRequestCallId === this.userAllPetsListApiCallId) {
      this.userPetListApiResponce(responseJson)
    } else if(apiRequestCallId === this.userDayCareApiCallId){
      this.dayCareResponce(responseJson);
    } else if(apiRequestCallId === this.orderDetailCallId){
      this.orderDetailResponse(responseJson);
    } else if(apiRequestCallId === this.getStandardCancellationCallId){
      this.getAdditionalQuestions(responseJson);
    }

    this.handleApiRequest(apiRequestCallId, responseJson);


    // Customizable Area End
  };

  // Customizable Area Start

  getWeeks = (days: string[]) => {
    const timeDifference = this.state.checkOutDate.getTime() - this.state.checkInDate.getTime();
    const totalDays = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
    const dayList = [0, 0, 0, 0, 0, 0, 0];
    const totalDay: number = Math.floor(totalDays);
    const startDay = this.state.checkInDate.getDay();
    let date = 0, getday = startDay;
    while (date < totalDay) {
      dayList[getday] += 1;
      getday = (getday + 1) % 7;
      date += 1;
    }
    const myDaySet: Set<number> = new Set();
    dayList.forEach((dayFreq: number, dayIndex: number) => {
      dayIndex = dayIndex > 0 ? dayIndex - 1 : 6;
      if (days.includes(String(dayIndex))) {
        myDaySet.add(dayFreq);
      }
    });
    this.setState({numberOfWeeks: Array.from(myDaySet)[0]});
    return Array.from(myDaySet)[0];
  }

  orderDetailResponse = (response: OrderCreation) => {
    
    this.setState({
      orderDetails: response,
      hotelId: response.data.attributes.hotel_id,
      checkInDate: new Date(response.data.attributes.check_in_date),
      checkOutDate: new Date(response.data.attributes.check_out_date),
      checkInDate1: new Date(response.data.attributes.check_in_date),
      checkOutDate1: new Date(response.data.attributes.check_out_date),
    },() => {
      this.getRoomData();
      if(response.data.attributes.hotel_information.pet_type === "daycare_services"){
        if(response.data.attributes.day_care_service_type === "One_Time"){
          this.setState({
            dayCareDays: response.data.attributes.days.length,
            dayCareDays1: response.data.attributes.days.length
          },()=>{
          });
        } else {
          this.setState({
            dayCareDays: response.data.attributes.days.length * this.getWeeks(response.data.attributes.days),
            dayCareDays1: response.data.attributes.days.length * this.getWeeks(response.data.attributes.days)
          });
        }
      }
    });
  };

  handleApiRequest = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.apiCallIdSeeHotelDetails) {
      this.getHotelApiResponse(responseJson)
    }


    if (apiRequestCallId === this.viewroomID) {
      this.handleAddPetApiResponse(responseJson);
    }

    if (apiRequestCallId === this.removepetcallID) {
      this.getUserPets()
    }

    if (apiRequestCallId === this.orderupdateId) {

      this.getOrderUpdateResponse(responseJson)
      removeStorageData("order_creation");

    }

    if (apiRequestCallId === this.getPolicyApiCallId) {

      this.getCancellationApiResponce(responseJson)

    }

    if (apiRequestCallId === this.getStandardPolicyApiCallId) {

      this.setState({ standardCancellation: responseJson.data })

    }
    if(apiRequestCallId ===this.apiCallIdgetTermsAndConditionsUrl){
      this.setState({ termsAndConditionsUrl: responseJson?.data?.stripe_terms_and_conditions_link })

    }

    if(apiRequestCallId ===this.getAvailableSpaceCallId){
      this.checkAvailableSpaces(responseJson);
    }
  }

  checkAvailableSpaces=(responseJson: { data: { attributes: { day_care_services: { data: { attributes: { available_spaces: {["date"]: number}[]; }; }[]; }; }; }; })=>{
    const spaces = responseJson.data?.attributes?.day_care_services?.data[0]?.attributes?.available_spaces;
      this.setState({
        availableSpace: spaces
      });
  };

  getlocalresponse = async () => {


    const orderData = (await getStorageData("order_creation", true))

    if(orderData){
    const checkedServicesDataDog = orderData.for_dog.additional_services
    const checkedServicesDataCat = orderData.for_cat.additional_services
    const ownServicesDataCat = orderData.for_cat.ownServices;
    const checkIn = new Date(orderData.check_out_date);
    const checkOut = new Date(orderData.check_in_date);
    let dogIds: Array<Array<string>> = [];
    orderData.for_dog.rooms.forEach((petRoom: object) => dogIds.push([]));
    const catIds: Array<Array<string>> = [];
    orderData.for_cat.rooms.forEach((petRoom: object) => catIds.push([]));
    let petDetailsPopUpDogList: Array<boolean> = [];
    orderData.for_dog.rooms.forEach((petRoom: object) => petDetailsPopUpDogList.push(false));
    let petDetailsPopUpCatList: Array<boolean> = [];
    orderData.for_cat.rooms.forEach((petRoom: object) => petDetailsPopUpCatList.push(false));
    let dogNames: Array<Array<string>> = [];
    orderData.for_dog.rooms.forEach((petRoom: object) => dogNames.push([]));
    const catNames: Array<Array<string>> = [];
    orderData.for_cat.rooms.forEach((petRoom: object) => catNames.push([]));
    const selectedServices = checkedServicesDataCat.map((service: { additional_service_id: number; isDailyService: boolean; isAllPets: boolean; })=>{
      return {
        serviceId: service.additional_service_id,
        service_provided_type: service.isDailyService? "Daily": "OneTime",
        isAllPets: service.isAllPets? "All Pets": "Per Pet",
        pets: []
      }
    });
    const ownselectedServices = ownServicesDataCat.map((service: { additional_service_id: number; isDailyService: boolean; isAllPets: boolean; })=>{
      return {
        serviceId: service.additional_service_id,
        service_provided_type: service.isDailyService? "Daily": "OneTime",
        isAllPets: service.isAllPets? "All Pets": "Per Pet",
        pets: []
      }
    });

    const ownServices = ownServicesDataCat.map((service: { additional_service_id: number; isDailyService: boolean; isAllPets: boolean; })=>{
      return {...service, pets: 1};
    });

    this.setState({
      ownServices: ownServices,
      hotel_id: orderData.hotel_id,
      checkOutDate: checkIn,
      hotelType: orderData.hotelType,
      checkInDate: checkOut,
      selectRoomForDog: orderData.for_dog.type,
      selectRoomForCat: orderData.for_cat.type,
      selectRoomForDogPrice: orderData.for_dog.price,
      selectRoomForCatPrice: orderData.for_cat.price,
      dogRoomPetCount:orderData.for_dog.number,
      catRoomPetCount:orderData.for_cat.number,
      addRoomCat: orderData.number_of_cats || 0,
      addRoomDog: orderData.number_of_dogs || 0,
      catRoomId: orderData.catRoomId,
      dogRoomId: orderData.dogRoomId,
      dogSelectedHotel: orderData.for_dog.rooms,
      catSelectedHotel: orderData.for_cat.rooms,
      dogId: dogIds,
      catId: catIds,
      dogName: dogNames,
      catName: catNames,
      petDetailsPopUpDog: petDetailsPopUpDogList,
      petDetailsPopUpCat: petDetailsPopUpCatList,
      ShoppingcartLoader: false,
      servicePetIds: selectedServices,
      ownServicePetIds: ownselectedServices
    }, () => { 
      this.seeHotelDetails();
    })

    checkedServicesDataDog.length > 0 && this.setState((prevState) => ({
      checkedServicesSelected: checkedServicesDataDog.map((service: { name: any; }) => service.name),
      checkedServicesDog: checkedServicesDataDog.map((service: { name: any; }) => service.name),
      checkedServicesDogPrice: checkedServicesDataDog.map((service: { name: string; price: number; additional_service_id: number; hotel_additional_service_id: number; isDailyService: boolean, perDayPrice: number}) => ({
        price: service.price,
        name: `${service.name}`,
        hotel_additional_service_id: service.hotel_additional_service_id,
        additional_service_id: service.additional_service_id,
        isDailyService: service.isDailyService,
        perDayPrice: service.perDayPrice
      })),
    }));

    checkedServicesDataCat?.length > 0 && this.setState((prevState) => ({
      checkedServicesSelected: checkedServicesDataCat.map((service: { name: any; }) => service.name),
      checkedServicesCat: checkedServicesDataCat.map((service: { name: any; }) => service.name),
      checkedServicesCatPrice: checkedServicesDataCat.map((service: { name: string; price: number; additional_service_id: number; hotel_additional_service_id: number; isDailyService: boolean, perDayPrice: number, isAllPets: boolean}) => ({
        price: service.price,
        additional_service_id: service.additional_service_id,
        hotel_additional_service_id: service.hotel_additional_service_id,
        name: `${service.name}`,
        isDailyService: service.isDailyService,
        perDayPrice: service.perDayPrice,
        isAllPets: service.isAllPets,
        pets: 1
      })),
    }));
  }
  }




  getHotelApiResponse = (responseJson: any) => {
    if (responseJson && !responseJson?.errors) {
      const services = []
      const service1 = responseJson.data?.attributes?.hotel_additional_services
      const service2 = responseJson.data?.attributes?.['Create your own services']
      services.push(...service1, ...service2);
      this.setState({
        hotelDetails: responseJson?.data?.attributes,
        servicesDog: services,
        servicesCat: services,
        hotelTypes: responseJson?.data?.attributes?.['Types of rooms'],
        hotel_addtionl_service_id: responseJson.data?.attributes?.hotel_additional_services[0]?.id,
        additional_service_id: responseJson.data?.attributes?.hotel_additional_services[0]?.additional_service_id,
      })


    }
    this.getRoomData()
  }



  getOrderUpdateResponse = (responseJson: any) => {
    if (responseJson && !responseJson?.errors) {
      let order=responseJson?.data
      this.state.fromReservation === true ? this.props.navigation.navigate("ReservationHistory") :
        this.setState({ orderConfirmatationStatus: true,order_id:responseJson?.data?.id,
          orderPrice:"£"+this.calculateOrderPrice(responseJson?.data?.attributes.pre_payment_amount, responseJson?.data?.attributes.stripe_charges),
          connectAccoutnID:responseJson?.stripe_connected_account_id,
          transactionFee:order.attributes.total_charges,
          stripeCharges:order.attributes.stripe_charges
        }, () => {
          this.getTermsAndConditionsUrl()
        })
      this.showOrder()
    } else if(responseJson && responseJson.errors[0] && responseJson.errors[0].message){
      this.setState({
        availabilityErrorCheck: true,
        availabilityErrorMessage: responseJson.errors[0].message
      }, ()=>{
        this.setState({
          availabilityErrorCheck: false
        })
      });
    }else{
      this.setState({updateOrderErrorMsg:true,updateOrderError:responseJson?.errors[0]},()=>{
        this.setState({updateOrderErrorMsg:false})
      })
    }
  }

  calculateOrderPrice = (pre_payment_amount: string, stripeCharges: number) => {
    const prePaymentAmount = parseFloat(pre_payment_amount.replace("£", ""));
    const totalAmount = prePaymentAmount + stripeCharges;
    return totalAmount.toFixed(2);
  }

  showOrderApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors && responseJson.data) {

      const checkedServicesDataDog = responseJson?.data?.attributes?.for_dog?.additional_services_for_dog || []
      const checkedServicesDataCat = responseJson?.data?.attributes?.for_cat?.additional_services || []
      const ownServicesData = responseJson?.data?.attributes?.own_services_for_dog || []
      const additionalServicesData = responseJson?.data?.attributes?.additional_services_for_dog || []

      const  petIds = responseJson?.data?.attributes?.pets?.data.map((pet: { id: any; }) => pet.id);


      checkedServicesDataDog.length > 0 && this.setState((prevState) => ({
        checkedServicesSelected: checkedServicesDataDog.map((service: { name: any; }) => service.name),
        checkedServicesDog: checkedServicesDataDog.map((service: { name: any; }) => service.name),
        checkedServicesDogPrice: checkedServicesDataDog.map((service: { name: any; price: any; additional_service_id: any; hotel_additional_service_id: any; }) => ({
          name: `${service.name}`,
          price: service.price,
          additional_service_id: service.additional_service_id,

        })),
      }));

      additionalServicesData.length > 0 && this.setState((prevState) => ({
        checkedServicesCatPrice: additionalServicesData.map((service: ServiceResponse) => ({
          hotel_additional_service_id: service.service.id,
          name: `${service.service.name}`,
          price: Number(service.service.price),
          additional_service_id: service.service.id,
          isAllPets: service.isAllPets === "All Pets",
          perDayPrice: Number(service.service.price),
          isDailyService: service.service_provided_type === "Daily",
          pets: service.isAllPets === "All Pets"?responseJson?.data?.attributes?.number_of_dogs + responseJson?.data?.attributes?.number_of_cats: service.pets.length
        }))
      }));

      ownServicesData.length > 0 && this.setState(() => ({
        ownServices: ownServicesData.map((service: ServiceResponse) => ({
          price: Number(service.service.price),
          additional_service_id: service.service.id,
          hotel_additional_service_id: service.service.id,
          name: `${service.service.name}`,
          isDailyService: service.service_provided_type === "Daily",
          perDayPrice: Number(service.service.price),
          isAllPets: service.isAllPets === "All Pets",
          pets: service.isAllPets === "All Pets"?responseJson?.data?.attributes?.number_of_dogs + responseJson?.data?.attributes?.number_of_cats: service.pets.length
        }))
      }));

      const selectedServices = additionalServicesData.map((service: AdditionalServicesforPets)=>{
        return {
          serviceId: service.service.id,
          service_provided_type: service.service_provided_type,
          isAllPets: service.isAllPets,
          pets: service.pets
        }
      });
      const ownselectedServices = ownServicesData.map((service: AdditionalServicesforPets)=>{
        return {
          serviceId: service.service.id,
          service_provided_type: service.service_provided_type,
          isAllPets: service.isAllPets,
          pets: service.pets
        }
      });
  
  
      this.setState(prevState => ({
        hotelPetType: responseJson?.data?.attributes?.hotel_information?.pet_type,
        orderItems: responseJson?.data?.attributes,
        petId: petIds,
        servicePetIds: selectedServices,
        ownServicePetIds: ownselectedServices,
        checkInDate: new Date(responseJson?.data?.attributes?.check_in_date),
        checkOutDate: new Date(responseJson?.data?.attributes?.check_out_date),
        hotel_id: responseJson?.data?.attributes?.hotel_id,
        selectRoomForDog: responseJson?.data?.attributes?.for_dog?.room_type_for_dog[0]?.room_type,
        selectRoomForCat: responseJson?.data?.attributes?.for_cat?.room_type_for_cat[0]?.room_type,
        selectRoomForDogPrice: responseJson?.data?.attributes?.for_dog?.room_type_for_dog[0]?.price,
        selectRoomForCatPrice: responseJson?.data?.attributes?.for_cat?.room_type_for_cat[0]?.price,
        dogRoomPetCount: responseJson?.data?.attributes?.for_dog?.room_type_for_dog[0]?.pet_count,
        catRoomPetCount: responseJson?.data?.attributes?.for_cat?.room_type_for_cat[0]?.pet_count,
        addRoomDog: responseJson?.data?.attributes?.number_of_dogs || 0,
        addRoomCat: responseJson?.data?.attributes?.number_of_cats || 0,
        additionalQuestion1: responseJson.data.attributes?.additional_question_answer_one,
        additionalQuestion2: responseJson.data.attributes?.additional_question_answer_two,
        additionalQuestion3: responseJson.data.attributes?.additional_question_answer_three,
      }), () => {
        let petServiceList = this.state.dogmap.filter(details => this.state.petId.includes(details.id))
        let cattServiceList = this.state.catmap.filter(details => this.state.petId.includes(details.id));
        this.setState({serviceDogList: petServiceList, serviceCatList: cattServiceList})
        this.seeHotelDetails()
    });


    } else {
      this.setState({ orderItems: [] })
    }

  }
  getOrderApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors) {
      this.setState({ orderList: responseJson?.data });
    } else {
      this.setState({ orderList: [] })
    }
  }
  createOrderApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors) {
      this.props.navigation.navigate("ShoppingCartOrders");
    }
  }
  userPetListApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors) {
      const dogmap = responseJson.data.filter((petData: { attributes: { pet_type: string; }; }) => petData.attributes.pet_type === 'dog')
      const catmap = responseJson.data.filter((petData: { attributes: { pet_type: string; }; }) => petData.attributes.pet_type === 'cat')
      this.setState({ userPetList: responseJson?.data, dogmap, catmap });
      if(this.state.hotelType === "daycare_services"){
        const petList = dogmap.filter((petData: {id: string}) => this.state.petId.includes(petData.id) );
        this.setState({
          serviceDogList: petList
        });
      }
    }
    else if (responseJson?.errors) {

      this.setState({ userPetList: [], dogmap: [], catmap: [] });

    }
  }
  isNumberNull(num: number) {
    return num === null || Number.isNaN(num);
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);

  };

  navigateToAddShoppingCartOrderItem = () => {
    this.props.navigation.navigate("AddShoppingCartOrderItem")
  };

  navigateToShoppingCartOrders = () => {
    this.props.navigation.navigate("ShoppingCartOrders")
  };
  navigateAddPets = async () => {
    removeStorageData("pagecomefrom")
    setStorageData("pagecomefrom", "ShoppingCartOrders")
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AddPetDetails'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { value: "Edit", from: "ShoppingCart", selectedPetId: "" })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);

  }

  getOrders = () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: getStorageData('authToken'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const params = { filter_by: "scheduled" };

    this.getOrdersApiCallId = requestMessage.messageId;
    let urlParams = new URLSearchParams(params).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrdersApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isRefreshing: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showOrder = async() => {
    const Shoppingid: any = sessionStorage.getItem("addTOCartId")
    this.setState({ isVisible: true });
    const storedId = await getStorageData("orderId", true);
    const orderid = this.state.order_id? this.state.order_id: storedId;

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: localStorage.getItem("authToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showOrderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/order_creations/${orderid}`

    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createOrderItem = (token: string) => {
    if (
      this.isNumberNull(this.state.catalogue_id) ||
      this.isNumberNull(this.state.quantity) ||
      this.isNumberNull(this.state.taxable_value)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const order_items = {
      catalogue_id: this.state.catalogue_id,
      quantity: this.state.quantity,
      taxable: this.state.taxable,
      taxable_value: this.state.taxable_value,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createOrderItemApiCallId = requestMessage.messageId;

    const httpBody = {
      order_items,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createOrderItemApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getUserPets = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userAllPetsListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userPetsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  

  handleCheckInDate = (date: any) => {
   
    const { checkOutDate } = this.state;
    if (checkOutDate && date < checkOutDate) {
      this.setState({ checkOutValidation: false, checkInDate: date.toDate() });
    } else {
      this.setState({ checkOutValidation: true, checkInDate: date.toDate() });
    }
  };

  handleCheckOutDate = (date: any) => {
    
    const { checkInDate } = this.state;
    if (checkInDate && date > checkInDate) {
      this.setState({ checkOutDate: date.toDate(), checkOutValidation: false });
    } else {
      this.setState({ checkOutValidation: true });
    }
  };

 
  openPetPopup = (roomIndex: number, petTypeAdded: string) => {
    if( petTypeAdded === "dog" ){
      this.setState((prevState)=>{
        let updatedPetDetailsPopUp = [...prevState.petDetailsPopUpDog];
        updatedPetDetailsPopUp[roomIndex] = true;
        return {petDetailsPopUpDog: updatedPetDetailsPopUp};
      });
    } else {
      this.setState((prevState)=>{
        let updatedPetDetailsPopUp = [...prevState.petDetailsPopUpCat];
        updatedPetDetailsPopUp[roomIndex] = true;
        return {petDetailsPopUpCat: updatedPetDetailsPopUp};
      });
    }
  }
  closePetPopup = (roomIndex: number, petTypeAdded: string) => {
    if( petTypeAdded === "dog" ){
      this.setState((prevState)=>{
        let updatedPetDetailsPopUp = [...prevState.petDetailsPopUpDog];
        updatedPetDetailsPopUp[roomIndex] = false;
        return {petDetailsPopUpDog: updatedPetDetailsPopUp};
      });
    } else {
      this.setState((prevState)=>{
        let updatedPetDetailsPopUp = [...prevState.petDetailsPopUpCat];
        updatedPetDetailsPopUp[roomIndex] = false;
        return {petDetailsPopUpCat: updatedPetDetailsPopUp};
      });
    }
  };
  // additional services

  handleAdditionalServices = (value: string) => {
    this.setState({ petType: value === "dogAdditional" ? 'dogs' : 'cats' });
    if (value === "dogAdditional") {
      this.setState({
        openadditionalServices: true,
        additionalServicesPet: [...this.state.servicesDog],
        selectedType: value
      })
    } else if (value === "catAdditional") {
      this.setState({
        catAdditionalServices: true,
        additionalServicesPet: [...this.state.servicesCat],
        selectedType: value
      })
    }
  };

  handleServiceCheckBox = (event: ChangeEvent<HTMLInputElement>, serviceType: string, prices: number, addtinalId: number, hotelId: number, isDailyService: boolean) => {

    const { value } = event.target;
    const splitValue = value.split(' ')
    const selectedServiceOnChange = splitValue.slice(1).join(' ');
    const perDayPrice = prices;


    this.setState((prevState) => {
      const isChecked = prevState.checkedServicesSelected.includes(selectedServiceOnChange);

      const newState = {
        checkedServicesSelected: isChecked
          ? prevState.checkedServicesSelected.filter((service) => service !== selectedServiceOnChange)
          : [...prevState.checkedServicesSelected, selectedServiceOnChange],
      };

      if (serviceType === "dogs") {
        const newDogService = { name: `${selectedServiceOnChange} for dog`, price: prices, additional_service_id: addtinalId, hotel_additional_service_id: hotelId, isDailyService: isDailyService, perDayPrice: perDayPrice };

        Object.assign(newState, {
          checkedServicesDog: isChecked
            ? prevState.checkedServicesDog.filter((service) => service !== selectedServiceOnChange)
            : [...prevState.checkedServicesDog, selectedServiceOnChange],
          checkedServices: isChecked
            ? prevState.checkedServices.filter((service) => service !== selectedServiceOnChange)
            : [...prevState.checkedServices, selectedServiceOnChange],

          checkedServicesDogPrice: isChecked
            ? prevState.checkedServicesDogPrice.filter((service: any) => service.additional_service_id !== addtinalId)
            : [...prevState.checkedServicesDogPrice, newDogService],

        });







      } else if (serviceType === "cats") {
        const newCatService = { name: `${selectedServiceOnChange}`, price: prices, additional_service_id: addtinalId, hotel_additional_service_id: hotelId, isDailyService: isDailyService, perDayPrice: perDayPrice };
        Object.assign(newState, {
          checkedServicesCat: isChecked
            ? prevState.checkedServicesCat.filter((service) => service !== selectedServiceOnChange)
            : [...prevState.checkedServicesCat, selectedServiceOnChange],
          checkedServices: isChecked
            ? prevState.checkedServices.filter((service) => service !== selectedServiceOnChange)
            : [...prevState.checkedServices, selectedServiceOnChange],
          checkedServicesCatPrice: isChecked
            ? prevState.checkedServicesCatPrice.filter((service: any) => service.additional_service_id !== addtinalId)
            : [...prevState.checkedServicesCatPrice, newCatService],
        });
      }

      return newState;

    });
  };

  addAdditionalService = () => {
    this.setState({
      openadditionalServices: false,
      catAdditionalServices: false
    })
  }

  openServices = () => {
    this.setState({ openadditionalServices: true })
  }

  // room selections 
  openSelectRooms = (selectedRoom: string) => {
    if (selectedRoom === 'dog') {
      this.setState({
        dogOpenRoomStatus: true,
        selectRoomType: selectedRoom,
        selectedFor: selectedRoom,
        selectRoomPet: this.state.selectRoomForDog,

      })
    } else if (selectedRoom === "catRoom") {
      this.setState({
        catOpenRoomStatus: true,
        selectedType: selectedRoom,
        selectedFor: selectedRoom,
        selectRoomPet: this.state.selectRoomForCat
      })
    }
  }
  closeSelectRooms = () => {
    this.setState({
      dogOpenRoomStatus: false,
      catOpenRoomStatus: false,
      selectRoomType: '',
      selectedFor: "",
    })

  }


  // Popup modals
  openEditInfo = (petId: string, event: React.MouseEvent<HTMLDivElement>) => {
    this.state.userPetList.forEach((pet: any) => {
      if (pet?.id === petId) {
        this.setState({ editInfoStatus: event.currentTarget, EditPetId: pet?.id })

      }
      return pet
    })
  }

  closeEditInfo = () => {
    this.setState({ editInfoStatus: null })
  }
  proceedBooking = (type: string) => {
    this.setState({ dailogOpen: true, popupType: type })
  }
  closeModal = () => {
    if(this.state.paymentStatus){
      this.onReservationHistory()
    }else{

      this.setState({ dailogOpen: false, popupType: "" })
    }
  }
  seeHotelDetails = async() => {
    const getStorageID = await getStorageData("hotelID", true);
    const hotelID = this.state.hotel_id ? this.state.hotel_id : getStorageID;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiCallIdSeeHotelDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.hotesListApiEndPoint}/${hotelID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    if(!this.state.fromReservation){
      this.getCancellation();
    }
  }

  calculateDateDifference(): number | undefined {
    const { checkInDate, checkOutDate } = this.state;

    const timeDifference = checkOutDate.getTime() - checkInDate.getTime();
    return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  }


  async componentDidUpdate(prevProps: any, prevState: any) {

    if (
      this.state.checkOutDate !== prevState.checkOutDate ||
      this.state.checkInDate !== prevState.checkInDate
    ) {
      const daysDifference1 = this.calculateDateDifference();
      this.setState({
        totalDay: daysDifference1
      }, () => {
        this.calculatepriceTotal();
        this.checkDays(); 
        this.checkDaysOneTime();
        this.getDayCareSpaces();
      });
    }

    if (
      prevState.checkedServicesCat !== this.state.checkedServicesCat ||
      prevState.checkedServicesDog !== this.state.checkedServicesDog ||
      prevState.selectRoomForCatPrice !== this.state.selectRoomForCatPrice ||
      prevState.selectRoomForDogPrice !== this.state.selectRoomForDogPrice ||
      prevState.totalDay !== this.state.totalDay ||
      prevState.addRoomCat !== this.state.addRoomCat ||
      prevState.addRoomDog !== this.state.addRoomDog
    ) {
      this.calculateDateDifference();
      this.calculatepriceTotal();
    }
  }

  calculatepriceTotal = () => {
    const {
      checkedServicesCatPrice,
      checkedServicesDogPrice,
      selectRoomForCatPrice,
      selectRoomForDogPrice,
      totalDay,
      addRoomCat,
      addRoomDog,
      reservationPrice,
      ownServices,
    } = this.state;
    const totalStayDay = this.state.hotelType === "daycare_services" ? this.state.dayCareDays: (Number(totalDay) || 0);
    const TotalcalculateService = (services: {price: number, isAllPets: boolean, isDailyService: boolean, perDayPrice: number, pets: number}[]) => {
      return services
        .filter(service => !isNaN(service.price)).reduce((total, service) => {
          if(service.isDailyService && !service.isAllPets){
            return total + Number(service.perDayPrice * service.pets * totalStayDay);
          } else if(!service.isDailyService && !service.isAllPets) {
            return total + Number(service.price * service.pets);
          } else if(!service.isDailyService && service.isAllPets) {
            return total + Number(service.price * this.getTotalPets());
          } else {
            return total + Number(service.perDayPrice * totalStayDay * this.getTotalPets());
          }
        }, 0);
    };


    const totalCatServicesPrice = TotalcalculateService(checkedServicesCatPrice);
    const ownCatServicesPrice = TotalcalculateService(ownServices);
    this.setState({additionalServicePrice: totalCatServicesPrice + ownCatServicesPrice});

    const dogRoomPrice = this.state.dogSelectedHotel.reduce((sumOfPrices, selectedHotel: SelectedHotel) => {
      return sumOfPrices + Number(selectedHotel.price)*totalDay;
    }, 0);

    const catRoomPrice = this.state.catSelectedHotel.reduce((sumOfPrices, selectedHotel: SelectedHotel) => {
      return sumOfPrices + Number(selectedHotel.price)*totalDay;
    }, 0);

    const priceTotal =
      dogRoomPrice +(selectRoomForDogPrice && selectRoomForDogPrice > 0 ? selectRoomForDogPrice * totalStayDay  : 0) +
      catRoomPrice + (selectRoomForCatPrice && selectRoomForCatPrice > 0 ? selectRoomForCatPrice * totalStayDay  : 0) +totalCatServicesPrice
       + ownCatServicesPrice
      + Number(this.state.transactionFee);
    if(reservationPrice){
      this.getReservationPriceOption(totalStayDay, addRoomDog, addRoomCat, totalCatServicesPrice + ownCatServicesPrice);
    } else {
      if (!this.state.isDayCareService) {
        this.setState({ priceTotal: priceTotal.toFixed(2) });
      } else {
        this.setState({ priceTotal: ((Number(this.state.displayPrice) * this.state.petId.length * this.state.numberOfWeeks) +totalCatServicesPrice
          + ownCatServicesPrice + Number(this.state.transactionFee)).toFixed(2)});
      }
    }
  };

  getReservationPriceOption = (totalDays: number, dogCount: number, catCount: number, servicePrice: number) => {
    if(!this.state.isDayCareService){
      const totalPrice = this.getResrvationPrice(totalDays, dogCount, catCount);
        this.setState({priceTotal: (Number(totalPrice) + Number(this.state.transactionFee)).toFixed(2),
          editPrice: '£'+totalPrice.toString() });
    } else {
      const totalPrice = Number(this.state.displayPrice) * this.state.petId.length * Number(this.state.numberOfWeeks) + Number(servicePrice) + Number(this.state.transactionFee);
      this.setState({ priceTotal: totalPrice.toFixed(2)});
    }
  }

  getResrvationPrice = (totalDays: number, dogCount: number, catCount: number) => {
    const dogRoom = this.state.orderDetails.data.attributes.room_for_dogs;
    const catRoom = this.state.orderDetails.data.attributes.room_for_cats;
    const services = this.state.checkedServicesCatPrice;
    const ownService = this.state.ownServices;
    let total = 0;
    dogRoom && dogRoom.forEach((roomData: { price: number; pets: string[]; }) => {
      total = total + roomData.price * totalDays;
    });
    catRoom && catRoom.forEach((roomData: { price: number; pets: string[]; }) => {
      total = total + roomData.price * totalDays;
    });
    services && services.forEach((roomData: { isDailyService: boolean; isAllPets: boolean; price: number; pets: number; perDayPrice: number; }) => {
      if(!roomData?.isDailyService && !roomData.isAllPets){
        total = total + roomData?.price * (roomData.pets);
      } else if (!roomData?.isDailyService && roomData.isAllPets) {
        total = total + roomData?.price * (dogCount + catCount);
      }else if(roomData?.isDailyService && roomData.isAllPets){
        total = total + roomData?.perDayPrice * (dogCount + catCount) * totalDays;
      } else {
        total = total + roomData?.perDayPrice * (roomData.pets) * totalDays;
      }
    });

    ownService && ownService.forEach((roomData) => {
      if(!roomData?.isDailyService && !roomData.isAllPets){
        total = total + roomData?.price * (roomData.pets);
      } else if(roomData?.isDailyService && roomData.isAllPets){
        total = total + roomData?.perDayPrice * (dogCount + catCount) * totalDays;
      } else if (!roomData?.isDailyService && roomData.isAllPets) {
        total = total + roomData?.price * (dogCount + catCount);
      } else {
        total = total + roomData?.perDayPrice * (roomData.pets) * totalDays;
      }
    });
    return total;
  };


  adddogsRoomOnClick = (event: {target: {checked: boolean}}, petDetailId: string, petName: string, roomIndex: number, bookings: number) => {
    const isChecked = event.target.checked;
    this.setState(prevState => {
      let updatedPetId = prevState.petId ? [...prevState.petId]: [];
      let dogIds = prevState.dogId;
      let dogNames = prevState.dogName;
      if (isChecked) {
        if( dogIds[roomIndex].length+1 <= bookings ){
          updatedPetId.push(petDetailId);
          dogIds[roomIndex].push(petDetailId);
          dogNames[roomIndex].push(petName);
        } else{
          this.setState({flashLogin: true});
        }
      } else {
        updatedPetId = updatedPetId.filter((petId: string) => petId !== petDetailId);
        dogIds[roomIndex] = prevState.dogId[roomIndex].filter((petId: string) => petId !== petDetailId);
        dogNames[roomIndex] = prevState.dogName[roomIndex].filter((petname: string) => petname !== petName);
        const ownServicePetIds = this.state.ownServicePetIds.map(service => {
          const ownpetsList = service.pets.filter(petNames => petNames !== petName);
          return {...service, pets: ownpetsList};
        });
        const servicePetIds = this.state.servicePetIds.map(service => {
          const ownpetsList = service.pets.filter(petNames => petNames !== petName);
          return {...service, pets: ownpetsList};
        });
        this.setState({ ownServicePetIds, servicePetIds });
      }
            
      return { checkeddog: isChecked, petId: updatedPetId, dogId: dogIds, dogName: dogNames};
    }, ()=>{
      let petServiceList = this.state.dogmap.filter(details => this.state.petId.includes(details.id));
      this.setState({serviceDogList: petServiceList});
    });
    
  };



  addcatsRoomOnClick = (event: {target: {checked: boolean}}, petDetailId: string, petName: string, roomIndex: number, bookings: number) => {
    const isChecked = event.target.checked;
    this.setState(prevState => {
      let updatedPetId = [...prevState.petId];
      let catIds = prevState.catId;
      let catNames = prevState.catName;
      if (isChecked) {
        if( catIds[roomIndex].length+1 <= bookings ){
          updatedPetId.push(petDetailId);
          catIds[roomIndex].push(petDetailId);
          catNames[roomIndex].push(petName); 
        }
        else{
          this.setState({flashLogin: true},
            () => {
              window.scrollTo({
                top: 0
              });
            }
          );
        }
      } else {
        updatedPetId = updatedPetId.filter((petId: string) => petId !== petDetailId);
        catIds[roomIndex] = prevState.catId[roomIndex].filter((petId: string) => petId !== petDetailId);
        catNames[roomIndex] = prevState.catName[roomIndex].filter((name: string) => name !== petName);
        const servicePetIds = this.state.servicePetIds.map(service => {
          const petsList = service.pets.filter(petNames => petNames !== petName);
          return {...service, pets: petsList};
        });
        const ownServicePetIds = this.state.ownServicePetIds.map(service => {
          const petsList = service.pets.filter(petNames => petNames !== petName);
          return {...service, pets: petsList};
        });
        this.setState({ ownServicePetIds, servicePetIds });
      }
      return { checkedcat: isChecked, petId: updatedPetId, catId: catIds, catName: catNames };
    }, ()=>{
      let petServiceList = this.state.catmap.filter(details => this.state.petId.includes(details.id));
      this.setState({serviceCatList: petServiceList});
    });
  };





  handleAddPetApiResponse = async (responseJson: any) => {
  
    const allRooms1 = [...responseJson];
    const roomOfHotel1 = allRooms1.filter((value) => value.hotel_id == this.state.hotel_id)
    if (this.state.fromReservation === true) {  
      const roomOfHotel = responseJson.filter((value: { hotel_id: string; }) => value.hotel_id == this.state.hotelId);
      this.setState({ roomDetails: roomOfHotel });
      if(this.state.orderDetails.data.attributes.hotel_information.pet_type === "daycare_services"){
        this.getDayCareDetails();
      } else {
        this.getRoomsList();
      }
    }

    if (responseJson && !responseJson.error) {
      const dogRooms = this.filterRooms1(roomOfHotel1, "Dog");

      const catRooms = this.filterRooms1(roomOfHotel1, "Cat");

      if (dogRooms.length > 0) {
        this.handleRoomDetailsUpdate(dogRooms[0], dogRooms, "roomsDog", "roomTypeForDog");
      } else {
        this.setState({ selectRoomForDog: "No room", })
      }
      if (catRooms.length > 0) {
        this.handleRoomDetailsUpdate(catRooms[0], catRooms, "roomsCat", "roomTypeForCat");
      } else { this.setState({ selectRoomForCat: "No room" }) }
    }
  }

  filterRooms1(responseJson: any, roomType: string) {
    const room1 = [...responseJson]
    return room1.filter((value) => value.room_for == roomType);
  }

  handleRoomDetailsUpdate(room: any, roomsArray1: any[], roomsStateKey1: string, roomTypeStateKey: string) {
    const stateUpdates1: any = {
      roomDetailData: room,
      roomDetailId: room.id,
      selectRoomForDog: this.state.selectRoomForDog,
      selectRoomForCat: this.state.selectRoomForCat,
      selectRoom: room.room_type,
      [roomsStateKey1]: roomsArray1.reduce((accumulator: any[], value: any) => {
        if (!accumulator.some((room) => room.name === value.room_type)) {
          accumulator.push({
            name: value.room_type,
            data: value,
          });
        }
        return accumulator;
      }, []),
      [roomTypeStateKey]: [...new Set(roomsArray1.map((value: any) => value.room_type))],
    };

    this.setState(stateUpdates1);
  }



  getRoomData = () => {


    const loginRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.viewroomID = loginRequestMessage.messageId;
    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_custom_form/rooms`
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);
  }

  handlePetTypeChange = (value: string, selectedRoomFor: string, price: any, id: any) => {



    if (selectedRoomFor === "dog") {

      const priceArray1 = Array.isArray(price) ? price.map((value: string) => parseFloat(value)) : [];
      const sumOfPrices = priceArray1.reduce((acc: number, currentValue: number) => acc + currentValue, 0);

      this.setState({
        selectRoomForDog: value,
        // selectRoomForDogPrice: price,
        selectRoomPet: value,
        dogRoomId: id
      });
    } else if (selectedRoomFor === "catRoom") {
      const priceArray1 = Array.isArray(price) ? price.map((value: string) => parseFloat(value)) : [];

      const sumOfPrices = priceArray1.reduce((acc: number, currentValue: number) => acc + currentValue, 0);

      this.setState({
        // selectRoomForCatPrice: price,
        selectRoomPet: value,
        selectRoomForCat: value,
        catRoomId: id
      });
    }
  }





  OnEditPet = () => {
    setStorageData('pagecomefrom', "Settings2")
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EditPetDetails'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { selectedPetId: this.state.EditPetId, value: "Edit", from: "ShoppingCart" })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }


  handleRemovePet = async () => {

    let token = localStorage.getItem("authToken");
    const selectedPetId = this.state.EditPetId

    if (!selectedPetId) {
      return;
    }


    const headers = {
      token: token,
    };

    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/pets/${selectedPetId}`
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );


    this.removepetcallID = requestMessage1.messageId;
    runEngine.sendMessage(requestMessage1.id, requestMessage1)


  };


  onReservations = () => {

    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'Reservations');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);


    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { selecteddataId: this.state.order_id });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  }


  onReservationHistory = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'ReservationHistory');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);


    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { selectstatus: "pending" });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  }
  
  checkUpdateValidation = () => {
    if(this.state.checkOutValidation || this.state.checkoutDayCare){
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      this.setState({
        roomError: "Please select appropriate data while editing information",
        isRoomError: true
      }, ()=>{
        this.setState({
          isRoomError: false
        })
      });
    } else{
      this.checkValidation();
    }
  };

  checkValidation = async()=>{
    let additionalServiceValidation: boolean = false;
    let ownServiceValidation: boolean = false;
    if(this.state.servicePetIds.length > 0){
      const serviceList = this.state.servicePetIds;
      additionalServiceValidation = serviceList.reduce((initialCondition: boolean, petDetail:{isAllPets: string, pets: string[]})=> {return petDetail.isAllPets !== "All Pets" && petDetail.pets.length === 0 || initialCondition}, false);
    }
    if(this.state.ownServicePetIds.length > 0){
      const ownServiceList = this.state.ownServicePetIds;
      ownServiceValidation = ownServiceList.reduce((initialCondition: boolean, petDetail: {isAllPets: string, pets: string[]})=> {return petDetail.isAllPets !== "All Pets" && petDetail.pets.length === 0 || initialCondition}, false);
    }
     if(this.state.petId?.length !== Number(this.state.addRoomCat)+Number(this.state.addRoomDog)){
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      this.setState({ petError: true },()=>{this.setState({ petError: false })})
     }else if(additionalServiceValidation || ownServiceValidation){
      this.setState({ petSelectionErr: true },()=>{this.setState({ petSelectionErr: false })})
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
     }else{
        await removeStorageData("petId");
        await removeStorageData("catId");
        await removeStorageData("checkedcat");
        await removeStorageData("catName");
        await removeStorageData("dogId");
        await removeStorageData("checkeddog");
        await removeStorageData("dogName");
      this.setState({ petError: false })
       this.orderUpdationApi()
     }
  }

  checkPets = () => {
    let additionalServiceValidation1: boolean = false;
    let ownServiceValidation1: boolean = false;
    if(this.state.servicePetIds.length > 0){
      const serviceList = this.state.servicePetIds;
      additionalServiceValidation1 = serviceList.reduce((initialCondition1: boolean, petDetail:{isAllPets: string, pets: string[]})=> {return petDetail.isAllPets !== "All Pets" && petDetail.pets.length === 0 || initialCondition1}, false);
    }
    if(this.state.ownServicePetIds.length > 0){
      const ownServiceList = this.state.ownServicePetIds;
      ownServiceValidation1 = ownServiceList.reduce((initialCondition1: boolean, petDetail: {isAllPets: string, pets: string[]})=> {return petDetail.isAllPets !== "All Pets" && petDetail.pets.length === 0 || initialCondition1}, false);
    }
    return additionalServiceValidation1 || ownServiceValidation1;
  }
  updateValidation = () => {
    if(this.state.daycareError){
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
        this.setState({
          editErrorMsg: "Please enter correct values",
          editError: true
        },()=>{          
          this.setState({
            editError: false
          });
        }
      )
    } 
    else if (this.checkPets()){
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
        this.setState({
          editError: true,
          editErrorMsg: "Please select atleast one pet for additional service.",
        },()=>{          
          this.setState({
            editError: false
          })
        }
      )
    }
    else{
      this.orderUpdationApi();
    }
  };

  orderUpdationApi = () => {


    const serviceidDog = this.state.checkedServicesDogPrice.map((value: { additional_service_id: object }) =>
      value.additional_service_id
    );

    const serviceidCat = this.state.checkedServicesCatPrice.map((value: { additional_service_id: object }) =>
      value.additional_service_id
    );

    const ownServiceIds = this.state.ownServices.map((value: { hotel_additional_service_id: number }) =>
      value.hotel_additional_service_id
    );

    const roomForDog: RoomAdded[] = [];
    const roomForCat: RoomAdded[] = [];
    let dayCareTypeText = "";
    if(this.state.selectedPriceType === "OneDay"){
      dayCareTypeText="One_Time";
    } else{
      dayCareTypeText="Repeat_Weekly";
    }
    
    this.state.dogSelectedHotel?.forEach((selectedRoom: SelectedHotel, roomIndex: number)=> {
      const newRoom: RoomAdded = {id: 0, price: -1, pets: []};
      newRoom.id = Number(selectedRoom.roomId);
      newRoom.price = Number(selectedRoom.price);
      newRoom.pets = this.state.dogId[roomIndex];
      if (newRoom.id !== 0) {
        roomForDog.push(newRoom);
      }
    });

    this.state.catSelectedHotel?.forEach((selectedRoom: SelectedHotel, roomIndex: number)=> {
      const newRoom: RoomAdded = {id: 0, price: -1, pets: []};
      newRoom.id = Number(selectedRoom.roomId);
      newRoom.price = Number(selectedRoom.price);
      newRoom.pets = this.state.catId[roomIndex];
      if (newRoom.id !== 0) {
        roomForCat.push(newRoom);
      }
    })


    if (this.state.petId?.length > 0) {
      const header = {
        "Content-Type": "application/json",
        token: localStorage.getItem("authToken")
      };



      const serviceIds = this.state.servicePetIds.map((petid) => {
        return {service_id: petid.serviceId, ...petid}
      });

      const ownServiceId = this.state.ownServicePetIds.map((petid) => {
        return {service_id: petid.serviceId, ...petid}
      });
      
      const httpBody = {

        "order_creation": {
          "additional_question_answer_one": this.state.additionalQuestion1,
          "additional_question_answer_two": this.state.additionalQuestion2,
          "additional_question_answer_three": this.state.additionalQuestion3,
          "hotel_id": this.state.hotel_id,
          "check_in_date": this.state.checkInDate,
          "check_out_date": this.state.checkOutDate,
          "pet_ids": this.state.petId,
          "room_type_for_dog": this.state.selectRoomForDog,
          "additional_services_for_dog": serviceIds,
          "own_services_for_dog": ownServiceId,
          "dog_room_id": this.state.dogRoomId,
          "room_type_for_cat": this.state.selectRoomForCat,
          "additional_services_for_cat": serviceidCat,
          "own_services_for_cat": ownServiceIds,
          "cat_room_id": this.state.catRoomId,
          "price":this.state.fromReservation === true ?`£${this.getTotalReservationPrice()}`: `£${this.getTotalPrice()}`,
          "number_of_dogs": this.state.addRoomDog,
          "number_of_cats": this.state.addRoomCat,
          "room_price_for_dog":this.state.selectRoomForDogPrice,
          "room_price_for_cat":this.state.selectRoomForCatPrice,
          "pet_count_for_dog":this.state.dogRoomPetCount,
          "pet_count_for_cat":this.state.catRoomPetCount,
          "day_care_service_type": dayCareTypeText,
          "days":this.state.selectedDays,
          "day_care_price": this.getDayCarePrice(),
          "weekly_price": this.getWeeklyPrice(),
          "additional_notes":this.state.additionalDetails,
          "room_for_dogs": roomForDog,
          "room_for_cats": roomForCat
        }

      }



      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        this.state.fromReservation === true ? `bx_block_shopping_cart/order_creations/${this.state.order_id}` : `bx_block_shopping_cart/order_creations`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        this.state.fromReservation === true ? "PUT" : "POST"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      this.orderupdateId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);

    } else {
      this.setState({ petError: true })
    }

  }

  handleCloseSnack = () => {
    this.setState({ 
      petError: false,
      openSnck: false,
      flashLogin:false
    })
  }



  getstandardCancellation = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.getStandardPolicyApiCallId = requestMessage.messageId;



    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.standardPolicyEndPoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };






  getCancellation = () => {

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );


    this.getPolicyApiCallId = requestMessage.messageId;

    let urlParams = this.state.hotel_id;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.cancellationPolicyEndPoint}=${urlParams}`
      // `bx_block_cancellation/cancellation_policies?hotel_id=926`
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  getCancellationApiResponce = (responseJson: any) => {
    if (responseJson && !responseJson?.errors) {
      this.setState({cancellationDetails:responseJson.data})

      if (responseJson.data.attributes?.cancellation_type === "standard") {
        this.setState({ serviceKey: responseJson?.data?.attributes?.cancellation_type }, () => { this.getstandardCancellation() })
      } else if(responseJson?.data?.attributes){
        this.setState({ tailoredCancellation: responseJson?.data?.attributes })


      }
    }
  }

  checkroomprice = (event: any, pet: any, index: number) => {


    if (pet === "dog") {
         this.setState({ selectRoomForDogPrice: event,dogRoomPetCount: index+1,
        })
    } else if (pet === "catRoom") {
          this.setState({ selectRoomForCatPrice: event, catRoomPetCount: index+1
       })
    }
  }

  getPaymentStatus=(paymentStatus:boolean)=>{
    this.setState({paymentStatus:paymentStatus},()=>{
      if(this.state.paymentStatus){
        this.proceedBooking('booking')
        // handleNavigation('ReservationHistory',{},this.send,this.props)
      }else{
        handleNavigation('PetDetailsPage',{},this.send,this.props)

      }
    })

  }
  handleModalOpen = () => {
    window.scrollTo({
      top: 0
    });
    this.setState({ openModal: true });
  };
  
  handleModal = () => {
    this.setState({ openModal: !this.state.openModal });
    this.getDayCareSpaces();
    if(this.state.fromReservation){
      this.handleContinue();
      this.setState({
        selectedDays: this.state.selectedDays1,
        selectedPriceType: this.state.selectedPriceType1,
        displayPrice: this.state.displayPrice1,
        additionalDetails: this.state.additionalDetails1,
        selectFeildText: this.state.selectFeildText1,
        checkInDate: this.state.checkInDate1,
        checkOutDate: this.state.checkOutDate1,
        dayCareDays: this.state.dayCareDays1,
        daycareError: false
      });
    }
  };

  getDayCareSpaces = async() => {
    const hotelID = await getStorageData('hotelID',true)
    this.getAvailableSpaceCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.hotesListApiEndPoint}/${hotelID}?check_in_date=${this.formatDate(this.state.checkInDate)}&check_out_date=${this.formatDate(this.state.checkOutDate)}`
    });
  };

  getDogMap = ( petTypeAdded: string ) => {
    return (this.state.addRoomDog > 0 && petTypeAdded === "dog") ;
  };

  getCatMap = ( petTypeAdded: string ) => {
    return this.state.addRoomCat > 0 && petTypeAdded === "cat" ;
  };

  getTotal = (price: number, totalDayStay: number) => {
    return (price * totalDayStay).toFixed(2) ;
  };

  getTotalPets =() => {
    return this.state.addRoomCat + this.state.addRoomDog;
  };

  getDogSelectionText = (petTypeAdded: string, bookings: number)=> {
    return (petTypeAdded === "dog" && bookings > 0);
  };

  getCatSelectionText =(petTypeAdded: string, bookings: number) => {
    return (petTypeAdded === "cat" && bookings > 0);
  };

  getRenderPetInformation = (petTypeAdded: string, roomIndex: number) =>{
    return (petTypeAdded === "dog" && this.state.petDetailsPopUpDog[roomIndex]) ||
    (petTypeAdded === "cat" && this.state.petDetailsPopUpCat[roomIndex]);
  }

  getDayCareServiceCost = async() => {
    let selectHotelID = await getStorageData('selectedHotelId',true);
    if(this.state.fromReservation){
      selectHotelID = this.state.orderDetails.data.attributes.hotel_information.id;
    }
    
    this.userDayCareApiCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.dayCareEndpoint.replace("{hotelId}",selectHotelID)}`
    });
  };

  getOrderDetails = async() => {
    const orderId = await getStorageData('orderId',true);
    this.orderDetailCallId = await this.apiCall({
      contentType:configJSON.apiContentType,
      method:configJSON.getApiMethod,
      endPoint:`${configJSON.orderDetailsApi}${orderId}`
    });
  };

  dayCareResponce = (responseJson: DayCareServiceCosts & {error: string}) => {
    if(responseJson.day_care_services) {
      const priceList= responseJson.day_care_services.data[0];
      const onetimePrices: (string | null)[] = [
        "� " + priceList.attributes.price_for_one_day,
        "� " + priceList.attributes.price_for_two_days,
        "� " + priceList.attributes.price_for_three_days,
        "� " + priceList.attributes.price_for_four_days,
        "� " + priceList.attributes.price_for_five_days,
        "� " + priceList.attributes.price_for_six_days,
        "� " + priceList.attributes.price_for_seveen_days
    ];
    const weeklyPrices: (string | null)[] = [
        "� " + priceList.attributes.weekly_price_for_one_day,
        "� " + priceList.attributes.weekly_price_for_two_days,
        "� " + priceList.attributes.weekly_price_for_three_days,
        "� " + priceList.attributes.weekly_price_for_four_days,
        "� " + priceList.attributes.weekly_price_for_five_days,
        "� " + priceList.attributes.weekly_price_for_six_days,
        "� " + priceList.attributes.weekly_price_for_seveen_days
    ];
      this.setState({
        oneTimePrice:onetimePrices,
        weeklyPrice:weeklyPrices,
        priceList
      })
    }
  };

  apiCall = async (apiData: APIPayloadType) => {
    const { method, endPoint, body, type } = apiData;
    
    let token = await getStorageData("authToken");
    const header = {
      token: token,
      "Content-Type": configJSON.apiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body && type !== "formData"
      ? requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  setButtonText = (selectedDaysIndex: number[], priceTypeString: string ) => {
     const daysString = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
     const priceType = priceTypeString === "OneDay" ? "One Time": "Repeated weekly";
     let btnString = priceType+" ( ";
     selectedDaysIndex.sort((prevIndex, nextIndex) => prevIndex - nextIndex);
     selectedDaysIndex.forEach((days, dayIndex)=> {
      if(dayIndex !== selectedDaysIndex.length-1){
        btnString = btnString + daysString[days] + ", ";
      } else {
        btnString = btnString + daysString[days];
      }
     });
     btnString = btnString + " )";
    this.setState({buttonText: btnString});
    };

  getSelectedHotel = () => {
    return this.state.hotelType!=="daycare_services" && this.state.dogSelectedHotel.length !== 0 && this.state.addRoomDog !== 0;
  };

  getDisabled = (roomIndex: number, petDatils: PetDetailsData) => {
    return !this.state.dogId[roomIndex].includes(petDatils.id) && this.state.petId?.includes(petDatils.id);
  };

  getDisabledCat = (roomIndex: number, petDatils: PetDetailsData) => {
    return !this.state.catId[roomIndex].includes(petDatils.id) && this.state.petId?.includes(petDatils.id);
  };

  getCatRoomPrice = () => {
    return this.state.hotelType!=="daycare_services" && (this.state.catSelectedHotel.length !== 0 && this.state.addRoomCat !== 0);
  };

  getTotalPetCost = (price: number, pets: number, totalDay: number) => {
    return Number(price * pets * totalDay).toFixed(2);
  };

  getTotalDaycareCost = (price: number, totalDay: number) => {
    return price * totalDay;
  };
  
  getTotalCost = (price: number, totalDay: number) => {
    return price * totalDay;
  };

  getPriceSectionText = (price: number, pets: number) => {
    return Number(price * pets).toFixed(2);
  }

    calculatedayCarePrice = () => {
      return Number(this.state.displayPrice) * this.state.petId.length* this.state.numberOfWeeks;
    };

    getDogNameList = (roomIndex: number) => {
      let nameList = "";
    this.state.dogName[roomIndex].forEach((name: string, nameIndex: number)=>{
      if(nameIndex === this.state.dogName[roomIndex].length-1 && nameIndex !== 0){
        nameList = nameList + " and " + name;
      } else if(nameIndex === 0){
        nameList = nameList + name;
      } else{
        nameList = nameList + ", "+ name ;
      }
    })
        return nameList;
    };   

  getDogNumber = (bookings: number) => {
    return bookings > 1 ? "s" : "";
  };

  getDogNames = (bookings: number, roomIndex: number) => {
      return this.state.dogName[roomIndex].length>0?
        this.getDogNameList(roomIndex)
        :`${bookings} ${configJSON.dogTxt}${this.getDogNumber(bookings)}` 
  };

  getCatNameList = (roomIndex: number) => {
    let catNameList = "";
    this.state.catName[roomIndex].forEach((name: string, catNameIndex: number)=>{
      if(catNameIndex === this.state.catName[roomIndex].length-1 && catNameIndex !== 0){
        catNameList = catNameList + " and " + name;
      } else if(catNameIndex === 0){
        catNameList = catNameList + name;
      } else{
        catNameList = catNameList + ", "+ name ;
      }    })
      return catNameList;
  };

  getNameList = (petList: {id: string, attributes: {name: string}}[]) => {
    let petnameList = "";
    const existingNames = petList.map((petDetails: {id: string, attributes: {name: string}})=>{
      return petDetails.attributes.name
    });
    this.setState({petName: existingNames});
    petList.forEach((petData: {id: string, attributes: {name: string}}, petIndex: number)=>{
      if(petIndex === petList.length-1 && petIndex !== 0){
        petnameList = petnameList + " and " + petData.attributes.name;
      } else if(petIndex === 0){
        petnameList = petnameList + petData.attributes.name;
      } else{
        petnameList = petnameList + ", "+ petData.attributes.name ;
      }    })
      return petnameList;
  };

  getCatNames = (bookings: number, roomIndex: number) => {
      return this.state.catName[roomIndex].length>0?
        this.getCatNameList(roomIndex)
        :`${bookings} ${configJSON.catTxt}${this.getDogNumber(bookings)}` 
  };

  getChecked= (petDatilsId: string) => {
    return this.state.petId?.includes(petDatilsId)
  };

  getCheckPriceValue = () => {
    if(this.state.hotelDetails.pet_type === "daycare_services"){
      return this.state.hotelDetails.day_care_services.data[0].attributes.price_for_one_day
    } 
    return this.state.hotelDetails.budget_per_day;
  };

  getTotalPrice = () => {
    return this.state.hotelDetails.pet_type==="daycare_services"? this.state.priceTotal - Number(this.state.transactionFee) : this.state.priceTotal;
  };

  getTotalReservationPrice = () => {
    return  this.state.priceTotal - Number(this.state.transactionFee);
  };

  getRoomName = (roomId: number) => {
    const roomName = this.state.roomDetails.find((roomData: { id: number; })=> roomId == roomData.id);
    return roomName?roomName.room_type:"";
  };

  getPetNames = (petId: string, petType: string) => {
    if(petType === "dog"){
      const dogName =  this.state.dogmap.find(dogDetails => dogDetails.id === petId);
       return dogName?dogName.attributes.name: "";
    }
    else if(petType === "cat"){
      const catName = this.state.catmap.find(catDetails => catDetails.id === petId)
      return catName?catName.attributes.name: "";
   }
    return "A";
  };

  getRoomsList = () => {
    const dogHotels: Array<SelectedHotel> = [];
    const catHotels: Array<SelectedHotel> = [];
    let dogNames: Array<Array<string>> = [];
    const catNames: Array<Array<string>> = [];
    let dogIds: Array<Array<string>> = [];
    const catIds: Array<Array<string>> = [];

    const petIds = this.state.orderDetails.data.attributes.pets.data.map((petDetails: {id: string})=> petDetails.id);
    this.state.orderDetails.data.attributes.room_for_cats.forEach((roomData: {id: number, price: number, pets: string[], type: string}) =>{
      catHotels.push({
        roomId: roomData.id,
        price: roomData.price,
        bookings: roomData.pets.length,
        name: roomData.type
      })
    });
    this.state.orderDetails.data.attributes.room_for_dogs.forEach((roomData: {id: number, price: number, pets: string[], type: string}) =>{
      const name = this.getRoomName(roomData.id);
      dogHotels.push({
        roomId: roomData.id,
        name: roomData.type,
        bookings: roomData.pets.length,
        price: roomData.price
      })
    });
    this.state.orderDetails.data.attributes.room_for_cats.forEach((roomData: {id: number, price: number, pets: string[]}) =>{
      const petNames = roomData.pets.map((petId: string) => {
        return this.getPetNames(petId, "cat");
      });
      catNames.push(petNames);
      catIds.push(roomData.pets);
    });
    this.state.orderDetails.data.attributes.room_for_dogs.forEach((roomData: {id: number, price: number, pets: string[]}) =>{
      const petNames = roomData.pets.map((petId: string) => {
        return this.getPetNames(petId, "dog");
      });
      dogNames.push(petNames);
      dogIds.push(roomData.pets);
    });


    this.setState({
      hotelType: this.state.orderDetails.data.attributes.hotel_information.pet_type,
      dogSelectedHotel: dogHotels,
      catSelectedHotel: catHotels,
      dogName: dogNames,
      catName: catNames,
      dogId: dogIds,
      catId: catIds,
      ShoppingcartLoader: false,
      petId: petIds,
      transactionFee: ""+this.state.orderDetails.data.attributes.total_charges
    }, () => { 
      this.calculatepriceTotal();
     }
  );
  };

  checkDays = () => {
    if (this.checkIfWeeklyPrice()) {
      const totalDay: number = Math.floor(this.state.totalDay);
      const startDay = this.state.checkInDate.getDay();
      const myDayList = [0, 0, 0, 0, 0, 0, 0];
      let date = 0, getday = startDay;
      while (date < totalDay) {
        myDayList[getday] += 1;
        getday = (getday + 1) % 7;
        date += 1;
      }
      const myDaySet: Set<number> = new Set();
      myDayList.forEach((dayFreq: number, dayIndex: number) => {
        dayIndex = dayIndex > 0 ? dayIndex - 1 : 6;
        if (this.state.orderDetails.data.attributes.days.includes(String(dayIndex)) || this.state.selectedDays.includes(dayIndex)) {
          myDaySet.add(dayFreq);
        }
      });
      if( this.state.totalDay < 6){
        this.setState({ 
          numberOfWeeks: 0, 
          modalError: "These days do not match the criteria.", 
          isModalError: true, 
          checkoutDayCare: true,
          daycareError: true
        },
          () => {
            this.getNumberOfWeeks();
            this.calculatepriceTotal();
            setTimeout(() => {
              this.setState({ isModalError: false })
            }, 2000);
          });

      }
      if (myDaySet.size > 1) {
        this.setState({ numberOfWeeks: 0, modalError: "These days do not match the criteria.", isModalError: true, checkoutDayCare: true, daycareError: true },
          () => {
            this.getNumberOfWeeks();
            this.calculatepriceTotal();
            setTimeout(() => {
              this.setState({ isModalError: false })
            }, 2000);
          });
      } else {
        this.setState({ numberOfWeeks: Array.from(myDaySet)[0], modalError: "", isModalError: false, checkoutDayCare: false, daycareError: false },
          () => {
            this.getNumberOfWeeks();
            this.calculatepriceTotal();
          });
      }
    }
  };

  checkDaysOneTime = () => {
    if (
      this.state.orderDetails.data.attributes.hotel_information.pet_type === "daycare_services" &&
      this.state.orderDetails.data.attributes.day_care_service_type === "One_Time"
    ) {
      let startDay = this.state.checkInDate.getDay()
      startDay = startDay >= 1 ? startDay - 1 : 6;
      const totalDay = Math.floor(this.state.totalDay)
      const dayList = [0, 0, 0, 0, 0, 0, 0]
      let date = 0, getday = startDay
      while (date < totalDay) {
        dayList[getday] += 1
        getday = (getday + 1) % 7
        date += 1
      };
      const uncoveredDays = this.state.orderDetails.data.attributes.days.some((daySelected: string) => dayList[Number(daySelected)] === 0);
      if(uncoveredDays){
        this.setState({ modalError: "These days do not match the criteria.", isModalError: true, checkoutDayCare: true, daycareError: true },
          () => {
            this.getNumberOfWeeks();
            this.calculatepriceTotal();
            setTimeout(() => {
              this.setState({ isModalError: false })
            }, 2000);
          });
      } else {
        this.setState({ modalError: "", isModalError: false, checkoutDayCare: false },
        () => {
          this.getNumberOfWeeks();
          this.calculatepriceTotal();
        });
      }
    }
  };

  getDayCareDetails = async() =>{
    await this.getDayCareServiceCost();
    const petIds = this.state.orderDetails.data.attributes.pets.data.map((petDetails: {id: string})=> petDetails.id);
    this.setState({
        hotelType: "daycare_services",
        selectedDays: this.state.orderDetails.data.attributes.days.map((dayCount: string)=> Number(dayCount)), 
        selectedDays1: this.state.orderDetails.data.attributes.days.map((dayCount: string)=> Number(dayCount)), 
        selectedPriceType: this.state.orderDetails.data.attributes.day_care_service_type === "One_Time" ? "OneDay" : "Weekly",
        selectedPriceType1: this.state.orderDetails.data.attributes.day_care_service_type === "One_Time" ? "OneDay" : "Weekly",
        isDayCareService: true,
        ShoppingcartLoader: false,
        displayPrice: this.state.orderDetails.data.attributes.day_care_service_type === "One_Time"?this.state.orderDetails.data.attributes.day_care_price : this.state.orderDetails.data.attributes.weekly_price,
        displayPrice1: this.state.orderDetails.data.attributes.day_care_service_type === "One_Time"?this.state.orderDetails.data.attributes.day_care_price : this.state.orderDetails.data.attributes.weekly_price,
        petId: petIds,
        additionalDetails: this.state.orderDetails.data.attributes.additional_notes,
        additionalDetails1: this.state.orderDetails.data.attributes.additional_notes,
        selectFeildText: this.getNameList(this.state.orderDetails.data.attributes.pets.data),
        selectFeildText1: this.getNameList(this.state.orderDetails.data.attributes.pets.data),
        transactionFee: ""+this.state.orderDetails.data.attributes.total_charges,
        dayCareDogCount: this.state.addRoomDog,
    },() => {
      this.calculatepriceTotal();
      this.setButtonText( this.state.selectedDays, this.state.selectedPriceType );
      this.checkDays();
    });
    
  };

  getDayCarePrice = () => {
    return this.state.selectedPriceType === "OneDay"?this.state.displayPrice: "";
  };

  getWeeklyPrice = () => {
    return this.state.selectedPriceType === "Weekly"?this.state.displayPrice: "";
  };

  getDisplayPrice = () => {
    return String((Number(this.state.displayPrice) * this.state.numberOfWeeks));
  };
  getNumberOfWeeks = () => {
    if(this.state.selectedPriceType === "Weekly" || this.state.orderDetails.data.attributes.day_care_service_type === "Repeat_Weekly"){
      if(this.state.numberOfWeeks > 1){
        return `X ${this.state.numberOfWeeks} Weeks`
      } 
      else {
        return "X 1 Week"
      }
    }
    return "";
  };

  checkIfWeeklyPrice = () => {
    return (this.state.selectedPriceType === "Weekly") ||
    (this.state.orderDetails.data.attributes.hotel_information.pet_type === "daycare_services" &&
    this.state.orderDetails.data.attributes.day_care_service_type !== "One_Time");
  };

  isPetSelected = (petTypeAdded: string, bookings: number, roomIndex: number) => {
    return (this.getDogSelectionText(petTypeAdded, bookings) && this.state.dogName[roomIndex].length>0) || 
    (this.getCatSelectionText(petTypeAdded, bookings) && this.state.catName[roomIndex].length>0); 
  };

  getStandardCancellation = async() => {
    let selectHotelID = await getStorageData('selectedHotelId',true);
    this.getStandardCancellationCallId = await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint: `${configJSON.getCancelationPolicyEndPoint.replace("{hotelId}",selectHotelID)}`
    });
  };

  getTermsAndConditionsUrl = async() => {

    this.apiCallIdgetTermsAndConditionsUrl= await this.apiCall({
      contentType: configJSON.apiContentType,
      method: configJSON.getApiMethod,
      endPoint:configJSON.stripeTermsAndConditionsEndPoint
    });
  };

  getAdditionalQuestions = (response: {
    data: {
      attributes: {
        additional_condition: string,
        additional_question_one: string,
        additional_question_three: string,
        additional_question_two: string
      }
    }
  }) => {
    this.setState({ policyQuestions: response.data.attributes });
  };

  openPetServicePopup = (serviceIndex: number, serviceType: string) => {
    if((this.state.serviceCatList.length === 0 && this.state.addRoomCat > 0) || (this.state.serviceDogList.length === 0 && this.state.addRoomDog > 0)){
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      this.setState({dogSelectionError: true},()=>{
        this.setState({dogSelectionError: false});
      });
      return;
    }
    if(serviceType === "additionalService"){
      const serviceList = [...this.state.petServiceList];
      serviceList[serviceIndex] = true;
      this.setState({petServiceList: serviceList});
    } else {
      const serviceList = [...this.state.ownPetServiceList];
      serviceList[serviceIndex] = true;
      this.setState({ownPetServiceList: serviceList});
    }
  }
  closeServicePet = (serviceIndex: number) => {
      let serviceList = [...this.state.petServiceList];
      serviceList[serviceIndex] = false;
      this.setState({petServiceList: serviceList});
      serviceList = [...this.state.ownPetServiceList];
      serviceList[serviceIndex] = false;
      this.setState({ownPetServiceList: serviceList});
  };

  handleSelectServicePet = (event: {target: {checked: boolean}}, serviceId: number, petName: string) => {
    const isChecked = event.target.checked;
    const serviceList = [...this.state.servicePetIds];
    if( isChecked ) {
      serviceList.forEach(element => {
        if(element.serviceId === serviceId){
          element.pets.push(petName);
        }
      });
    } else {
      serviceList.forEach(element => {
        if(element.serviceId === serviceId  && element.pets.length>1){
          element.pets = element.pets.filter((name: string) => name !== petName);
        } else if(element.serviceId === serviceId && element.pets.length === 1){
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          })
          this.setState({petSelectionErr: true},()=>{
            this.setState({petSelectionErr: false})
          })
        }
      });
    }

    serviceList.forEach(element => {
      this.serviceCalculation(element, serviceId);
    });

    this.setState({
      servicePetIds: serviceList,
    }, () => {
      this.calculatepriceTotal();
    });
  };

  serviceCalculation = (element: { serviceId: number; charge_type?: string; pets: string[]; service_provided_type?: string; }, serviceId: number) => {
    if(element.serviceId === serviceId && element.pets.length>0){
      let selectedService = this.state.checkedServicesCatPrice;
      selectedService = selectedService.map((service: {additional_service_id: number, pets: number })=>{
        if(service.additional_service_id === serviceId){
          return {...service, pets: element.pets.length};
        } else{
         return service;
        }
      });
      this.setState({checkedServicesCatPrice: selectedService});
    }
  };

  myServiceCalculation = (element: { serviceId: number; charge_type?: string; pets: string[]; service_provided_type?: string; }, myServiceId: number) =>{
      if(element.serviceId === myServiceId && element.pets.length>0){
        let mySelectedService = this.state.ownServices;
        mySelectedService = mySelectedService.map((service)=>{
          if(service.additional_service_id === myServiceId){
            return {...service, pets: element.pets.length};
          } else{
           return service;
          }
        });
        this.setState({ownServices: mySelectedService});
      }
  };

  handleOwnServicePet = (event: {target: {checked: boolean}}, serviceId: number, petName: string) => {
    const isChecked = event.target.checked;
    const ownServiceList = [...this.state.ownServicePetIds];
    if(isChecked) {
      ownServiceList.forEach(myElement => {
        if(myElement.serviceId === serviceId){
          myElement.pets.push(petName);
        }
      });
    } else {
      ownServiceList.forEach(myElement => {
        if(myElement.serviceId === serviceId  && myElement.pets.length > 1){
          myElement.pets = myElement.pets.filter((name: string) => name !== petName);
        }else if(myElement.serviceId === serviceId && myElement.pets.length === 1){
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          })
          this.setState({petSelectionErr: true},()=>{
            this.setState({petSelectionErr: false})
          })
        }
      });
    }

    ownServiceList.forEach(element => {
      this.myServiceCalculation(element, serviceId);
    });

    this.setState({
      ownServicePetIds: ownServiceList
    }, () => {
      this.calculatepriceTotal();
    });
  };

  getCheckedService = (petName: string, serviceId: number) => {
    const selectedService = this.state.servicePetIds.find(service => service.serviceId === serviceId);
    return selectedService && selectedService.pets.includes(petName);
  };

  getCheckedOwnService = (petName: string, serviceId: number) => {
    const selectedService = this.state.ownServicePetIds.find(service => service.serviceId === serviceId);
    return selectedService && selectedService.pets.includes(petName);
  };

  getServicePetName = (serviceId: number, serviceType: string) => {
    let selectedServiceType = serviceType === "ownService"?this.state.ownServicePetIds:this.state.servicePetIds;
    const selectedIndex = selectedServiceType.findIndex(service => service.serviceId === serviceId);
    let petNameList = "";
    if (selectedIndex >= 0) {
      selectedServiceType[selectedIndex].pets.forEach((name: string, catNameIndex: number) => {
        if (catNameIndex === selectedServiceType[selectedIndex].pets.length - 1 && catNameIndex !== 0) {
          petNameList = petNameList + " and " + name;
        } else if (catNameIndex === 0) {
          petNameList = petNameList + name;
        } else {
          petNameList = petNameList + ", " + name;
        }
      })
    }
    return petNameList === ""? "Select pets" : petNameList;
  };

  callService = (event: {target: {checked: boolean}}, serviceId: number, petName: string, serviceType: string) => {
    if(serviceType === "ownService"){
      this.handleOwnServicePet(event, serviceId, petName);
    } else{
      this.handleSelectServicePet(event, serviceId, petName);
    }
  };

  checkedService = (petName: string, serviceId: number, serviceType: string) => {
    if(serviceType === "ownService"){
      return this.getCheckedOwnService(petName, serviceId);
    } else {
      return this.getCheckedService(petName, serviceId);
    }
  };

  handleQuestion1 = (event: {target: {value: string}}) => {
      this.setState({
        additionalQuestion1: event.target.value
      })
  };

  handleQuestion2 = (event: {target: {value: string}}) => {
    this.setState({
      additionalQuestion2: event.target.value
    })
  };

  handleQuestion3 = (event: {target: {value: string}}) => {
    this.setState({
      additionalQuestion3: event.target.value
    })
  };

  async componentWillUnmount() {
    await removeStorageData("petId");
    await removeStorageData("catId");
    await removeStorageData("checkedcat");
    await removeStorageData("catName");
    await removeStorageData("dogId");
    await removeStorageData("checkeddog");
    await removeStorageData("dogName");
  };

  getDays = (totalDayStay: number) =>{
    return totalDayStay > 1 ? "s ":" ";
  };

  totalDay = (totalDay: number | undefined) => {
    return totalDay == undefined ? 0 : totalDay;
  };

  getQuestionCondition =() => {
    if (this.state.policyQuestions.additional_condition === "" && this.state.policyQuestions.additional_question_one === "" && this.state.policyQuestions.additional_question_two === "" && this.state.policyQuestions.additional_question_three === "") {
      return false;
    } 
    return true;
  };

  handleAdditionalDetailsChange =(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=> {
    const value = event.target.value;
    this.setState({ additionalDetails: value});
  };

  handleSelectedType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const changedValue = event.target.value;
    this.setState({ 
      selectedPriceType: changedValue 
   },()=> {
     this.checkError();
     this.checkDisplayPrice();
   });
  };


  checkError = () => {
    if(this.state.selectedPriceType === "OneDay" && this.state.totalDay > 7){
      this.setState({dateErrorText: "Day range can not be more than seven for one time service."});
    } else{
      this.setState({dateErrorText: ""});
    }
  };

  checkDisplayPrice = () => {
    this.checkDayCareDays();
    const onetimePrices: (string | null)[] = [
      this.state.priceList.attributes.price_for_one_day,
      this.state.priceList.attributes.price_for_two_days,
      this.state.priceList.attributes.price_for_three_days,
      this.state.priceList.attributes.price_for_four_days,
      this.state.priceList.attributes.price_for_five_days,
      this.state.priceList.attributes.price_for_six_days,
      this.state.priceList.attributes.price_for_seveen_days
  ];
  const weeklyPrices: (string | null)[] = [
      this.state.priceList.attributes.weekly_price_for_one_day,
      this.state.priceList.attributes.weekly_price_for_two_days,
      this.state.priceList.attributes.weekly_price_for_three_days,
      this.state.priceList.attributes.weekly_price_for_four_days,
      this.state.priceList.attributes.weekly_price_for_five_days,
      this.state.priceList.attributes.weekly_price_for_six_days,
      this.state.priceList.attributes.weekly_price_for_seveen_days
  ];
    if (this.state.selectedPriceType === "OneDay") {
      this.setState({ displayPrice : onetimePrices[this.state.selectedDays.length-1]??"0"});
    } else if (this.state.selectedPriceType === "Weekly") {
      this.setState({ displayPrice : weeklyPrices[this.state.selectedDays.length-1]??"0"});
    }
  };

  addRoomOnClick = (selceteddPetId: string, selectedPetName: string, selectedPetType: string) => {
    if (this.state.petId.includes(selceteddPetId)) {
       if(selectedPetType === "dog"){
         this.setState({dayCareDogCount: this.state.dayCareDogCount-1});
       }
       this.setState((prevState) => {
         const updatedPetId = prevState.petId.filter((petDataId: string) => petDataId !== selceteddPetId);
         const updatedPetName = prevState.petName.filter(petDataName => petDataName !== selectedPetName);
         return { petId: updatedPetId, petName: updatedPetName};
       },()=>{
        this.getSelectFeildText();
        this.setState({serviceDogList: [] });
      });
    } else {
     if(selectedPetType === "dog"){
       this.setState({dayCareDogCount: this.state.dayCareDogCount+1});
     }
     const ownServicePetIds = this.state.ownServicePetIds.map(service => {
      return {...service, pets: []};
    });
    const servicePetIds = this.state.servicePetIds.map(service => {
      return {...service, pets: []};
    });
    this.setState({ ownServicePetIds, servicePetIds });
     this.setState((prevState) => {
       const newData = [...prevState.petId, selceteddPetId];
       const newNameData = [...prevState.petName, selectedPetName];
       return { petId: newData, petName: newNameData};
     },()=>{
      this.getSelectFeildText();
      let petServiceList = this.state.dogmap.filter(details => this.state.petId.includes(details.id));
      this.setState({serviceDogList: petServiceList});
    });
    }
  };

  addSelectedDay = ( dayIndex: number) => {
    if (this.state.selectedDays.includes(dayIndex)) {
      this.setState((prevState) => {
        const updatedDays = prevState.selectedDays.filter((dayId) => dayId !== dayIndex);
        return { selectedDays: updatedDays };
      }, ()=>{
        this.checkDisplayPrice();
      });
    } else {
      this.setState((prevState) => {
        const newData = [...prevState.selectedDays, dayIndex];
        return { selectedDays: newData };
      }, ()=>{
        this.checkDisplayPrice();
      });
    }
  };

  handleCheckInDateChange = (date: DateObject) => {
    const {checkOutDate} = this.state;
    const dateAsDate = date.toDate();
    const totalDay = Math.ceil((Number(checkOutDate) - Number(dateAsDate))/   86400000);
    const formattedDateString = date.format("YYYY-MM-DD");
    this.setState({ checkInDate: dateAsDate, checkInDateError: formattedDateString, openSnck: false, totalDay: totalDay },()=>{
      this.checkError();
    });
  };

  handleCheckOutDateChange = (date: DateObject) => {
    const {checkInDate}=this.state;
    const dateAsDate = date.toDate()
    const totalDay = Math.ceil((Number(dateAsDate) - Number(checkInDate))/   86400000);
    const formattedDateString = date.format("YYYY-MM-DD")
    this.setState({ checkOutDate: dateAsDate, checkOutDateError: formattedDateString, openSnck: false, totalDay: totalDay },()=>{
      this.checkError();
    });
  };

  handelOpenSnack = () => {
    this.setState({
      openSnck: true
    },()=>{this.setState({openSnck: false})})
  };

  openPopUp = () => {
    this.setState({openPetPopup: true});
  };

  closePopup = () => {
  this.setState({openPetPopup: false});
  };

  addSelectedDays = (dayIndex: number) => {
    if(this.state.fromReservation){
      this.addSelectedDay(dayIndex)
    } else {
      return undefined;
    }
  };

  handleType = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(this.state.fromReservation){
      this.handleSelectedType(event)
    } else{
      return undefined;
    }
  };

  formatDate(date: Date){
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = (date.getDate()).toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  getDatesOnSpecificDays() {
    const daysOfWeek = this.state.selectedDays.map(currentday => currentday === 6? 0:currentday+1);
    const start = new Date(this.state.checkInDateError);
    const end = new Date(this.state.checkOutDateError);
    let matchingDates = [];
    let currentDate = start;
    while (currentDate < end) {
        const dayOfWeek = currentDate.getDay();
        if (daysOfWeek.includes(dayOfWeek)) {
            matchingDates.push(this.formatDate(currentDate));
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }    
    return matchingDates;
  };

  checkAvailability = ()=>{
    function getSpacesByDate(givenDate: string, dataArray: { [key: string]: number }[]) {
      let spaces = 0;
      dataArray.forEach(dateObj => {
        const date = Object.keys(dateObj)[0];
        if(givenDate === date){
          spaces = dateObj[date];
        }
      });
      return spaces;
    };
    let selectedDates = this.state.selectedPriceType === "Weekly" ? this.getDatesOnSpecificDays() : this.getDatesOnSpecificDays().splice(0,this.state.selectedDays.length);
    let flag = false;
    selectedDates.forEach((date: string) => {
      const spaces = getSpacesByDate(date, this.state.availableSpace);
      if(spaces < this.state.addRoomDog ){
        flag =  true;
      }
    });
    return flag;
  };

  getSpaceText = () => {
    return "These many space are not available for selected date";
  };

  handleContinue = () => {
    if(this.state.petId.length === 0){
      this.setState({ modalError: "Please select pets for daycare service", daycareError: true});
    } else if (this.state.addRoomDog !== this.state.dayCareDogCount){
      this.setState({ modalError: "Please select correct number and type of pets for daycare service", daycareError: true});
    } else if(this.state.selectedDays.length === 0){
      this.setState({ modalError: "Please select days for daycare service", daycareError: true });
    } else if(this.state.additionalDetails === ""){
      this.setState({ modalError: "Please enter additional details", daycareError: true});
    } else if(this.state.totalDay && this.state.totalDay < 6 && this.state.selectedPriceType === "Weekly"){
      this.setState({ modalError: "Service cannot be repeated in the given date range", daycareError: true});
    } else if(this.checkDayCareDays()){
      this.setState({ modalError: "These days do not match the criteria.", daycareError: true});
    }  else if(this.checkDayCareOneTime()){
      this.setState({ modalError: "These days do not match the criteria.", daycareError: true});
    } else if (this.state.dateErrorText){
      this.setState({ modalError: "", daycareError: true});
    } else if (this.state.availableSpace!==null && this.checkAvailability()){
      this.setState({ modalError: `${this.getSpaceText()}`, daycareError: true});
    }else {
      let daycareDays;
      if(this.state.selectedPriceType === "Weekly"){
        daycareDays = this.state.numberOfWeeks * this.state.selectedDays.length;
      } else {
        daycareDays = this.state.selectedDays.length;
      }
      this.setState({ 
        priceTotal: Number(this.state.displayPrice) * this.state.petId.length * this.state.numberOfWeeks,
        dayCareFormSubmited: true,
        dayCareDays: daycareDays,
        openModal: !this.state.openModal, 
        daycareError: false
      }, ()=>{
        this.setButtonText( this.state.selectedDays, this.state.selectedPriceType );
      });
    }
  };

  checkDayCareDays = () => {
    if(this.state.selectedPriceType === "Weekly"){
    const dayList = [0, 0, 0, 0, 0, 0, 0];
      const startDay = this.state.checkInDate.getDay();
    const totalDay: number = Math.floor(this.state.totalDay);
    let date = 0, getday = startDay;
    while (date < totalDay) {
      dayList[getday] += 1;
      getday = (getday + 1) % 7;
      date += 1;
    }
      const daySet: Set<number> = new Set();
      dayList.forEach((dayFreq: number, dayIndex: number) => {
        dayIndex = dayIndex > 0 ? dayIndex -1 : 6;
        if (this.state.selectedDays.includes(dayIndex)) {
          daySet.add(dayFreq);
        }
    });
      if(daySet.size === 0){
        this.setState({numberOfWeeks: 0});
        return false;
      }
      if (daySet.size > 1) {
        this.setState({numberOfWeeks: 0, modalError: "These days do not match the criteria.", daycareError: true}, ()=>this.calculatepriceTotal());
        return true;
      } 
      this.setState({numberOfWeeks: Array.from(daySet)[0], modalError: ""}, ()=>this.calculatepriceTotal());
      return false;
    } else{
      this.setState({numberOfWeeks: 1, modalError: ""}, ()=>this.calculatepriceTotal());
      return false;
    } 
  };
  
  checkDayCareOneTime = () => {
    if(this.state.totalDay && this.state.totalDay < 6 && this.state.selectedPriceType === "OneDay"){
      let startDay = this.state.checkInDate.getDay();
      startDay = startDay >= 1 ? startDay-1 : 6;
      const totalDay = Math.floor(this.state.totalDay);
      const dayList = [0, 0, 0, 0, 0, 0, 0];
      let date = 0, getday = startDay;
      while (date < totalDay) {
        dayList[getday] += 1;
        getday = (getday + 1) % 7;
        date += 1;
      }

      return this.state.selectedDays.some((daySelected: number)=> dayList[daySelected]===0);
    }
    return false;
  };

  getSelectFeildText = () =>{
    if(this.state.petName.length === 0){
        return "Select";
    } else {
        let countDogAndCat = this.state.selectFeildText;
        this.state.petName.forEach(( selectedPet: string, petIndex )=>{
           if(petIndex > 0 && petIndex < this.state.petName.length-1 ){
             countDogAndCat = countDogAndCat + ", " +  selectedPet;
           } else if(petIndex === 0){
             countDogAndCat = selectedPet;
           } else {
             countDogAndCat = countDogAndCat + " and " +  selectedPet;
           }
        })
        return countDogAndCat;
    }     
  };

  checkDayCareOneTimeValidation = () => {
    if(this.state.totalDay && this.state.totalDay < 6 && this.state.selectedPriceType === "OneDay"){
      let startDay = this.state.checkInDate.getDay()
      startDay = startDay > 1 ? startDay-1 : 6;
      const totalday = Math.floor(this.state.totalDay);
      const dayList = [0, 0, 0, 0, 0, 0, 0]
      let date = 0;
      let getday = startDay;
      while (date <= totalday) {
        dayList[getday] += 1
        getday = (getday + 1) % 7
        date += 1
      }
    }
    return true;
  }
  // Customizable Area End
}
