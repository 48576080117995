import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { checkApiErrorResponce, handleLogoutUser, handleNavigation } from "../../../components/src/CommonFunctions";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab";


export interface Activity {
  new_reservations: boolean
  new_chat: boolean
}

interface AccountDetailsAttributes {
  activated: boolean
  is_accepted_terms_and_conditions: boolean
  full_name: string
  surname: string 
  gender: string
  phone_number_country_code: string
  email: string
  full_phone_number: string
  type: string
  city: string 
  country: string
  flat_number: string
  address: string
  postcode: string 
  created_at: string
  updated_at: string
  device_id: any
  unique_auth_id: any
  first_name: string 
  last_name: string 
  country_code: any
  number_of_business: any
  address_line_1: string 
  address_line_2: string
  country_region: string
  is_profile_complete: boolean
  county: string
  name_title: string
  is_hotel_created: boolean
  photo: string
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  active?: number,
  profilePhoto?: any,
  iconPage?: string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  profilePicture: string,
  activity: Activity,
  toasterStatus: boolean,
  toasterType: Color,
  toasterMessage: string,
  logoutModal: boolean,
  profileInformation: AccountDetailsAttributes,
  navigationToaster: boolean
  navigationToasterMessage: string,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  apiCallIdGetActivity: string = "";
  apiCallIdGetAccountInformatation: string = ""
  activityTimeInterval: NodeJS.Timeout | null = null;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      profilePicture: "",
      activity: {
        new_reservations: false,
        new_chat: false,
      },
      toasterStatus: false,
      toasterType: "success",
      toasterMessage: "",
      logoutModal: false,
      profileInformation: {} as AccountDetailsAttributes,
      navigationToaster: false,
      navigationToasterMessage: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))
    if (apiRequestCallId == this.apiCallIdGetActivity) {
      if (responseJson && !responseJson.errors) {
        this.setState({ activity: responseJson.data })

      }
    }
    if (apiRequestCallId === this.apiCallIdGetAccountInformatation) {
      if (responseJson && !responseJson.errors) {
        this.setState({ profileInformation: responseJson?.data?.attributes, profilePicture: responseJson?.data?.attributes?.photo });
      } else {
        this.setState({ toasterStatus: true, toasterMessage: configJSON.sessionErrorMessage });
        checkApiErrorResponce(responseJson, this.props.navigation);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.gettingNewActivity();
    this.getAccountDetails()

    this.activityTimeInterval = setInterval(this.gettingNewActivity, 20000);
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.activity?.new_reservations !== this.state.activity.new_reservations) {
      this.setState((prevState) => ({
        activity: prevState.activity
      }))
    }
    if (prevState.activity?.new_chat !== this.state.activity.new_chat) {
      this.setState((prevState) => ({
        activity: prevState.activity
      }))
    }
  }

  async componentWillUnmount(): Promise<void> {
    // Clean up the interval when the component is unmounted
    if (this.activityTimeInterval) {
      clearInterval(this.activityTimeInterval);
    }
  }

  sideBarNavigation = (pageType: string) => {
    if (pageType === configJSON.navigationButtons.dashboard) {
      handleNavigation("PropertyDetails", {}, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.reservations) {
      handleNavigation("ReservationsBusinessOwner", {}, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.calendar) {
      handleNavigation("Scheduling", {}, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.inbox) {
      handleNavigation("Chat", {}, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.transactions) {
      handleNavigation("Collecttransactionfees", {}, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.settings) {
      handleNavigation("Settings2BO", {}, this.send, this.props)
    } else if (pageType === configJSON.navigationButtons.logout) {
      this.setState({ logoutModal: true })
    } else if (pageType === configJSON.navigationButtons.avatar) {
      handleNavigation("ContentManagementBo", {}, this.send, this.props)
    }

  }


  gettingNewActivity = async () => {

    let tokenValue = await getStorageData("authToken");
    const headers = {
      token: tokenValue,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdGetActivity = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getActivityEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAccountDetails = async () => {

    let tokenValue = await getStorageData("authToken");
    const headers = {
      token: tokenValue,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdGetAccountInformatation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getAccountDetailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleLogOut = () => {
    this.setState({ logoutModal: false }, () => {
      handleLogoutUser()
      setTimeout(() => {
        handleNavigation("LandingPage", {}, this.send, this.props)
      }, 500)
    })
  }

  closeLogOutModal = () => {
    this.setState({ logoutModal: false })
  }


  validateNavigation = () => {
    const requiredFields: Array<keyof AccountDetailsAttributes> = ['first_name', 'last_name', 'full_phone_number', 'postcode', 'address_line_1', 'city'];
    const { profileInformation } = this.state
    const missingFields = requiredFields.filter(field => {
      const value = profileInformation[field];
      return !value || value.trim() === '';
    });

    if (missingFields.length > 0) {
      this.setState({ navigationToaster: true, navigationToasterMessage: configJSON.profileSetup, toasterType: 'error' })
      return false;
    } else {
      this.setState({ navigationToaster: false, navigationToasterMessage: "", toasterType: 'error' })

      return true;
    }

  }

  handleNavigation = (type: string) => {
    const isLogout = type === configJSON.navigationButtons.logout;
  
    if (!isLogout && this.validateNavigation() || isLogout) {
      this.sideBarNavigation(type);
    }
  };

  closeNaviagtionToaster = () => {
    this.setState({ navigationToaster: false })
  }
  // Customizable Area End
}
