import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { FilterButtons, Pet, Property, ReservationsData } from "./types";
import { WithStyles } from "@material-ui/core";
import { getStorageData } from "../../../framework/src/Utilities";
import { checkApiErrorResponce, handleNavigation } from "../../../components/src/CommonFunctions";
import React from "react";
import { DateObject } from "react-multi-date-picker";
import moment from "moment";
import { Color } from "@material-ui/lab";

// Customizable Area Start

export interface PaymentDetailsRoot {
  errors: any;
  payment_details: PaymentDetails
}

export interface PaymentDetails {
  order_amount?: string
  prepayment_or_deposit?: string
  paid_amount?: string
  pending_amount?: string,
  stripe_charges?: string
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

export interface RoomDetails {
  id: number;
  price: number;
  max_capacity: number;
  additional_charges: number;
  account_id: number;
  hotel_id: number;
  room_type: string;
  pet_count: number;
  add_pet: number;
  created_at: string;
  updated_at: string;
  description: string;
  room_for: string;
  num_rooms: string;
  check_in_start: string;
  check_in_end: string;
  check_out_start: string;
  check_out_end: string;
  min_stay: number;
  max_stay: number;
  advance_notice: string;
  notice_before: string;
  pet_room_prices: string[];
  num_dogs: number;
  num_cats: number;
}

export interface Pets {
  attributes: {
    name: string,
    pet_type: string
  }
}

interface OutlookLoginResponse {
  redirect_uri?: string;
}

export interface Params {
  min: string;
  max: string;
  date: string;
  roomType: string;
  numberofPets: string;
  num_rooms: number;
};

export interface Reservation {
  attributes: {
    status: string,
    total_charges: number
    reject_message: string
    pet_owner_name: string,
    check_in_date: string,
    check_out_date: string,
    price: string,
    room_type_for_dog: string,
    room_type_for_cat: string,
    hotel_information: {
      name: string
    },
    pets: {
      data: Array<Pets>
    },
    room_for_dogs: { id: number, pets: string[], price: number, type: string }[];
    room_for_cats: { id: number, pets: string[], price: number, type: string }[];
  }
}
interface ReservationData {
  orders: {
    data: Array<Reservation>
  }
}
interface LinkObject {
  url: string;
  title: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  FilterButtons: FilterButtons[];
  selectedFilter: string;
  searchStatus: boolean;
  searchValue: string;
  statusDropDown: boolean;
  statusDropDownValues: number | string;
  dailogStatus: boolean;
  StatusValue: string;
  popUpType: string;
  propertiesDropDownStatus: boolean;
  propertiesList: Property[];
  selectedProperty: string;
  reservationList: any[];
  selelctedFilterValue: string,
  rejectMessagetext: string;
  selectedOrder: string | number;
  reservationPageLoader: boolean;
  dropdownLoader: boolean;
  upcomingStatus: string
  checkedInStatus: string
  orderDetails: any;
  selectedIndex: number;
  page: number,
  totalPageCount: number
  modifiedRoomType: any[],
  settingFiltersEndParams: string,
  reservationDetails: any,
  upcomingIndex: number,
  saveOrderId: string | number,
  totalCount: number,
  isButtonSelected: boolean;
  csvResponse: object | undefined;
  redirectUri: string;
  socialLinksData: { icons: string, navigationUrl: string }[];
  poBOLinksData: LinkObject[];
  roomList: RoomDetails[];
  openToasterStatus: boolean;
  openToasterErrorStatus: boolean;
  syncCompleted: string;
  syncCompletedError: string;
  roomListBo: RoomDetails[];
  selectedRoomType: string;
  openRoomTypes: boolean;
  params: Params;
  selectedRoomCount: number;
  inputFieldDate: string;
  selectedRange: DateObject[];
  dateRange: string;
  startDate: string;
  endDate: string;
  openCalenderStatus: boolean;
  numberOfPets: number;
  price: {
    min: string;
    max: string;
  },
  paymentDetails: PaymentDetails;
  toasterStatus: boolean,
  toasterMessage: string,
  toasterType: Color,
  checkInDate: string,
  errorMsg: string,
  isError: boolean,
  popUpBtnText: string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ReservationsBusinessOwnerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiCallIdgetproperties: string = "";
  apiCallIdGetReservations: string = "";
  apiCallIdUpdateReservations: string = '';
  apiCallIdPreviewOrder: string = '';
  csvDownloadId: string = "";
  apiCallIdSearchReservations: string = ""
  apiCallIdGetOutlookUrl: string = "";
  calendarSyncApiCallId: string = "";
  calendarSyncAccessToken: string | null = "";
  roomListCallId: string = "";
  apiCallIdGetAllRooms: string = "";
  debounceTimeout: any;;
  apiCallIdGetPaymentBookingDetails: string = ""
  apiCallIdBussinessOwnerReservationList: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    const date = new DateObject()
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      FilterButtons: [
        { filterId: 1, filterName: "Pending", filterValue: "pending" },
        { filterId: 2, filterName: "Upcoming", filterValue: "upcoming" },
        { filterId: 3, filterName: "Checked in", filterValue: "checked_in" },
        { filterId: 4, filterName: "Completed", filterValue: "completed" },
        { filterId: 5, filterName: "Cancelled", filterValue: "cancelled" },
        { filterId: 6, filterName: "No Show", filterValue: "no_show" },
      ],
      selectedFilter: "Pending",
      selelctedFilterValue: "pending",
      searchStatus: false,
      searchValue: "",
      statusDropDown: false,
      statusDropDownValues: 0,
      dailogStatus: false,
      StatusValue: "PENDING...",
      popUpType: '',
      propertiesDropDownStatus: false,
      propertiesList: [],
      selectedProperty: "All properties",
      reservationList: [],
      rejectMessagetext: "",
      selectedOrder: "",
      reservationPageLoader: false,
      dropdownLoader: false,
      upcomingStatus: "",
      checkedInStatus: "",
      orderDetails: {},
      selectedIndex: 0,
      page: 1,
      totalPageCount: 0,
      modifiedRoomType: [],
      settingFiltersEndParams: "",
      reservationDetails: {},
      upcomingIndex: 0,
      saveOrderId: "",
      totalCount: 0,
      isButtonSelected: false,
      csvResponse: {},
      redirectUri: '',
      socialLinksData: []
      ,
      poBOLinksData: [],
      roomList: [],
      openToasterStatus: false,
      openToasterErrorStatus: false,
      syncCompleted: '',
      syncCompletedError: '',
      roomListBo: [],
      selectedRoomType: '',
      openRoomTypes: false,
      params: {
        min: "",
        max: "",
        date: "",
        roomType: "",
        numberofPets: "",
        num_rooms: 0,
      },
      selectedRoomCount: 0,
      inputFieldDate: "",
      selectedRange: [],
      dateRange: '',
      startDate: date.format('DD/MM/YYYY'),
      endDate: date.format('DD/MM/YYYY'),
      openCalenderStatus: false,
      numberOfPets: 0,
      price: {
        min: '',
        max: '',
      },
      paymentDetails: {} as PaymentDetails,
      toasterStatus: false,
      toasterMessage: "",
      toasterType: 'success',
      checkInDate: "",
      errorMsg: "",
      isError: false,
      popUpBtnText: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getAllRooms();
    this.getAllRoomsBo()
    this.setState({ dateRange: `${this.state.startDate} - ${this.state.endDate}` })
    this.getProperties()
    const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
    this.setState({ socialLinksData: mySocialLinksData });
    const allLinksData = await getStorageData("BOandPOLinks", true)
    this.setState({ poBOLinksData: allLinksData })
    this.calendarSyncApiCall()
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    this.calendarSyncAccessToken = params.get('access_token');
  }

  // Customizable Area End
  async receive(from: string, message: Message) {
    // runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.apiCallIdgetproperties) {
      this.propertyListResponseHandler(responseJson)

    } if (apiRequestCallId === this.apiCallIdGetReservations) {
      if (responseJson && !responseJson.errors) {
        let modifiedArray = responseJson.reservations.data.map((reservation: { attributes: { status: string,price:string, pre_payment_amount:string}; }) => {
          const numericPrice = parseFloat(reservation.attributes.price.replace("£", ""));
          const numericPrepaymentPrice = parseFloat(reservation.attributes.pre_payment_amount.replace("£", ""));
          const displayPrice = numericPrepaymentPrice > numericPrice ? reservation.attributes.pre_payment_amount : reservation.attributes.price;

          if (reservation.attributes.status === 'upcoming' || reservation.attributes.status === 'pending') {
            return { ...reservation, StatusTest: "PENDING...",price:displayPrice };
          } else if (reservation.attributes.status === 'checked_in') {
            return { ...reservation, StatusTest: "CHECKED IN",price:displayPrice };
          } else {
            return {...reservation,price:displayPrice};
          }


        });


        this.setState({
          reservationList: modifiedArray,
          reservationPageLoader: false,
          totalPageCount: responseJson?.total_page,
          totalCount: responseJson?.total_count
        })
      }
      else if (responseJson.errors) {
        checkApiErrorResponce(responseJson, this.props.navigation)
      }

    }
    if (apiRequestCallId === this.apiCallIdUpdateReservations) {
      if (responseJson && !responseJson.errors) {
        this.setState({ StatusValue: "" }, () => {
          this.getProperties()

        })
      }
      else if (responseJson.errors) {
        const allReservationList = this.state.reservationList;
        allReservationList.forEach((reservation) => {
          if (reservation.StatusTest === "APPROVE") {
            reservation.StatusTest = "PENDING..."
          }
        });

        this.setState({
          errorMsg: responseJson.errors[0].message,
          isError: true,
          reservationPageLoader: false,
          reservationList: allReservationList
        }, () => {
          this.setState({
            isError: false,
          })
        })
        checkApiErrorResponce(responseJson, this.props.navigation)
      }

    }
    this.checkCSVResponse(apiRequestCallId, responseJson);
    this.outlookLoginResponse(apiRequestCallId, responseJson);
    this.getAllRoomsResponse(apiRequestCallId, responseJson);
    this.calendarSyncApiResponse(apiRequestCallId, responseJson);
    this.getAllRoomsApiResponse(apiRequestCallId, responseJson);
    this.getPaymentDetailsApiResponce(apiRequestCallId, responseJson);
    // Customizable Area End
  }
  // Customizable Area Start
  getPaymentDetailsApiResponce = (apiRequestCallId: string, responseJson: PaymentDetailsRoot) => {
    if (apiRequestCallId === this.apiCallIdGetPaymentBookingDetails) {
      if (responseJson && !responseJson.errors) {
        this.setState({ paymentDetails: responseJson.payment_details })
      } else if (responseJson && responseJson.errors) {
        this.setState({ paymentDetails: {} })
      }
    }
  }
  getAllRoomsResponse = (apiRequestCallId: string, responseJson: RoomDetails[]) => {
    if (apiRequestCallId === this.roomListCallId) {
      this.setState({ roomList: responseJson });
    }
  };
  convertDateFormat = (dateStr: string) => {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    return formattedDate.replace(/,/g, '');
  };

  checkCSVResponse = (apiRequestCallId: string, responseJson: ReservationData) => {
    if (apiRequestCallId === this.csvDownloadId) {
      if (responseJson.orders.data) {
        this.setState({ csvResponse: responseJson });
        let reservationArray: string[][] = [];
        this.addHeaders(reservationArray);

        responseJson.orders.data.forEach((reservationData: Reservation) => {
          const price = this.getCsvPrice(reservationData);
          const roomType = this.formatRoomType(reservationData);

          let petsData = "[";
          reservationData.attributes.pets.data.forEach(function (petInfo: Pets) {
            petsData = petsData + `${petInfo.attributes.name} (${petInfo.attributes.pet_type}) `
          });
          petsData = petsData + "]"
          let reservationRow: string[] = [];
          let date = `${this.convertDateFormat(reservationData.attributes.check_in_date)} to ${this.convertDateFormat(reservationData.attributes.check_out_date)}`;
          reservationRow.push(`${reservationData.attributes.pet_owner_name}, ${date}, ${reservationData.attributes.hotel_information.name}, ${petsData}, ${roomType}, ${price}`);
          reservationArray.push(reservationRow);
        })

        const csvContent = reservationArray.map(rowArray => rowArray.join(",")).join("\r\n");
        const bom = "\uFEFF";
        const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${bom}${csvContent}`);
        const csvLink = document.createElement("a");
        csvLink.setAttribute("href", encodedUri);
        csvLink.setAttribute("download", configJSON.textCSVFileName);
        document.body.appendChild(csvLink);
        csvLink.click();
      }
    }
  };

  addHeaders = (reservationArray: string[][]) => {
    const { selectedFilter } = this.state;
    const header = selectedFilter === 'Cancelled'
      ? ["Pet Owner Name, Date, Property Name, Pet Type, Room Type, Reason"]
      : ["Pet Owner Name, Date, Property Name, Pet Type, Room Type, Price"];
    reservationArray.push(header);

  };

  getCsvPrice = (reservationData: Reservation): string => {
    let price = reservationData.attributes.status === 'cancelled'
      ? reservationData.attributes.reject_message?reservationData.attributes.reject_message:""
      : this.getPrice(reservationData.attributes.price, reservationData.attributes.total_charges);
    return price;
  };
  getPrice = (price: string, stripeAmount: number) => {
    return "£" + (Number(price?.split("£")[1]) + stripeAmount).toFixed(2);
  };

  formatRoomType = (reservation: Reservation): string => {
    let roomTypes: string[] = [];

    if (reservation.attributes.room_for_dogs && reservation.attributes.room_for_dogs.length > 0) {
      reservation.attributes.room_for_dogs.forEach(room => {
        roomTypes.push(`${room.type} (for Dog)`);
      });
    }


    if (reservation.attributes.room_for_cats && reservation.attributes.room_for_dogs.length > 0) {
      reservation.attributes.room_for_cats.forEach(room => {
        roomTypes.push(`${room.type} (for Cat)`);
      });
    }

    return roomTypes.length > 0 ? `[${roomTypes.join(' ')}]` : '[]';
  };
  outlookLoginResponse = (apiRequestCallId: string, responseJson: OutlookLoginResponse) => {
    if (apiRequestCallId === this.apiCallIdGetOutlookUrl) {
      if (responseJson && responseJson.redirect_uri) {
        this.setState({ redirectUri: responseJson.redirect_uri });
        window.open(this.state.redirectUri, '_blank');
      }
    }
  }
  calendarSyncApiResponse = (apiRequestCallId: string, responseJson: { message: string; error: string }) => {
    if (apiRequestCallId === this.calendarSyncApiCallId) {
      if (responseJson && responseJson.message) {
        this.setState({ syncCompleted: responseJson.message, openToasterStatus: true });
      } else if (responseJson.error) {
        this.setState({ syncCompletedError: responseJson.error, openToasterErrorStatus: true });
      }
    }
  }

  getAllRoomsApiResponse = (apiRequestCallId: string, responseJson: any) => {
    if (apiRequestCallId === this.apiCallIdGetAllRooms) {
      if (responseJson && !responseJson.errors) {
        this.setState({ roomListBo: responseJson })
      }
    }
  }
  propertyListResponseHandler = (responseJson: { errors: string | [], message: string, data: [] }) => {
    if (responseJson && !responseJson.errors && !responseJson.message) {
      this.setState({ propertiesList: responseJson.data, dropdownLoader: false })
    }
    else if (responseJson.message) {
      this.setState({ dropdownLoader: false });
    }     
    else if (responseJson.errors) {
      checkApiErrorResponce(responseJson, this.props.navigation)
    }
  }
  handlePageonSearch = () => {
    this.setState({ page: 1 })
  }
  changeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({ searchValue: value });
    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
    }
    this.debounceTimeout = setTimeout(() => {
      this.getProperties()
      this.handlePageonSearch()
    }, 700);
  }
  selectFilter = (filterName: FilterButtons) => {
    this.setState({ selectedFilter: filterName.filterName, selelctedFilterValue: filterName.filterValue, page: 1, reservationList: [] }, () => {
      this.getProperties()
    })

  }
  showSearchBar = () => {

    this.setState({ searchStatus: true })

  }
  hideSearchBar = () => {

    this.setState({ searchStatus: false, searchValue: "" })
    this.getProperties();

  }

  openStatusDropDown = (id: number | string) => {
    this.setState({ statusDropDownValues: id })
  }
  closeStatusDropDown = () => {
    if(!this.state.dailogStatus){
    this.setState({ statusDropDownValues: 0 })
    }
  }
  openRoomType = () => {
    this.setState({ openRoomTypes: true })
  }
  closeRoomTypes = () => {
    this.setState({ openRoomTypes: false })
  }
  selectRoomType = (selectedType: string) => {
    this.setState({
      selectedRoomType: selectedType,
      params: { ...this.state.params, roomType: `room_type=${selectedType}` }

    }, () => {
      this.closeRoomTypes()
    })
  }
  handleRoomSelect = (roomCount: number) => {
    this.setState((prevState) => ({
      selectedRoomCount: roomCount,
      params: { ...prevState.params, selectedRoomCount: roomCount !== 0 && `num_rooms=${roomCount}` }
    }));
  };

  openCommonDailog = (filterType: string, orderId: string | number, index?: number) => {
    this.setState({ dailogStatus: true }, () => {
      this.setState({ popUpType: filterType, selectedOrder: orderId, selectedIndex: index || 0 })
    })
  }
  closeCommonDailogStatus = (status: boolean) => {
    this.setState({ dailogStatus: status })
  }
  handleDateChange = (values: DateObject[]) => {
    if (values.length === 2 && values[0] && values[1]) {
      const startDate = values[0].format('DD/MM/YYYY');
      const endDate = values[1].format('DD/MM/YYYY');
      const startDateFormat = values[0].format("DD MMM");
      const endDateFormat = values[1].format("DD MMM");
      const inputRange = `${startDate} - ${endDate}`;
      this.setState({
        selectedRange: values, dateRange: inputRange,
        startDate: startDate, endDate: endDate,
        openCalenderStatus: false,
        inputFieldDate: `${startDateFormat} - ${endDateFormat}`,
        params: { ...this.state.params, date: `filters[date_range][start_date]=${startDate}&filters[date_range][end_date]=${endDate}` }
      }, () => {
        this.closeSelectDate()
      })
    }
  };
  clearDates = () => {
    this.setState({
      selectedRange: [], dateRange: "",
      startDate: "", endDate: "",
      openCalenderStatus: false,
      inputFieldDate: "",
      params: { ...this.state.params, date: "" }

    }, () => {
      this.closeSelectDate()
    })
  }
  openSelectDate = () => {
    this.setState({ openCalenderStatus: true })
  }
  closeSelectDate = () => {
    this.setState({ openCalenderStatus: false })
  }
  closeDate = () => {
    this.setState({
      openCalenderStatus: false,

    }, () => {
      this.closeSelectDate()
    })
  }
  addPets = () => {

    this.setState(prevState => ({
      numberOfPets: prevState.numberOfPets + 1,
      params: { ...this.state.params, numberofPets: `filters[number_of_pets]=${prevState.numberOfPets + 1}` }

    }));
  }

  minusPets = () => {
    if (this.state.numberOfPets > 0) {
      this.setState(prevState => ({
        numberOfPets: prevState.numberOfPets - 1,
        params: { ...this.state.params, numberofPets: `filters[number_of_pets]=${prevState.numberOfPets - 1}` }

      }));
    }
  }
  handlePriceInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    let inputPriceValue = event.target.value;
    inputPriceValue = inputPriceValue.replace(/\D/g, '');
    this.setState({
      price: {
        ...this.state.price,
        [event.target.name]: "£" + inputPriceValue
      },
      params: { ...this.state.params, [event.target.name]: `filters[price][${event.target.name}]=${"£" + inputPriceValue}` }
    });

  }
  handleClear = () => {
    this.setState({
      selectedRoomCount: 0,
      selectedRoomType: '',
      price: {
        min: '',
        max: ''
      },
      numberOfPets: 0,
    }, () => {
      this.setState({
        params: {
          min: "",
          max: "",
          date: "",
          roomType: "",
          numberofPets: "",
          num_rooms: 0
        }
      }, () => {
        this.clearDates();
      });
    });
  };
  getRejectAction = (action: string, index: number) => {
    this.setState({ StatusValue: action }, () => {
      this.state.reservationList[this.state.selectedIndex].StatusTest = "REJECT"

      this.updateReservation(this.state.selectedOrder)
    })
  }
  approveAction = (orderId: string | number, index: number, check_in_date: string) => {
    const todayDate = moment().startOf('day');
    const currentDate = moment(check_in_date, "YYYY-MM-DD");

    if (orderId === this.state.statusDropDownValues) {
      this.state.reservationList[index].StatusTest = "APPROVE"
      if (currentDate.isSameOrAfter(todayDate)) {
        this.setState({ StatusValue: 'APPROVE' }, () => {
          setTimeout(() => {
            { this.updateReservation(orderId, index) }
          }, 500)
          this.closeStatusDropDown()
        })
      } else {
        this.state.reservationList[index].StatusTest = "PENDING..."
        this.setState({ toasterStatus: true, toasterMessage: configJSON.pendingApproveErrorMessage, toasterType: 'error' }, () => {
          this.closeStatusDropDown()
        })
      }
    }
  }

  checkOutStatusAction = (orderId: string | number, index: number) => {

    this.state.reservationList[index].StatusTest = "CHECK OUT"
    this.setState({ checkedInStatus: 'CHECK OUT' }, () => {
      this.updateReservation(orderId, index)
      this.closeStatusDropDown()
    })
  }



  upcomingStatusAction = (statusType: string, orderId: string | number, index: number) => {
    const todayDate = moment().startOf('day');
    const currentDate = moment(this.state.checkInDate, "YYYY-MM-DD");
    if (statusType === 'Cancelled') {
      this.state.reservationList[index].StatusTest = "CANCELLED"
      setTimeout(() => {
        this.updateReservation(orderId)
      }, 500)
      this.closeStatusDropDown()
    } else if (statusType === "Check-In") {
      if (orderId === this.state.statusDropDownValues) {
        this.state.reservationList[index].StatusTest = "CHECKED IN"
        if (currentDate.isSame(todayDate)) {
          setTimeout(() => {
            { this.updateReservation(orderId, index) }
          }, 500)
          this.closeStatusDropDown()
        } else {
          this.state.reservationList[index].StatusTest = "PENDING..."
          this.setState({ toasterStatus: true, toasterMessage: configJSON.upcomingApproveErrorMessage, toasterType: 'error' }, () => {
            this.closeStatusDropDown()
          })
        }
      }
    }
    else if (statusType === 'No Show') {
      if (orderId === this.state.statusDropDownValues) {
        this.state.reservationList[index].StatusTest = "NO SHOW"
        setTimeout(() => {
          { this.updateReservation(orderId, index) }
        }, 500)
        this.closeStatusDropDown()
      }
    } else if (statusType === "Approve") {
      this.approveAction(orderId, index, this.state.checkInDate)
    } else if (statusType === "Check-Out") {
      this.checkOutStatusAction(orderId, index)
    }
  }

  openPropertiesDropdown = () => {
    this.setState({ propertiesDropDownStatus: true }, () => {
      this.getDropdownProperties()

    })
  }
  closePropertiesDropdown = () => {
    this.setState({ propertiesDropDownStatus: false })

  }

  selectProperty = (propertyName: string) => {
    this.setState({ selectedProperty: propertyName }, () => {
      this.getProperties()
      this.handlePageonSearch()
      this.closePropertiesDropdown()
    })
  }

  getRejectMessage = (message: string) => {
    this.setState({ rejectMessagetext: message })
  }

  getFilterParams = (params: string) => {
    this.setState({ settingFiltersEndParams: params }, () => {
      this.getProperties()
    })

  }
  // Api Calls

  getProperties = async () => {

    let tokenValue = await getStorageData("authToken");
    const headers = {
      token: tokenValue,
    };
    let endPoint;

    if (this.state.selectedProperty === "All properties") {
      endPoint = `${configJSON.reservationsEndPoint}=${this.state.selelctedFilterValue}&${this.state.settingFiltersEndParams}&page=${this.state.page}`;
    }
    if (this.state.selectedProperty !== "All properties") {
      endPoint = `${configJSON.reservationsEndPoint}=${this.state.selelctedFilterValue}&property_name=${this.state.selectedProperty}&${this.state.settingFiltersEndParams}&page=${this.state.page}`;
    }
    if (this.state.searchValue) {
      endPoint = `${configJSON.reservationsEndPoint}=${this.state.selelctedFilterValue}&q=${this.state.searchValue}&${this.state.settingFiltersEndParams}&page=${this.state.page}`;
    }
    if (this.state.searchValue && this.state.selectedProperty !== "All properties") {
      endPoint = `${configJSON.reservationsEndPoint}=${this.state.selelctedFilterValue}&q=${this.state.searchValue}&property_name=${this.state.selectedProperty}&${this.state.settingFiltersEndParams}&page=${this.state.page}`;
    }

    this.setState({ reservationPageLoader: true, })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.itemListApiMethodType
    );

    this.apiCallIdGetReservations = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getDropdownProperties = async () => {

    let tokenValue = await getStorageData("authToken");
    const headers = {
      token: tokenValue,
    };

    this.setState({ dropdownLoader: true })

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCallIdgetproperties = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.HotelListEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.itemListApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateReservation = async (orderId: string | number, index: number = 0) => {
    let tokenValue = await getStorageData("authToken");
    const headers = {
      token: tokenValue,
    };
    let endPoint = `${configJSON.updateOrderEndPoint}/${orderId}/approve_reject`
    let updateOrderBody = new FormData()
    if (this.state.reservationList[index].StatusTest === "APPROVE") {
      updateOrderBody.append('status', 'approve')
    } else if (this.state.reservationList[index].StatusTest === "CHECKED IN") {
      updateOrderBody.append('status', 'check_in')


    } else if (this.state.reservationList[index].StatusTest === 'CANCELLED') {
      updateOrderBody.append('status', 'reject')
      updateOrderBody.append('reject_message', this.state.rejectMessagetext)

    }
    else if (this.state.reservationList[index].StatusTest === "CHECK OUT") {
      updateOrderBody.append('status', 'check_out')
    }
    else if (this.state.reservationList[index].StatusTest === "NO SHOW") {
      updateOrderBody.append('status', 'no_show')
    }
    else {
      updateOrderBody.append('status', 'reject')
      updateOrderBody.append('reject_message', this.state.rejectMessagetext)
    }
    this.setState({ reservationPageLoader: true, })
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), updateOrderBody
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.editReservationApiMethodType
    );

    this.apiCallIdUpdateReservations = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  selectedPet = (pet: Pet) => {

    this.setState({ orderDetails: pet.attributes }, () => {
      this.openCommonDailog('pets', "")
    })
  }

  handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ page: value }, () => {
      this.getProperties()
    })
  }
  openCheckInPopup = (reservation: ReservationsData, upcomingIndex: number, orderId: string | number, popUpBtnText: string) => {
    this.setState({
      reservationDetails: reservation, upcomingIndex: upcomingIndex, saveOrderId: orderId,
      checkInDate: reservation.attributes?.check_in_date,
      popUpBtnText: popUpBtnText
    }, () => {
      this.getPaymentDetails(reservation.id)
      this.openCommonDailog('checkin', "")

    })
  }

  seeChat = (chat_id: number) => {
    handleNavigation('Chat', { chatId: chat_id, page: 'reservationsBO' }, this.send, this.props)
  }

  selectExport = () => {
    this.setState({ isButtonSelected: !this.state.isButtonSelected });
    this.downloadCsv('reservationList');
  };

  downloadCsv = async (apiType: string) => {
    let csvEndPoint = '';

    if (apiType === 'reservationList') {
      if (this.state.selectedProperty === "All properties") {
        csvEndPoint = `${configJSON.csvURL}${this.state.selelctedFilterValue}&${this.state.settingFiltersEndParams}`;
      }
      if (this.state.selectedProperty !== "All properties") {
        csvEndPoint = `${configJSON.csvURL}${this.state.selelctedFilterValue}&property_name=${this.state.selectedProperty}&${this.state.settingFiltersEndParams}`;
      }
      if (this.state.searchValue) {
        csvEndPoint = `${configJSON.csvURL}${this.state.selelctedFilterValue}&q=${this.state.searchValue}&${this.state.settingFiltersEndParams}`;
      }
      if (this.state.searchValue && this.state.selectedProperty !== "All properties") {
        csvEndPoint = `${configJSON.csvURL}${this.state.selelctedFilterValue}&q=${this.state.searchValue}&property_name=${this.state.selectedProperty}&${this.state.settingFiltersEndParams}`;
      }
    }

    this.csvDownloadId = await this.apiCall({
      contentType: configJSON.itemListApiContentType,
      method: configJSON.itemListApiMethodType,
      endPoint: csvEndPoint
    });
  };

  apiCall = async (apiData: APIPayloadType) => {
    const { method, endPoint } = apiData;

    let token = await getStorageData("authToken");
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getMicrosoftRedirection = async () => {

    let tokenValue = await getStorageData("authToken");
    const headers = {
      token: tokenValue,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.outlookRedirection}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), 'GET'
    );
    this.apiCallIdGetOutlookUrl = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  calendarSyncApiCall = async () => {

    let tokenValue = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.itemListApiContentType,
      token: tokenValue
    };

    const body = {
      access_token: this.calendarSyncAccessToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.calendarSyncApiEndpoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),

      `${configJSON.calendarSyncApiMethod}`
    );
    this.calendarSyncApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId
  }
  getAllRoomsBo = async () => {
    let tokenValue = await getStorageData("authToken");
    const headers = {
      token: tokenValue,
      'Cache-Control': 'no-cache', // Add this line to disable caching
      'Pragma': 'no-cache',        // Add this line to disable caching
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.allRoomsReservationUrl}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.itemListApiMethodType
    );
    this.apiCallIdGetAllRooms = requestMessage.messageId;

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllRooms = async () => {
    this.roomListCallId = await this.apiCall({
      method: configJSON.itemListApiMethodType,
      endPoint: configJSON.roomEndpoint
    });
  };

  getPaymentDetails = async (orderId: string | number) => {
    const {selelctedFilterValue,popUpBtnText}=this.state;

    let statusValue=popUpBtnText==="Check-Out"?"check_out":selelctedFilterValue
    this.apiCallIdGetPaymentBookingDetails = await this.apiCall({
      method: configJSON.itemListApiMethodType,
      endPoint: `${configJSON.updateOrderEndPoint}/${orderId}/${configJSON.getPaymentDetailsEndpoint}?status=${statusValue}`
    });
  };

  getRecordPerPage = (resultsPerPage: number) => {
    return ((this.state.page - 1) * resultsPerPage + 1) + (Math.min(this.state.page * resultsPerPage, this.state.reservationList.length));
  };
  closeToaster = () => {
    this.setState({ toasterStatus: false })
  }

  getRoomPrice = (price: string, stripeAmount: number) => {
    return "£" + (Number(price.split("£")[1]) + stripeAmount).toFixed(2);
  };

  // Customizable Area End
}